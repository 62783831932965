import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { Alert } from '@grafana/ui';

import { BackendContext, usePluginMeta } from '@common/utils';

export const BackendErrorWarning = () => {
  const { pluginMeta, onConfigurationPage, pluginAppUrl, pluginConfigUrl } = usePluginMeta();
  const { jsonData, name: pluginName } = pluginMeta || {};
  const { backendUrl } = jsonData || {};
  const base64EncodedAccessTokenSet = !!pluginMeta?.secureJsonFields?.base64EncodedAccessToken;
  const backendConfigured = backendUrl && base64EncodedAccessTokenSet;

  const {
    backend: { isBackend, backendError, name: backendName, version: backendVersion },
  } = useContext(BackendContext);

  const navigate = useNavigate();

  // Conditionally add these properties to the Alert components to set a button that goes to the CONFIG_URL.
  const checkConfigButton = !onConfigurationPage
    ? { buttonContent: 'Check configuration', onRemove: () => navigate(pluginConfigUrl) }
    : {};

  if (!backendConfigured) {
    return (
      <Alert severity="warning" title="Not connected" {...checkConfigButton}>
        {`This plugin's connection settings are incomplete.`}
      </Alert>
    );
  }

  if (backendError) {
    return (
      <Alert severity="error" title="Unable to connect" {...checkConfigButton}>
        An error occurred while attempting to make a connection using the current settings.
        <br />
        {backendError}
      </Alert>
    );
  }

  if (onConfigurationPage) {
    if (isBackend) {
      return (
        <Alert
          severity="info"
          title="Connection settings are valid"
          buttonContent="Use app to manage cluster"
          onRemove={() => navigate(pluginAppUrl)}
        >
          This <em>{pluginName}</em> app is connected to a {backendName} {backendVersion} cluster.
        </Alert>
      );
    } else {
      return (
        <Alert severity="warning" title="Testing if connection settings are valid">
          This plugin is attempting to connect using the settings below.
        </Alert>
      );
    }
  }

  return null;
};
