import { getPluginApiPrefix, PluginApiPrefix } from './Plugin';

export type AdminResourceStatus = 'unknown' | 'active' | 'inactive';

export type AdminResource = {
  name: string;
  created_at?: string;
  created_by?: string;
  display_name: string;
  status?: AdminResourceStatus;
};

export const isAdminResourceActive = (resource: AdminResource) => resource?.status === 'active';

export const ADMIN_RESOURCE_API_NAMES = ['tenants', 'accesspolicies', 'tokens', 'clusters', 'licenses'] as const;
export type AdminApiPrefix = `${PluginApiPrefix}/admin/api/v3`;

export const getAdminApiPrefix: (pluginId: string) => AdminApiPrefix = (pluginId) =>
  `${getPluginApiPrefix(pluginId)}/admin/api/v3`;

export type AdminResourceApiName = (typeof ADMIN_RESOURCE_API_NAMES)[number];

export const ADMIN_RESOURCES_SINGULAR: Record<AdminResourceApiName, string> = {
  accesspolicies: 'access policy',
  clusters: 'cluster',
  licenses: 'license',
  tenants: 'tenant',
  tokens: 'token',
} as const;

export const ADMIN_RESOURCES_PLURAL: Record<AdminResourceApiName, string> = {
  accesspolicies: 'access policies',
  clusters: 'clusters',
  licenses: 'licenses',
  tenants: 'tenants',
  tokens: 'tokens',
} as const;

export type AdminResourceTypeString = 'license' | 'access_policy' | 'tenant' | 'instance' | 'cluster' | 'token';

export type AdminResourceList<T> = {
  items: T[];
  type: AdminResourceTypeString;
};
