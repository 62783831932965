import { CaseReducer, createEntityAdapter, createSlice, Draft, EntityState } from '@reduxjs/toolkit';
import { TypedActionCreator } from '@reduxjs/toolkit/dist/mapBuilders';

import { AdminResource, AdminResourceApiName } from '@common/types';

import { AdminResourceActions } from './adminResources.actions';

export type SpecialCaseReducer = {
  action: TypedActionCreator<string>;
  reducer: CaseReducer<any, any>;
};

export const createAdminResourceReducer = <T extends AdminResource>(
  name: AdminResourceApiName,
  actions: AdminResourceActions<T>,
  specialCaseReducers: SpecialCaseReducer[] = []
) => {
  const entityAdapter = createEntityAdapter<T>({ selectId: ({ name }: T) => name });
  const initialState = entityAdapter.getInitialState({});

  const { create, deactivate, fetchAll, fetchById, update } = actions;

  const slice = createSlice({
    name,
    extraReducers: (builder) => {
      // Upsert many (fetchAll)
      builder.addCase(fetchAll.fulfilled, (state: Draft<EntityState<T>>, action) => {
        entityAdapter.upsertMany(state as EntityState<T>, action.payload);
      });

      // Upsert one (create, deactive, fetchById, update)
      [create, deactivate, fetchById, update].forEach((singleEntityAction) => {
        if (!singleEntityAction) {
          return;
        }
        builder.addCase(singleEntityAction.fulfilled, (state, action) => {
          entityAdapter.upsertOne(state as EntityState<T>, action.payload);
        });
      });

      // Handle provided special cases
      specialCaseReducers.forEach(({ action, reducer }) => {
        builder.addCase(action, reducer);
      });
    },
    initialState,
    reducers: {},
  });

  return { entityAdapter, reducer: slice.reducer };
};
