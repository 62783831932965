import React from 'react';
import { cx } from '@emotion/css';

import { MatcherType } from '@common/types';
import { labelSelectorFromString, useUtilityStyles } from '@common/utils';

export const MatcherTypeSymbol: Record<string, string> = {
  [MatcherType.equal]: '=',
  [MatcherType.notEqual]: '!=',
  [MatcherType.match]: '=~',
  [MatcherType.noMatch]: '!~',
};

type Props = {
  selector: string;
};

export const LabelSelectorVisualiser = ({ selector: rawSelector = '' }: Props) => {
  const selector = labelSelectorFromString(rawSelector);
  const s = useUtilityStyles();
  const LabelMatcherStyles: Record<MatcherType, string> = {
    [MatcherType.equal]: s.colorGreen300,
    [MatcherType.match]: s.colorGreen300,
    [MatcherType.notEqual]: s.colorRed500,
    [MatcherType.noMatch]: s.colorRed500,
  };

  return (
    <div className={cx(s.colorStrong)}>
      &#123;
      {selector.map((matcher, i) => (
        <span key={i} className={cx(s.inlineBlock, s.paddingHorizontalXs)}>
          <span className={s.colorStrong}>{matcher.name}</span>
          <span className={cx(s.textBold, LabelMatcherStyles[matcher.type])}>{MatcherTypeSymbol[matcher.type]}</span>
          <span className={s.colorSemiWeak}>&quot;{matcher.value}&quot;</span>
          {i < selector.length - 1 && <span>,</span>}
        </span>
      ))}
      &#125;
    </div>
  );
};
