import { createTenant, deactivateTenant, getTenant, getTenants, updateTenant } from '@common/api';
import { Tenant } from '@common/types';

import { createAdminResourceAsyncThunkActions } from '../adminResources/adminResources.actions';

export const tenantActions = createAdminResourceAsyncThunkActions<Tenant>('tenants', {
  getAll: getTenants,
  getByName: getTenant,
  create: createTenant,
  deactivate: deactivateTenant,
  update: updateTenant,
});

export const { create, fetchAll, deactivate } = tenantActions;
