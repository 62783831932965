import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';

export const getStyles = (theme: GrafanaTheme2) => ({
  container: css`
    background-color: ${theme.colors.background.secondary};
    margin-bottom: ${theme.spacing(2)};
    padding: ${theme.spacing(3)};
  `,
  header: css`
    display: flex;
    align-items: baseline;
  `,
  tenantStatusDot: css`
    margin-right: ${theme.spacing(3)};
  `,
  namesContainer: css`
    flex: 1;
    font-size: ${theme.typography.h4.fontSize};
    overflow-wrap: anywhere;
  `,
  tenantName: css`
    color: ${theme.colors.text.secondary};
    font-size: ${theme.typography.bodySmall.fontSize};
  `,
  infoContainer: css`
    color: ${theme.colors.text.secondary};
    font-size: ${theme.typography.bodySmall.fontSize};
    width: 200px;
  `,
  timeAndClusterInfo: css`
    margin-right: ${theme.spacing(1)};
  `,
  actionContainer: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: ${theme.spacing(3)};
  `,
  editIcon: css`
    color: ${theme.colors.text.secondary};
    margin-left: ${theme.spacing(2)};
  `,
  deleteIcon: css`
    color: ${theme.colors.text.secondary};
  `,
  noStat: css`
    color: ${theme.colors.text.secondary};
    margin-top: ${theme.spacing(2)};
    margin-left: ${theme.spacing(2)};
  `,
  statContainer: css`
    display: flex;
    align-items: center;
    margin-top: ${theme.spacing(2)};
  `,
  defaultIngestionRate: css`
    flex: 1;
  `,
  ingestionRate: css`
    flex: 1;
    margin-left: ${theme.spacing(2)};
  `,
  collapse: css`
    margin-left: ${theme.spacing(1)};
    margin-top: ${theme.spacing(2)};
    color: ${theme.colors.text.secondary};
  `,
  limitContainer: css`
    margin-top: ${theme.spacing(0.5)};
    margin-left: ${theme.spacing(2)};
  `,
  limitNames: css`
    color: ${theme.colors.text.secondary}
    font-size: ${theme.typography.bodySmall.fontSize}
  `,
  limit: css`
    margin-left: ${theme.spacing(0.5)};
    padding-left: ${theme.spacing(1)};
    padding-right: ${theme.spacing(1)};
  `,
  overriddenLImits: css`
    color: ${theme.colors.text.secondary};
    font-family: monospace;
    font-weight: ${theme.typography.fontWeightBold};
    margin-left: ${theme.spacing(0.5)};
  `,
  deleteModal: css`
    font-size: ${theme.typography.body.fontSize};
  `,
});
