import React from 'react';

import { Button, useStyles2 } from '@grafana/ui';

import { CurrentTimeUTC } from '@common/components';

import { getStyles } from './TenantsActionBar.styles';

type Props = {
  onCreate: () => void;
};

export const TenantsActionBar: React.FC<Props> = ({ onCreate }) => {
  const styles = useStyles2(getStyles);

  return (
    <>
      {/* Create tenant */}
      <div className={styles.createTenantContainer}>
        <Button variant="primary" size="md" onClick={onCreate}>
          Create tenant
        </Button>
      </div>

      {/* Current UTC Time */}
      <div className={styles.timeContainer}>
        <CurrentTimeUTC />
      </div>
    </>
  );
};
