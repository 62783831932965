import { apiDelete, apiGet, apiPost, slugifyForUrl } from '@common/utils';

type DashboardSearchParameters = {
  dashboardIds?: string[];
  folderIds?: number[];
  limit?: number;
  page?: number;
  query?: string;
  starred?: boolean;
  tag?: string[];
  type?: 'dash-db' | 'dash-folder';
};

export type DashboardSearchResult = {
  uid: string;
  id: number;
  title: string;
  folderId: number;
  folderUid: string;
  tags: string[];
  type: 'dash-db' | 'dash-folder';
};

// From /api/dashboards/uid/${dashboardUid}
export type Dashboard = {
  // This contains the complete dashboard model
  dashboard: {
    // The rest of the dashboard model
    [dashboardProperty: string]: any;

    // null to create a new dashboard.
    uid?: string;

    // null will generate a new uid.
    id?: string;
  };
  // The id of the folder to save the dashboard in.
  folderId?: string;

  // The UID of the folder to save the dashboard in. Overrides the folderId.
  folderUid?: string;

  // Set a commit message for the version history.
  message: string;

  // Set to true if you want to overwrite existing dashboard with newer version, same dashboard title in folder or same dashboard uid.
  overwrite?: boolean;

  // Set the dashboard refresh interval. If this is lower than the minimum refresh interval, then Grafana will ignore it and will enforce the minimum refresh interval.
  refresh: string;
};

type DashboardModificationResult = {
  uid: string;
  id: number;
  status: 'success' | 'version-mismatch' | 'name-exists' | 'plugin-dashboard';
  url: string;
  version: number;
};

export const searchDashboardsAndFolders = async (parameters: DashboardSearchParameters) =>
  (await apiGet<DashboardSearchResult[]>('/api/search', { params: parameters })).data;

export const createNewDashboardFolder = async (folderName: string) => {
  const uid = slugifyForUrl(folderName);

  return (
    await apiPost<DashboardSearchResult>('/api/folders', {
      data: {
        uid,
        title: folderName,
      },
    })
  ).data;
};

export const deleteDashboardFolder = (folderUid: string, forceDeleteRules = false) => {
  return apiDelete(`/api/folders/${folderUid}?forceDeleteRules=${forceDeleteRules}`);
};

export const moveDashboard = async (dashboardUid: string, folderId: number) => {
  const dashboardData = (await apiGet<Dashboard>(`/api/dashboards/uid/${dashboardUid}`)).data;

  const response = await apiPost<DashboardModificationResult>('/api/dashboards/db/', {
    data: {
      dashboard: dashboardData.dashboard,
      folderId,
      overwrite: true,
    },
  });

  const status = response.data.status;
  return status === 'success';
};
