import React, { useState } from 'react';
import { cx } from '@emotion/css';

import { Alert, Button, HorizontalGroup, Icon, IconName, LoadingBar, Tooltip } from '@grafana/ui';

import { useForgetMember, useRingMembers } from '@common/state/src/rings';
import { RingMember, RingMetadata, RingType } from '@common/types';
import {
  fixMimirDate,
  isValidDate,
  RingMemberField,
  RingMemberRenderers,
  transformRingResponse,
  useUtilityStyles,
} from '@common/utils';

import { LoadingIndicator } from '../LoadingIndicator';
import { RingMemberStateDot } from '../RingMemberStateDot/RingMemberStateDot';
import { RingTokensModal } from '../RingTokensModal/RingTokensModal';
import { Table } from '../Table';
import { TimeAgo } from '../TimeAgo';

import t from './text';

export const Ring = ({ ringType, metadata }: { ringType: RingType; metadata: RingMetadata }) => {
  let issueIcon: IconName | undefined;
  let issue: string | undefined;

  if (!ringType) {
    return <RingIssueMessage issue={t.ringSelection.none} />;
  }

  const { activeMemberIds, serviceAvailable, shardingDisabled, unhealthyMemberIds } = metadata;

  if (!serviceAvailable) {
    issue = t.ringSelection.inactive(ringType);
  } else if (shardingDisabled) {
    issue = t.ringSelection.shardingDisabled(ringType);
  } else {
    const totalMembers = activeMemberIds.length + unhealthyMemberIds.length;
    if (totalMembers === 0) {
      issue = t.ringSelection.pending(ringType);
    }
  }

  if (issue) {
    return <RingIssueMessage issue={issue} icon={issueIcon} />;
  }

  // If there were no issues, we can comfortably send the ring table
  return <RingTable ringType={ringType} />;
};

const RingTable = (props: { ringType: RingType }) => {
  const { ringType } = props;

  const s = useUtilityStyles();
  const [selectedRingMember, setSelectedMember] = useState<RingMember>();

  const { data, isError, error, isLoading, isFetching } = useRingMembers(ringType);
  const forgetRingMember = useForgetMember(ringType);
  const items = data?.ringMembers;
  const memberCount = items ? items.length : 0;

  const dismissTokensModal = () => setSelectedMember(undefined);

  // We don't necessarily want to have the field renderers to be named components below:
  /* eslint-disable react/display-name */
  const fieldRenderers: RingMemberRenderers = {
    [RingMemberField.Id]: ({ id, isForgetting }) => (
      <span style={(isForgetting && { textDecoration: 'line-through' }) || undefined}>{id || '-'}</span>
    ),
    [RingMemberField.Address]: ({ address }) => address || '-',
    [RingMemberField.Zone]: ({ zone }) => zone || '-',
    [RingMemberField.Ownership]: () => `${Number(100 / memberCount).toFixed(2)}%`,
    [RingMemberField.LastHeartbeat]: ({ timestamp }) => {
      if (!isValidDate(timestamp)) {
        // Try to fix the timestamp
        const fixedTimestamp = fixMimirDate(timestamp);
        if (isValidDate(fixedTimestamp)) {
          return <TimeAgo date={fixedTimestamp} />;
        }
      }

      // Either we gave up trying to fix it, or we trust the original timestamp
      return <TimeAgo date={timestamp} />;
    },
    [RingMemberField.State]: ({ state, isForgetting }) => (
      <div className={cx(s.flex, s.justifyStart)}>{!isForgetting && <RingMemberStateDot state={state} />}</div>
    ),
    [RingMemberField.Tokens]: (ingester) => {
      const tokenCount = ingester.tokens?.length || 0;

      // We would only like to show the option for viewing the tokens
      // if the ingester has tokens.
      if (tokenCount) {
        return (
          <Tooltip content={t.ringTable.viewTokens} placement="bottom">
            <div
              role="button"
              tabIndex={0}
              onClick={() => setSelectedMember(ingester)}
              onKeyDown={() => setSelectedMember(ingester)}
            >
              {tokenCount} <Icon name="eye" />
            </div>
          </Tooltip>
        );
      }

      return (
        <>
          {tokenCount} <Icon name="eye" />
        </>
      );
    },
    [RingMemberField.Actions]: ({ id, isForgetting }) => (
      <div className={cx(s.flex, s.justifyEnd)}>
        <Button variant="secondary" size="md" disabled={isForgetting} onClick={() => forgetRingMember(id)}>
          {t.ringTable.forget}
        </Button>
      </div>
    ),
  };
  /* eslint-enable react/display-name */

  if (isError) {
    const message = typeof error === 'string' ? error : JSON.stringify(error);
    return (
      <Alert title="Failed to retrieve ring status" severity="error">
        {message}
      </Alert>
    );
  }

  // Currently we wouldn't like to display the loading spinner if there is any loading
  // in the background as we are trying to build an "optimistic UI".
  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (!memberCount) {
    return <div className={cx(s.flex, s.justifyCenter, s.colorWeak, s.textLg, s.marginLg)}>{t.ringTable.empty}</div>;
  }

  // TableData is almost identical to the one that the <Table> component from @grafana/ui expects for the easy transition
  // once that component is starting to support custom renderers for columns.
  const tableData = transformRingResponse(items || [], fieldRenderers);

  return (
    <div style={{ paddingTop: isFetching ? 0 : 1 }}>
      {isFetching && <LoadingBar width={512} delay={0} />}
      {/* Tokens Modal */}
      <RingTokensModal
        ringMember={selectedRingMember}
        onDismiss={dismissTokensModal}
        isOpen={Boolean(selectedRingMember)}
      />

      {/* List of Ring Members */}
      <Table
        className={cx(s.widthFull, s.marginAuto, s.colorSemiWeak, s.marginTopSm)}
        thClassName={cx(s.textBold, s.textMd, s.paddingMd, s.colorGray200)}
        tdClassName={s.paddingMd}
        data={tableData}
        placement="bottom-start"
      />
    </div>
  );
};

const RingIssueMessage = ({ icon, issue }: { icon?: IconName; issue: React.ReactNode }) => {
  const s = useUtilityStyles();

  return (
    <HorizontalGroup justify="center">
      {icon && <Icon name={icon} size="xl" />}
      <div className={cx(s.flex, s.justifyCenter, s.colorWeak, s.textLg, s.marginLg)}>{issue}</div>
    </HorizontalGroup>
  );
};
