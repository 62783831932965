import { createSelector } from 'reselect';

import { isAdminResourceActive } from '@common/types';

import { RootState } from '../store';

import { tokensAdapter } from './tokens.reducer';

export const selectRoot = (state: RootState) => state.tokens;

const selectors = tokensAdapter.getSelectors(selectRoot);
const { selectAll: selectAllIncludingInactive } = selectors;
export const { selectById } = selectors;

export const selectAll = createSelector(selectAllIncludingInactive, (tokens) => tokens.filter(isAdminResourceActive));

export const getTokenWithSecret = createSelector(selectAll, (tokens) => tokens.find(({ token }) => token));

export const selectTokensForAccessPolicy = (accessPolicyName: string) =>
  createSelector(selectAll, (tokens) => tokens.filter(({ access_policy }) => access_policy === accessPolicyName));
