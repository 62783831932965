import { useCallback, useEffect, useState } from 'react';

import { Limits } from '@common/types';
import { getNotOverriddenLimits, getOverriddenLimits, useSupportedLimitValues } from '@common/utils';

// Given a set of limitss
export const useLimitOverrideState = (initialLimits?: Limits) => {
  const availableLimits = useSupportedLimitValues();

  const [overriddenLimits, setOverriddenLimits] = useState<Limits>({} as Limits);
  const [notOverriddenLimits, setNotOverriddenLimits] = useState<Limits>({});

  const setUpdatedLimits = useCallback(
    (updatedOverriddenLimits: Limits) => {
      const overridden = getOverriddenLimits(updatedOverriddenLimits, availableLimits);
      const notOverridden = getNotOverriddenLimits(updatedOverriddenLimits, availableLimits);
      setOverriddenLimits(overridden);
      setNotOverriddenLimits(notOverridden);
      // Returning from the scope we just defined here

      return { notOverridden, overridden };
    },
    [setOverriddenLimits, setNotOverriddenLimits, availableLimits]
  );

  const getDefaultLimit = useCallback((limitName: string) => availableLimits[limitName], [availableLimits]);

  // Update once the available limits from the config have been fetched
  useEffect(() => {
    if (availableLimits) {
      setUpdatedLimits(getOverriddenLimits(initialLimits || {}, availableLimits));
    }
  }, [availableLimits, initialLimits, setUpdatedLimits]);

  return {
    getDefaultLimit,
    notOverriddenLimits,
    overriddenLimits,
    setUpdatedLimits,
  };
};
