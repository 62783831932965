import React from 'react';
import { cx } from '@emotion/css';

import { Spinner } from '@grafana/ui';

import { useUtilityStyles } from '@common/utils';

type Props = {
  text?: string;
};

export const LoadingIndicator = ({ text = 'Loading...' }: Props) => {
  const s = useUtilityStyles();

  return (
    <div className={cx(s.flex, s.justifyCenter)}>
      <div className={s.marginRightSm}>{text}</div>
      <Spinner />
    </div>
  );
};
