import React from 'react';
import { cx } from '@emotion/css';

import { useUtilityStyles } from '@common/utils';

export const ErrorScreenAccessDenied = () => {
  const s = useUtilityStyles();

  return (
    <div className={cx(s.flex, s.flexColumn, s.alignCenter, s.justifyCenter, s.marginTopLg)}>
      <h2 className={cx(s.textLeft, s.width500)}>Access Denied</h2>
      <div className={cx(s.colorWeak, s.textLeft, s.width500)}>You need admin permissions to use this plugin.</div>
    </div>
  );
};
