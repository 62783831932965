import React from 'react';

import { Alert } from '@grafana/ui';

import { grafanaVersionRange, isGrafanaVersionSupported } from '@common/utils';

export const GrafanaVersionWarning = () => {
  if (isGrafanaVersionSupported()) {
    return null;
  }

  return (
    <Alert severity="warning" title="The current Grafana version is not supported">
      This plugin officially supports the following Grafana versions: <var>{grafanaVersionRange}</var>
    </Alert>
  );
};
