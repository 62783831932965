import { Scope } from '@common/types';

export const getScopeDescription = (scopeType: string) => {
  switch (scopeType) {
    case Scope.Admin:
      return 'Permission to use the admin API';
    case Scope.AlertsRead:
      return 'Permission to read alerts';
    case Scope.AlertsWrite:
      return 'Permission to write alerts';
    case Scope.RulesRead:
      return 'Permission to read alerting and recording rules from the ruler';
    case Scope.RulesWrite:
      return 'Permission to create and edit alerting and recording rules in the ruler';
    default:
      return `Permission to ${scopeType.split(':').reverse().join(' ')}`;
  }
};
