import { createAsyncThunk } from '@reduxjs/toolkit';

import * as api from '@common/api';
import { GeneratedDatasource } from '@common/types';
import { createRequestError } from '@common/utils';

export const create = createAsyncThunk(
  'generatedDatasources/create',
  async (datasource: GeneratedDatasource, thunkApi) => {
    try {
      const { name, basicAuthPassword, basicAuthUser, type, url } = datasource;
      const response = await api.createDatasource({
        name,
        basicAuthPassword,
        basicAuthUser,
        type,
        url,
      });

      const { id } = response;

      return { id, uid: response?.datasource?.uid, name, type, url };
    } catch (e) {
      return thunkApi.rejectWithValue(createRequestError('Unable to create new data source', e));
    }
  }
);
