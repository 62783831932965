import { createSelector } from 'reselect';

import { AccessPolicy, isAdminResourceActive, Token } from '@common/types';
import { sortByDateField } from '@common/utils';

import { RootState } from '../store';
import { selectAll as selectAllTokens } from '../tokens';

import { accessPoliciesAdapter } from './accessPolicies.reducer';

const selectRoot = (state: RootState) => state.accesspolicies;
const selectors = accessPoliciesAdapter.getSelectors<RootState>(selectRoot);

const { selectAll: selectAllIncludingInactive } = selectors;
export const { selectById } = selectors;

export const selectAll = createSelector(selectAllIncludingInactive, selectAllTokens, (accessPolicies, tokens) =>
  sortByDateField(
    enrichWithActiveTokens(accessPolicies.filter(isAdminResourceActive), tokens.filter(isAdminResourceActive)),
    'created_at'
  )
);

const enrichWithActiveTokens = (accessPolicies: AccessPolicy[], tokens: Token[]) =>
  accessPolicies.map((accessPolicy) => ({
    ...accessPolicy,
    tokens: tokens.filter(({ access_policy }) => access_policy === accessPolicy.name),
  }));
