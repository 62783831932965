import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';

import { colors } from '@common/utils';

export const getStyles = (className?: string) => (theme: GrafanaTheme2) => ({
  container: css`
    background-color: ${theme.colors.background.primary};
    border-radius: ${theme.spacing(1)};
    padding: ${theme.spacing(2)};
    ${className};
  `,
  label: css`
    font-size: ${theme.typography.bodySmall.fontSize};
    color: ${theme.colors.text.secondary};
  `,
  stat: css`
    font-size: ${theme.typography.h2.fontSize};
    font-weight: ${theme.typography.fontWeightBold};
  `,
  colorGreen200: css`
    color: ${colors.green200};
  `,
  colorRed: css`
    color: ${colors.red400};
  `,
  colorYellow: css`
    color: ${colors.yellow400};
  `,
  colorBlue200: css`
    color: ${colors.blue200};
  `,
});
