import React, { useContext } from 'react';
import { isEmpty } from 'ramda';

import { AppPluginMeta, KeyValue, NavModel, NavModelItem } from '@grafana/data';
import { getBackendSrv } from '@grafana/runtime';

import { AppPluginSettings, getPluginApiPrefix, Page } from '@common/types';

import { safeBase64 } from './general';

// The name of the query parameter in the URL that is holding the ID of the currently active route.
export const QUERY_PARAM = 'path';
export const QUERY_PATH_DELIMITER = '.';

export const getPluginLogo = (meta: AppPluginMeta<Record<string, any>>) => meta.info.logos.large;

export const transformPagesToNavModelItems = (pages: Page[], activePage: Page, id: string) =>
  pages.map((page) => ({
    id: page.id,
    active: page.id === activePage.id,
    icon: page.icon,
    text: page.label,
    url: `a/${id}/${page.id}`,
  }));

export const getNavModel = (
  title: string,
  subTitle: string,
  tabs: Page[],
  basePath: string,
  activeTab: Page,
  logoUrl: string,
  id: string
) => {
  const main: NavModelItem = {
    children: transformPagesToNavModelItems(tabs, activeTab, id),
    img: logoUrl,
    subTitle,
    text: title,
    url: basePath,
  };

  const navModel: NavModel = {
    main,
    node: main,
  };

  return navModel;
};

export const isRouteIdValid = (id: string) => /^[a-zA-Z0-9-]+$/.test(id);

export const getPageIds = (query: Record<string, any>) => {
  return query[QUERY_PARAM] ? query[QUERY_PARAM].split(QUERY_PATH_DELIMITER) : [];
};

export const getRouteIdFromUrl = (path: string, appId: string) => {
  if (path) {
    const [, route] = path.split(`${appId}/`);
    const [id] = route ? route.split('/') : ['tenants'];
    return id ? [id] : [];
  }
  return [];
};

export const hasSameId = (id: string) => (page: Page) => page.id === id;

export const findChildPage = (page: Partial<Page>, id: string) => page.children?.find(hasSameId(id));

export const findActivePages = (pages: Page[], query: Record<string, any>, path: string, id: string) => {
  const currentPageIds = !isEmpty(query) ? getPageIds(query) : getRouteIdFromUrl(path, id);
  const activePages = currentPageIds.reduce((currentActivePages: Page[], pageId: string) => {
    if (!currentActivePages.length) {
      const topmostPage = pages.find(hasSameId(pageId)) || pages[0];

      return [topmostPage];
    }

    const parentPage = currentActivePages[currentActivePages.length - 1];
    const childPage = findChildPage(parentPage, pageId);

    // Only append the page if it exists
    return childPage ? [...currentActivePages, childPage] : currentActivePages;
  }, []);

  return activePages.length ? activePages : [pages[0]];
};

export const PluginMetaContext = React.createContext<{
  pluginMeta?: AppPluginMeta<AppPluginSettings>;
  setPluginJsonData?: (jsonData?: AppPluginSettings, secureJsonData?: KeyValue<any>) => Promise<void>;
  onConfigurationPage?: boolean;
}>({});

export const usePluginMeta = () => {
  const { pluginMeta, setPluginJsonData, onConfigurationPage } = useContext(PluginMetaContext);

  const { id } = pluginMeta || {};

  if (id == null) {
    throw new Error('Unexpected error: pluginMeta is not aware of the plugin id');
  }

  const pluginConfigUrl = `/plugins/${id}`;
  const pluginAppUrl = `/a/${id}`;
  const pluginResourceUrlPrefix = getPluginApiPrefix(id);

  return { pluginMeta, setPluginJsonData, onConfigurationPage, pluginConfigUrl, pluginAppUrl, pluginResourceUrlPrefix };
};

export const ACCESS_TOKEN_ERRORS = {
  INVALID_BASE64: 'Invalid Base64: it needs to be a correctly encoded base64 representation.',
  INVALID_FORMAT: 'Invalid Format: it needs to be in the following format: base64("<name>:<password>")',
  NAME_EMPTY: 'The "name" part of the access token is empty.',
  PASSWORD_EMPTY: 'The "password" part of the access token is empty.',
  PASSWORD_LENGTH: (length: number) =>
    `Invalid password length of ${length} characters. The accepted password length is ${ACCESS_TOKEN_PASSWORD_LENGTH} characters.`,
};

const ACCESS_TOKEN_PASSWORD_LENGTH = 24;

// A token is a base64 encoded string in the following format:
// base64("<name>:<password>")
export const isLicenseTokenValid = (base64Token: string) => !getLicenseTokenError(base64Token);

export const getLicenseTokenError = (base64Token: string) => {
  const decoded = safeBase64(base64Token);

  if (decoded === null) {
    return ACCESS_TOKEN_ERRORS.INVALID_BASE64;
  }

  if (!decoded.includes(':')) {
    return ACCESS_TOKEN_ERRORS.INVALID_FORMAT;
  }

  const [name, password] = decoded.split(/:(.+)/, 2);

  if (!name) {
    return ACCESS_TOKEN_ERRORS.NAME_EMPTY;
  }

  if (!password) {
    return ACCESS_TOKEN_ERRORS.PASSWORD_EMPTY;
  }

  if (password.length !== ACCESS_TOKEN_PASSWORD_LENGTH) {
    return ACCESS_TOKEN_ERRORS.PASSWORD_LENGTH(password.length);
  }

  return undefined;
};

/**
 * When we post to `/api/plugins/${pluginId}/settings` we need to convert
 * the `jsonData.base64EncodedAccessToken` to use a specially encoded format.
 *
 * Background:
 * The `base64EncodedAccessToken` BASE64-decodes to: [lookup name]:[24 byte secret]
 * However, this string (in its encoded form) must be BASE64-encoded again, but not until
 * a `:` is prepended to it: `:[base64EncodedAccessToken]`
 *
 * This is the format expected by the server, and it relates to the idea of
 * performing an admin api update with a blank token name.
 *
 * @param base64EncodedAccessToken
 * @returns
 */
export const prepareTokenForAdminApiUpdate = (base64EncodedAccessToken: string) => {
  return btoa(`:${base64EncodedAccessToken}`);
};

// This is needed for cases when backendSrv() is not initialised yet
export const waitForBackendSrv = (callback: any) => {
  const interval = setInterval(() => {
    if (getBackendSrv()) {
      callback();
      clearInterval(interval);
    }
  }, 100);
};
