import { createSelector } from 'reselect';

import { RootState } from '../store';

import { generatedDatasourcesAdapter } from './generatedDatasources.reducer';

export const selectRoot = (state: RootState) => state.generatedDatasources;

export const { selectAll } = generatedDatasourcesAdapter.getSelectors(selectRoot);

export const selectByName = (generatedName: string) =>
  createSelector(selectAll, (datasources) => datasources.find(({ name }) => name === generatedName));

export const selectNames = createSelector(selectAll, (datasources) => datasources.map((datasource) => datasource.name));
