import React from 'react';

import { Alert, Button, useStyles2 } from '@grafana/ui';

import { getStyles } from './CreateTenantInfoBox.styles';

type Props = {
  onCreate: () => void;
};

export const CreateTenantInfoBox: React.FC<Props> = ({ onCreate }) => {
  const styles = useStyles2(getStyles);

  return (
    <Alert title="" severity="info">
      <div className={styles.noTenant}>There are no tenants yet.</div>
      <div className={styles.createBtn}>
        <Button variant="primary" size="lg" onClick={onCreate}>
          Create tenant
        </Button>
      </div>
      <div className={styles.description}>A tenant is a unique identifier that is scoped to a particular cluster.</div>
    </Alert>
  );
};
