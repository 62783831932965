import React from 'react';

import { AppPluginMeta } from '@grafana/data';

import { getPluginLogo } from '@common/utils';

type Props = {
  title?: string;
  name?: string;
  meta: AppPluginMeta;
};

export const Header = ({ title, name, meta }: Props) => {
  return (
    <>
      <span className="page-header__logo">
        <img className="page-header__img" src={getPluginLogo(meta)} alt={`logo of ${name}`} />
      </span>
      <div className="page-header__info-block">
        <h1 className="page-header__title">{name}</h1>
        <div className="page-header__sub-title">{title}</div>
      </div>
    </>
  );
};
