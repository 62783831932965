import React from 'react';
import { useSelector } from 'react-redux';
import { cx } from '@emotion/css';

import { Icon } from '@grafana/ui';

import { selectRequestInfo } from '@common/state/src/requests';
import { RequestStatus } from '@common/types';
import { capitalize, useUtilityStyles } from '@common/utils';

import { Collapse } from '../Collapse';

type Props = {
  // The "type" of the Redux action that was instantiating the request.
  actionType: string;
  className?: string;
};

/**
 * Shows API errors in a nice way for an async action initiated by a Redux action.
 */
export const ApiError = ({ actionType, className }: Props) => {
  const s = useUtilityStyles();
  const requestInfo = useSelector(selectRequestInfo(actionType));
  const isError = requestInfo?.status === RequestStatus.Error;

  if (isError) {
    return (
      <div
        className={cx(
          s.themeErrorColor,
          s.themeErrorBg,
          s.paddingVerticalSm,
          s.paddingHorizontalMd,
          s.rounded,
          className
        )}
      >
        <div className={cx(s.textBold)}>
          <Icon name="exclamation-triangle" className={s.marginRightSm} />
          {capitalize(requestInfo?.error?.data?.message || 'Unknown error')}
        </div>
        <Collapse title="More info" defaultIsOpen={false} className={cx(s.textSm, s.marginTopSm)}>
          <div className={cx(s.textMonospace, s.themeErrorColor)}>
            {requestInfo?.errorTitle}
            <br />
            {requestInfo?.errorMessage}
          </div>
        </Collapse>
      </div>
    );
  }

  return null;
};
