import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Select } from '@grafana/ui';

import { selectRequestInfo } from '@common/state/src/requests';
import { fetchAll, selectAll } from '@common/state/src/tenants';

import { LoadingIndicator } from '../LoadingIndicator';

export type TenantOption = {
  name: string;
  cluster: string;
  label: string;
  value?: string;
};

type Props = React.ComponentProps<typeof Select> & {
  /** Can be used to filter which tenants to show in the list. */
  tenantNames?: string[];
};

export const SelectTenant = ({ closeMenuOnSelect = false, tenantNames, ...props }: Props) => {
  const dispatch = useDispatch();
  const tenants = useSelector(selectAll);
  const request = useSelector(selectRequestInfo('tenants/fetchAll'));
  const tenantOptions: TenantOption[] = tenants
    .filter((tenant) => (tenantNames ? tenantNames.includes(tenant.name) : true))
    .map((tenant) => ({
      name: tenant.name,
      cluster: tenant.cluster || '',
      label: tenant.display_name,
      value: tenant.name,
    }));

  useEffect(() => {
    request.isNotFetched && dispatch(fetchAll());
  }, []); // eslint-disable-line

  if (request.isLoading) {
    return <LoadingIndicator />;
  }

  return <Select {...props} closeMenuOnSelect={closeMenuOnSelect} options={tenantOptions} />;
};
