import { pluralize } from '@common/utils';

// One line per definition probably looks better here -> disable line wrapping with Prettier
/* prettier-ignore */
export default {
  addTokenButton: 'Add token',
  defaultAccessPolicyInfoContent: 'Set the "admin_client.disable_default_admin_policy" flag to "true" in the GEM config file.',
  defaultAccessPolicyInfoTitle: 'How to disable this policy?',
  labelSelectorsLabel: (count: number) => (count > 0 ? `(${count} ${pluralize('label selector', count)})` : ''),
  noTokens: 'No tokens',
  pending: 'Tokens loading',
  scopesLabel: 'Scopes:',
  showTokensButton: 'Show tokens',
  tenantsLabel: (count?: number) => !count
    ? "No tenants"
    : `${
      Number.isFinite(count)
        ? count
        : 'all'
      } ${pluralize('tenant', count)
    }`,
  tokensLabel: (count: number) => `${count} ${pluralize('token', count)}`,
};
