import { DataSourceSettings } from '@grafana/data';

import { GeneratedDatasource } from '@common/types';
import { apiDelete, apiGet, apiPost, apiPut } from '@common/utils';

type CreateDatasourceResponse = {
  id: number;
  message: string;
  name: string;
  datasource: DataSourceSettings;
};

export const createDatasource = async ({ name, basicAuthPassword, basicAuthUser, type, url }: GeneratedDatasource) => {
  const { data } = await apiPost<CreateDatasourceResponse>('/api/datasources', {
    data: {
      name,
      access: 'proxy',
      basicAuth: true,
      basicAuthUser,
      secureJsonData: {
        basicAuthPassword,
      },
      type,
      url,
    },
  });

  return data;
};

export const getDatasources = async () => {
  const response = await apiGet<DataSourceSettings[]>('/api/datasources');

  return response.data;
};

export const deleteDatasource = async (id: number) => {
  return apiDelete(`/api/datasources/${id}`);
};

export const updateDatasource = async (datasource: DataSourceSettings) => {
  type Response = { datasource: DataSourceSettings; message: string };
  const response = await apiPut<Response>(`/api/datasources/${datasource.id}`, { data: datasource });

  return response.data;
};
