import { AdminResource } from 'common/types';
import { v4 as uuid } from 'uuid';

export const split = (text: string, subStr: string) => {
  const regex = new RegExp(escapeString(subStr), 'igm');

  return text.split(regex);
};

export const countAllMatches = (text: string, subStr: string) => {
  const matches = matchAll(text, subStr);

  return matches ? matches.length : 0;
};

export const matchAll = (text: string, subStr: string) => {
  const regex = new RegExp(escapeString(subStr), 'igm');

  return text.match(regex);
};

export const escapeString = (str: string) => {
  return str.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
};

export const capitalize = (str: string) => {
  return `${str.charAt(0).toUpperCase()}${str.slice(1).toLowerCase()}`;
};

// TODO: This is very far from being ideal, make it cover possible edge cases.
// We are going to upgrade once it becomes an issue.
// Example: https://stackoverflow.com/a/57129703
export const pluralize = (str: string, count: number) => {
  return `${str}${count !== 1 ? 's' : ''}`;
};

export const slugifyForUrl = (str: string) => {
  return str
    .toLowerCase()
    .replace(/[^\w ]+/g, '')
    .replace(/ +/g, '-');
};

export const createUuid = uuid;

export function getLimitedLengthName(item: AdminResource, maxLength = 63) {
  const name = item.display_name || item.name || 'Nameless resource';
  return name.length > maxLength ? `${name.substring(0, maxLength)}...` : name;
}
