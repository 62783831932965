import { useContext } from 'react';

import { AccessPolicy } from '@common/types';
import { BackendContext, SYSTEM_MONITORING_ACCESS_POLICY_NAME } from '@common/utils';

import { createHooks } from '../adminResources/adminResources.hooks';
import { RootState } from '../store';

import { accessPolicyActions } from './accessPolicies.actions';
import { entityAdapter } from './accessPolicies.reducer';
import { selectAll } from './accessPolicies.selectors';

const selectRoot = (state: RootState) => state.accesspolicies;
const selectors = entityAdapter.getSelectors(selectRoot);

export const {
  useFetchById,
  useGetAll: useGetAllUnfiltered,
  useDeactivate,
  useCreate,
  useCreateLoading,
  useUpdate,
  useUpdateLoading,
} = createHooks<AccessPolicy>(accessPolicyActions, selectors, selectAll);

/* Filters self-monitoring if appropriate */
export const useGetAll = () => {
  const {
    backend: {
      features: { self_monitoring },
    },
  } = useContext(BackendContext);

  const result = useGetAllUnfiltered();

  if (self_monitoring) {
    return result;
  }

  // If self-monitoring is not enabled, activeResources should not include SYSTEM_MONITORING
  const activeResources = result.activeResources.filter((item) => item.name !== SYSTEM_MONITORING_ACCESS_POLICY_NAME);

  return { ...result, activeResources };
};
