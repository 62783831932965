import React from 'react';
import { cx } from '@emotion/css';

import { Tooltip, useStyles2 } from '@grafana/ui';

import { TenantStatus } from '@common/types';

import { getStyles } from './TenantStatusDot.styles';

type Props = {
  className?: string;
  status?: TenantStatus;
};

const TOOLTIP = {
  active: 'Active',
  inactive: 'Inactive',
  unknown: 'Unknown',
};

export const TenantStatusDot = ({ className, status }: Props) => {
  const styles = useStyles2(getStyles(className));
  const backgrounds = {
    active: styles.gradientGreen,
    inactive: styles.gradientRed,
    unknown: styles.gradientBlue,
  };
  const background = backgrounds[status || 'unknown'];
  const tooltip = TOOLTIP[status || 'unknown'];

  return (
    <Tooltip content={tooltip} placement="bottom">
      <div className={cx(background, styles.statusDot)} />
    </Tooltip>
  );
};
