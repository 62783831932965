import { AccessPolicy, AdminApiPrefix } from '@common/types';
import {
  adminApiDeactivate,
  adminApiGet,
  adminApiGetAllItems,
  adminApiPost,
  adminApiPut,
  excludeReadOnlyFields,
  RequestOptions,
} from '@common/utils';

const RESOURCE_TYPE = 'accesspolicies';

export const getAccessPolicies = async (prefix: AdminApiPrefix) => {
  const accessPolicies = await getRawAccessPolicies(prefix);
  accessPolicies.forEach(fixNullValues);
  return accessPolicies;
};

export const getRawAccessPolicies = async (prefix: AdminApiPrefix) => {
  const response = await adminApiGetAllItems<AccessPolicy>(prefix, RESOURCE_TYPE);
  return response.data.items || [];
};

export const getAccessPolicy = async (prefix: AdminApiPrefix, name: string, options?: RequestOptions) => {
  const response = await adminApiGet<AccessPolicy>(prefix, RESOURCE_TYPE, name, options);
  return response.data;
};

export const createAccessPolicy = async (prefix: AdminApiPrefix, accessPolicy: AccessPolicy) => {
  const data = excludeReadOnlyFields(accessPolicy);
  return (await adminApiPost<AccessPolicy>(prefix, RESOURCE_TYPE, { data })).data;
};

export const updateAccessPolicy = async (prefix: AdminApiPrefix, accessPolicy: AccessPolicy) => {
  const data = excludeReadOnlyFields(accessPolicy);
  const response = await adminApiPut<AccessPolicy>(prefix, RESOURCE_TYPE, accessPolicy.name, { data });
  return response.data;
};

export const deactivateAccessPolicy = async (prefix: AdminApiPrefix, accessPolicy: AccessPolicy) => {
  const response = await adminApiDeactivate<AccessPolicy>(prefix, RESOURCE_TYPE, accessPolicy.name, {
    data: accessPolicy,
  });
  return response.data;
};

const fixNullValues = (accessPolicy: AccessPolicy) => {
  // The react form hook `useFieldArray` does not handle nulls gracefully,
  // to we replace nulls with empty arrays after any fetch/create/update operation.
  accessPolicy.realms = accessPolicy.realms || [];
  accessPolicy.realms.forEach((realm) => (realm.label_policies = realm.label_policies || []));
};
