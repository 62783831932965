import React from 'react';
import { cx } from '@emotion/css';

import { useUtilityStyles } from '@common/utils';

import { EnterpriseLicenseAlerts } from '../EnterpriseLicenseAlerts';

export const ErrorScreenNoLicense = () => {
  const s = useUtilityStyles();

  return (
    <div className={cx(s.flex, s.flexColumn, s.alignCenter, s.justifyCenter, s.marginTop2Xl)}>
      <EnterpriseLicenseAlerts />
    </div>
  );
};
