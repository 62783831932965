import { Realm, Tenant, WILDCARD_TENANT_NAME } from '@common/types';

import { labelSelectorFromString, labelSelectorToString } from './labels';

export const getNotSelectedTenants = (realms: Realm[], availableTenants: Tenant[] = []) =>
  availableTenants.filter((tenant) => !realms.find((realm) => realm.tenant === tenant.name));

/**
 * Removes any Realm that is already represented by a wildcard for the same cluster
 */
export const filterWildcardRedundancies = (realms: Realm[]) => {
  const wildcardClusters = new Set(realms.filter(isWildcardRealm).map((realm) => realm.cluster));
  return realms.filter((realm: Realm) => !wildcardClusters.has(realm.cluster) || isWildcardRealm(realm));
};

export const formatSelectorsInRealms = (realms?: Realm[]) =>
  realms
    ? realms.map((realm) => ({
        ...realm,
        label_policies: realm.label_policies?.map((label_policy) => ({
          ...label_policy,
          selector: labelSelectorToString(labelSelectorFromString(label_policy.selector)),
        })),
      }))
    : [];

export const isWildcardRealm = (realm: Realm) => realm.tenant === WILDCARD_TENANT_NAME;
