import { AdminApiPrefix, Cluster } from '@common/types';
import { adminApiGet, adminApiGetAllItems } from '@common/utils';

export const getClusters = async (prefix: AdminApiPrefix) => {
  const response = await adminApiGetAllItems<Cluster>(prefix, 'clusters');

  return response.data;
};

export const getCluster = async (prefix: AdminApiPrefix, name: string) => {
  const response = await adminApiGet<Cluster>(prefix, 'clusters', name);

  return response.data;
};
