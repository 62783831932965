import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { clearSecrets as clearSecretsAction, create, getTokenWithSecret } from '@common/state/src/tokens';
import { AccessPolicy, Token } from '@common/types';

import { CreateTokenModal } from './CreateTokenModal';

type Props = {
  accessPolicy: AccessPolicy;
  isOpen: boolean;
  onDismiss: () => void;
};

export const CreateTokenModalContainer = ({ accessPolicy, isOpen, onDismiss }: Props) => {
  const dispatch = useDispatch();
  const tokenWithSecret = useSelector(getTokenWithSecret);
  const onCreate = (token: Token) => dispatch(create(token));
  const clearSecrets = () => dispatch(clearSecretsAction());

  return (
    <CreateTokenModal
      isOpen={isOpen}
      onDismiss={onDismiss}
      onCreate={onCreate}
      tokenWithSecret={tokenWithSecret}
      accessPolicy={accessPolicy}
      clearSecrets={clearSecrets}
    />
  );
};
