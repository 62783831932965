import { dump, load } from 'js-yaml';

export const fromYaml = (str: string): any => {
  // Not handling errors here on a purpose, let the call site do it.
  return load(str) || {};
};

export const fromYamlStream = (str: string): any[] => {
  return str.split('\n---\n').map((s) => fromYaml(s));
};

export const toYaml = (obj: unknown) => {
  return dump(obj);
};
