import React, { useRef, useState } from 'react';

import { Button, Icon, RadioButtonGroup, Tooltip, useStyles2 } from '@grafana/ui';

import { TextHighlighter } from '@common/components';
import { isArray, isBoolean, isEmptyArray, isNumber, isString, stringifyLimit } from '@common/utils';

import { getStyles } from './TenantLimitsRow.styles';

export const TEST_ID = 'tenant-limits-row';
export const ARRAY_VALUE_PLACEHOLDER = 'E.g. label1, label2';

type Props = {
  /** (Optional) A CSS class name for custom styling. */
  name: string;
  /** A default value that is going to be used as a placeholder in the inputs. */
  className?: string;
  /** (Optional) Uses stronger colors for displaying the limit. (used for custom limits) */
  defaultValue: any;
  /** The name of the limit. */
  isHighlighted?: boolean;
  /** A callback that is called every time the limit is updated. */
  onChange: (value: any) => void;
  /** (Optional) A search query that is going to be highlighted in the name of the limits (if found). */
  searchQuery?: string;
  /** The value of the limit. */
  value: any;
};

export const TenantLimitsRow: React.FC<Props> = ({
  name,
  defaultValue,
  isHighlighted,
  onChange,
  searchQuery,
  value,
}) => {
  const styles = useStyles2(getStyles(isHighlighted));
  const [innerValue, setInnerValue] = useState(value);
  const [isEditing, setIsEditing] = useState(false);
  const inputEl = useRef(null);
  const innerValueAsString = stringifyLimit(innerValue);
  const defaultValueAsString = stringifyLimit(defaultValue);
  const isArrayValueWithElements = isArray(innerValue) && !isEmptyArray(innerValue);
  const onEdit = () => setIsEditing(true);

  const onSetToDefault = () => {
    setInnerValue(defaultValue);
    onChange(defaultValue);
  };

  const onCancelEditing = () => {
    setIsEditing(false);
    setInnerValue(value);
  };

  const onUpdate = () => {
    setIsEditing(false);
    onChange(innerValue);
  };

  const keyHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const stopBubbling = () => {
      e.stopPropagation();
      e.preventDefault();
    };

    if (e.key === 'Escape') {
      stopBubbling();
      onCancelEditing();
    }

    if (e.key === 'Enter' && innerValueAsString.length) {
      // Prevent submission while value is negative
      if (parseInt(innerValueAsString, 10) >= 0) {
        stopBubbling();
        onUpdate();
      } else {
        e.preventDefault();
      }
    }
  };

  const renderInput = () => {
    return (
      <input
        autoFocus
        ref={inputEl}
        value={innerValue}
        type={isNumber(value) ? 'number' : 'text'}
        placeholder={defaultValueAsString}
        className={styles.limitInput}
        onKeyDown={keyHandler}
        onChange={(e) =>
          setInnerValue(
            isNumber(value) && e.currentTarget.value ? parseInt(e.currentTarget.value, 10) : e.currentTarget.value
          )
        }
      />
    );
  };

  return (
    <div className={styles.container} data-testid={TEST_ID}>
      {/* Name */}
      <div className={styles.limitName}>
        {searchQuery && <TextHighlighter text={name} keyword={searchQuery} />}
        {!searchQuery && name}
      </div>

      {/* Value - Not editing */}
      {!isEditing && (
        <div role="button" tabIndex={0} className={styles.defaultValueContainer} onClick={onEdit} onKeyDown={onEdit}>
          <div className={styles.defaultValues}>
            {/* Array value types with elements - display as tags */}
            {isArrayValueWithElements &&
              innerValue.map((tag: string) => (
                <span key={tag} className={styles.tag}>
                  {tag}
                </span>
              ))}

            {/* Other value types or empty array - display as string */}
            {!isArrayValueWithElements && innerValueAsString}
          </div>
        </div>
      )}

      {/* Value - Editing */}
      {isEditing && (
        <div className={styles.edittedValueContainer}>
          {/* Value is string or numeric */}
          {(isString(value) || isNumber(value)) &&
            // Add tooltip to notice for negative numbers
            (parseInt(innerValue, 10) < 0 ? (
              <Tooltip content="Number cannot be negative" placement="left" theme="error" show={true}>
                {renderInput()}
              </Tooltip>
            ) : (
              renderInput()
            ))}

          {/* Value is boolean */}
          {isBoolean(innerValue) && (
            <div className={styles.valueType}>
              <RadioButtonGroup
                size="sm"
                value={innerValue}
                onChange={setInnerValue}
                options={[
                  { label: 'true', value: true },
                  { label: 'false', value: false },
                ]}
              />
            </div>
          )}

          {/* Value is array */}
          {isArray(innerValue) && (
            <div className={styles.valueType}>
              <input
                autoFocus
                ref={inputEl}
                defaultValue={innerValue.join(', ')}
                type="text"
                placeholder={ARRAY_VALUE_PLACEHOLDER}
                className={styles.arrayValueInput}
                onKeyDown={keyHandler}
                onChange={(e) =>
                  setInnerValue(
                    e.target.value
                      .split(',')
                      .map((s) => s.trim())
                      .filter(Boolean)
                  )
                }
              />
            </div>
          )}
        </div>
      )}

      {/* Actions */}
      <div className={styles.actionsContainer}>
        {/* Edit icon */}
        {!isEditing && (
          <div role="button" tabIndex={0} onClick={onEdit} onKeyDown={onEdit} className={styles.editIcon}>
            <Icon name="pen" size="xs" className={styles.penIcon} />
          </div>
        )}

        {/* Clear icon */}
        {isHighlighted && !isEditing && (
          <div
            role="button"
            tabIndex={0}
            onClick={onSetToDefault}
            onKeyDown={onSetToDefault}
            className={styles.clearIcon}
          >
            &times;
          </div>
        )}

        {/* Update & Cancel */}
        {isEditing && (
          <>
            <Button
              size="sm"
              disabled={innerValueAsString && (!innerValueAsString.length || parseInt(innerValueAsString, 10) < 0)}
              className={styles.buttonGroup}
              onClick={onUpdate}
            >
              Update
            </Button>
            <Button size="sm" variant="secondary" className={styles.buttonGroup} onClick={onCancelEditing}>
              Cancel
            </Button>
          </>
        )}
      </div>
    </div>
  );
};
