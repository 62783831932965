export enum RequestStatus {
  Error = 'Error',
  Pending = 'Pending',
  Success = 'Success',
}

// Used in Redux models for revealing information about the last requests statuses
export type RequestInfo = {
  // A counter which tells how many requests have been made for the model.
  // Can be useful to decide if the failing request has been the initial or a recurring one.
  counter: number;
  // The whole error object
  error?: any;
  // An optional error message
  errorMessage?: string;
  // A meaningful summary of the error
  errorTitle?: string;
  status: RequestStatus;
};
