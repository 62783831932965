import { AppEvents, BusEvent } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';

import { capitalize } from './string';

export const CREATION_ERROR = 'Creation Failed';

export type AlertType = keyof typeof AppEvents;
export type Category = {
  name: string;
  actionType: 'created' | 'updated';
  category: 'tenant' | 'policy' | 'token';
};

/**Call successAlert */
export const callAlertNotification = (alertType: AlertType, title: string, content: string) => {
  const event: BusEvent = { type: AppEvents[alertType].name, payload: [title, content] };
  getAppEvents().publish(event);
};

export const getAlertMessage = (
  alertType: AlertType,
  { name, actionType, category }: Category,
  errorResponse?: string
) => {
  switch (alertType) {
    case 'alertSuccess':
      const title = `${capitalize(category)} ${actionType}`;
      const content = `Successfully ${actionType} ${category} "${name}"`;
      return { title, content };
    // TODO We are currently handling the error alert, keep this function in mind for future usage if we need
    // to handle the error case from the frontend side
    default:
      return { title: '', content: `${errorResponse}` };
  }
};

export const popAlert = (category: Category['category'], actionType: Category['actionType'], name: string) => {
  const { title: successTitle, content: successContent } = getAlertMessage('alertSuccess', {
    name,
    actionType,
    category,
  });
  callAlertNotification('alertSuccess', successTitle, successContent);
};
