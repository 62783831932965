import { createSelector } from '@reduxjs/toolkit';

import { RequestInfo, RequestStatus } from '@common/types';

import { RootState } from '../store';

export type RequestInfoExtended = RequestInfo & {
  isError: boolean;
  isLoading: boolean;
  isNotFetched: boolean;
  isSuccess: boolean;
};

export const selectRoot = (state: RootState) => state.requests;

const createExtendedInfo = (requestInfo: RequestInfo) => {
  return {
    ...requestInfo,
    isError: requestInfo?.status === RequestStatus.Error,
    isLoading: requestInfo?.status === RequestStatus.Pending,
    isNotFetched: requestInfo === undefined,
    isSuccess: requestInfo?.status === RequestStatus.Success,
  } as RequestInfoExtended;
};

export const selectRequestInfo = (actionType: string) =>
  createSelector(selectRoot, (state: Record<string, RequestInfoExtended>) => {
    const requestInfo = state[actionType];

    return createExtendedInfo(requestInfo);
  });

export const selectMultipleRequestInfos = (requestTypes: Array<{ typePrefix: string }>) =>
  createSelector(selectRoot, (state: Record<string, RequestInfoExtended>) =>
    requestTypes.map((requestType) => createExtendedInfo(state[requestType.typePrefix]))
  );

export const selectRequestStatus = (actionType: string) =>
  createSelector(selectRequestInfo(actionType), (requestInfo) => requestInfo?.status);

export const selectIsLoading = (actionType: string) =>
  createSelector(selectRequestInfo(actionType), ({ isLoading }) => isLoading);

export const selectIsError = (actionType: string) =>
  createSelector(selectRequestInfo(actionType), ({ isError }) => isError);

export const selectIsSuccess = (actionType: string) =>
  createSelector(selectRequestInfo(actionType), ({ isSuccess }) => isSuccess);

export const selectIsNotFetched = (actionType: string) =>
  createSelector(selectRequestInfo(actionType), ({ isNotFetched }) => isNotFetched);
