import { createAction } from '@reduxjs/toolkit';

import { AdminApiPrefix, PluginApiPrefix } from '@common/types';

export type ApiPrefixes = {
  pluginPrefix: PluginApiPrefix;
  adminApiPrefix: AdminApiPrefix;
};

export const changeApiPrefixesAction = createAction<ApiPrefixes>('context/prefix');
