import React from 'react';
import { css, cx } from '@emotion/css';

import { dateTimeFormat } from '@grafana/data';

import { License as LicenseType } from '@common/types';
import { useUtilityStyles } from '@common/utils';

import { LicenseDataRow } from '../LicenseDataRow';

type Props = {
  item: LicenseType;
};

export const License: React.FC<Props> = ({ item }) => {
  const s = useUtilityStyles();

  return (
    <div className={cx(s.colorSemiWeak, s.paddingXl, s.marginBottomMd, s.bgLevel2)}>
      <div className={s.flex}>
        {/* Name */}
        <div className={cx(s.wrap, s.textLg, s.marginRightLg, s.width200)}>
          {item.display_name}
          {item.name && <div className={cx(s.textSm, s.colorWeak)}>License id: {item.name}</div>}
        </div>

        {/* Values */}
        <div
          className={cx(
            s.flex1,
            s.bgLevel1,
            s.rounded,
            s.paddingVerticalMd,
            s.paddingHorizontalLg,
            s.marginRightLg,
            css`
              &:last-child {
                margin-right: 0;
              }
            `
          )}
        >
          {item?.token && (
            <>
              {item.token.iss && <LicenseDataRow name="Issuer" value={item.token.iss} />}
              {item.token.iat && (
                <LicenseDataRow name="Issued at" value={dateTimeFormat(new Date(item.token.iat * 1000))} />
              )}
              {item.token.exp && (
                <LicenseDataRow name="Expires" value={dateTimeFormat(new Date(item.token.exp * 1000))} />
              )}
              {item.token.prod && <LicenseDataRow name="Products" value={item.token.prod.join(', ')} />}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
