import { PluginApiPrefix, RawTenantStats, TenantStats } from '@common/types';
import { apiGet, mapTenantStats } from '@common/utils';

export const getTenantStats = async (prefix: PluginApiPrefix): Promise<Map<string, TenantStats>> => {
  // GEL/GET doesn't support tenant statistics yet, so we are not erroring out loud if they return a 404
  try {
    const response = await apiGet<RawTenantStats[]>(`${prefix}/distributor/all_user_stats`, { showErrorAlert: false });
    const rawStats = response.data || [];
    const stats: TenantStats[] = rawStats.map(mapTenantStats);

    // Perform all enrichment jobs
    const enrichmentJobs = tenantStatsEnrichers.map((enricher) => enricher(stats));

    // Do not return from this method until all enrichment jobs are complete. Promise!
    await Promise.allSettled(enrichmentJobs);
    // Any errors will be ignored. We just won't get their enrichment.

    return new Map<string, TenantStats>(stats.map((s) => [s.name, s]));
  } catch (e) {
    console.error('Tenant statistics are not enabled yet.', e);
    return new Map();
  }
};

export type TenantStatsEnricher = (stats: TenantStats[]) => Promise<void>;
const tenantStatsEnrichers: TenantStatsEnricher[] = [];

/** Add an external method to supplement fetched `TenantStatistic` objects, e.g., with a product-specific query. */
export const addTenantStatsEnricher = (enricher: TenantStatsEnricher) => tenantStatsEnrichers.push(enricher);
