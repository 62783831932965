import { RawTenantStats, Tenant, TenantStats, WILDCARD_TENANT_NAME } from '@common/types';

import { formatThousands } from './numbers';

export const mapTenantStats = (rawTenantStat: RawTenantStats): TenantStats => ({
  name: rawTenantStat.userID,
  apiIngestionRate: rawTenantStat.APIIngestionRate,
  ingestionRate: rawTenantStat.ingestionRate,
  numberOfSeries: rawTenantStat.numSeries,
  ruleIngestionRate: rawTenantStat.RuleIngestionRate,
});

export const formatIngestionRate = (rate: number, interval = 's') => {
  return `${formatThousands(rate)}/${interval}`;
};

// Enriches the tenant objects with statistics
// (The reason for this is that the stats are fetched separately, they are not part of the original tenant object)
export const addStatsToTenants = (tenants: Tenant[], statistics: TenantStats[]) =>
  tenants.map((tenant: Tenant) => {
    const statistic = statistics.find(({ name }) => name === tenant.name);

    if (statistic) {
      return {
        ...tenant,
        statistic,
      };
    }

    return tenant;
  });

// A "reserved" tenant is created by the backend and cannot be deleted / mutated
export const isReservedTenant = (tenant: Tenant) => {
  return tenant.name.startsWith('__') && tenant.name.endsWith('__');
};

export const isNotAReservedTenant = (tenant: Tenant) => {
  return !isReservedTenant(tenant);
};

// A combined tenant name is an identifier that contains multiple tenant names and can be used
// to access multiple tenant with the same token (through a data source for example)
export const getCombinedTenantNames = (tenants: Tenant[]) =>
  tenants
    .map((tenant) => tenant.name)
    .sort()
    .join('|'); // e.g., "team-a|team-b" for use in basicAuthUser, or unique data source name portion

export const getCombinedTenantDisplayNames = (tenants: Tenant[]) =>
  tenants.map((tenant) => tenant.display_name).join(' | '); // e.g., "Team A | Team B" for use in on-screen display

// A wildcard tenant represents an tenant that has access to every tenant
export const getWildcardTenant = (cluster: string) =>
  ({ name: WILDCARD_TENANT_NAME, cluster, display_name: `All tenants in cluster` } as Tenant);

export const isWildcardTenant = (tenant: Tenant) => tenant.name === WILDCARD_TENANT_NAME;

// Returns a function that can be used to return the tenant's display name
// It is needed as in some cases we only have access to the name of the tenant (identifier), but we would need the display name
export const useGetTenantDisplayName = (tenants: Tenant[]) => {
  // To get the tenant display name from cluster and name, create a map to key these to the tenant objects
  const getTenantKey = (cluster: string, name: string) => `${cluster}.${name}`;
  const tenantsByKey = {} as { [key: string]: Tenant };
  tenants.forEach((tenant) => (tenantsByKey[getTenantKey(tenant.cluster as string, tenant.name)] = tenant));

  return (cluster?: string, name?: string) => {
    if (cluster && name) {
      const tenant = tenantsByKey[getTenantKey(cluster, name)];
      return tenant ? tenant.display_name : name;
    }

    return name;
  };
};
