import { css } from '@emotion/css';

import { GrafanaTheme } from '@grafana/data';
import { useStyles } from '@grafana/ui';

// Custom colors that we are using in our plugin
export const colors = {
  blue100: '#a7bddf',
  blue200: '#7c9dcf',
  blue400: '#245baf',
  blue600: '#19407a',
  blue700: '#122e58',
  gray100: '#e1e3e4',
  gray200: '#d3d4d7',
  gray400: '#b5b8bc',
  gray600: '#7f8184',
  gray700: '#484a4b',
  gray800: '#3c3c3c',
  green100: '#afcfa9',
  green200: '#6bca5a',
  green300: '#21ce00',
  green400: '#388729',
  green500: '#2d6c21',
  green600: '#275f1d',
  green700: '#1c4415',
  red100: '#ffc7d0',
  red200: '#ffabb8',
  red300: '#ff8fa1',
  red400: '#FF7389',
  red500: '#ff5670',
  red600: '#b35160',
  red700: '#803a45',
  yellow100: '#fdf7a4',
  yellow200: '#fbf477',
  yellow400: '#F9EC1C',
  yellow600: '#aea514',
  yellow700: '#7d760e',
};

// These values are larger than what we have available in Grafana Core, but they are on the same grid.
const spacing2Xl = '40px';
const spacing3Xl = '48px';

export const useUtilityStyles = () => {
  const styles = useStyles((theme?: GrafanaTheme) => {
    const isDark = Boolean(theme?.isDark);

    return {
      absolute: css`
        position: absolute;
      `,
      alignBaseline: css`
        align-items: baseline;
      `,
      alignCenter: css`
        align-items: center;
      `,
      alignEnd: css`
        align-items: flex-end;
      `,
      alignStart: css`
        align-items: flex-start;
      `,
      bgBlue: css`
        background-color: ${colors.blue400};
      `,
      bgBlue100: css`
        background-color: ${colors.blue100};
      `,
      bgBlue200: css`
        background-color: ${colors.blue200};
      `,
      bgBlue400: css`
        background-color: ${colors.blue400};
      `,
      bgBlue600: css`
        background-color: ${colors.blue600};
      `,
      bgBlue700: css`
        background-color: ${colors.blue700};
      `,
      bgGray: css`
        background-color: ${colors.gray400};
      `,
      bgGray100: css`
        background-color: ${colors.gray100};
      `,
      bgGray200: css`
        background-color: ${colors.gray200};
      `,
      bgGray400: css`
        background-color: ${colors.gray400};
      `,
      bgGray600: css`
        background-color: ${colors.gray600};
      `,
      bgGray700: css`
        background-color: ${colors.gray700};
      `,
      bgGray800: css`
        background-color: ${colors.gray800};
      `,
      bgGreen: css`
        background-color: ${colors.green400};
      `,
      bgGreen100: css`
        background-color: ${colors.green100};
      `,
      bgGreen200: css`
        background-color: ${colors.green200};
      `,
      bgGreen400: css`
        background-color: ${colors.green400};
      `,
      bgGreen600: css`
        background-color: ${colors.green600};
      `,
      bgGreen700: css`
        background-color: ${colors.green700};
      `,
      bgLevel1: css`
        background-color: ${theme?.colors.bg1};
      `,
      bgLevel2: css`
        background-color: ${theme?.colors.bg2};
      `,
      bgLevel3: css`
        background-color: ${theme?.colors.bg3};
      `,
      bgRed: css`
        background-color: ${colors.red400};
      `,
      bgRed100: css`
        background-color: ${colors.red100};
      `,
      bgRed200: css`
        background-color: ${colors.red200};
      `,
      bgRed400: css`
        background-color: ${colors.red400};
      `,
      bgRed600: css`
        background-color: ${colors.red600};
      `,
      bgRed700: css`
        background-color: ${colors.red700};
      `,
      bgYellow: css`
        background-color: ${colors.yellow400};
      `,
      bgYellow100: css`
        background-color: ${colors.yellow100};
      `,
      bgYellow200: css`
        background-color: ${colors.yellow200};
      `,
      bgYellow400: css`
        background-color: ${colors.yellow400};
      `,
      bgYellow600: css`
        background-color: ${colors.yellow600};
      `,
      bgYellow700: css`
        background-color: ${colors.yellow700};
      `,
      block: css`
        display: block;
      `,
      border: css`
        border-width: 1px;
      `,
      borderBlue: css`
        border-color: ${colors.blue400};
      `,
      borderBottom: css`
        border-width: 0 0 1px 0;
      `,
      borderCurrentColor: css`
        border-color: currentColor;
      `,
      borderDashed: css`
        border-style: dashed;
      `,
      borderGray: css`
        border-color: ${colors.gray400};
      `,
      borderGray200: css`
        border-color: ${colors.gray200};
      `,
      borderGray600: css`
        border-color: ${colors.gray600};
      `,
      borderGreen: css`
        border-color: ${colors.green400};
      `,
      borderLeft: css`
        border-width: 0 0 0 1px;
      `,
      borderNone: css`
        border-width: 0;
      `,
      borderRed: css`
        border-color: ${colors.red400};
      `,
      borderRight: css`
        border-width: 0 1px 0 0;
      `,
      borderSemiWeak: css`
        border-color: ${theme?.colors.textSemiWeak};
      `,
      borderSolid: css`
        border-style: solid;
      `,
      borderStrong: css`
        border-color: ${theme?.colors.textStrong};
      `,
      borderTop: css`
        border-width: 1px 0 0 0;
      `,
      borderVeryWeak: css`
        border-color: ${isDark ? colors.gray800 : colors.gray100};
      `,
      borderWeak: css`
        border-color: ${theme?.colors.textWeak};
      `,
      borderYellow: css`
        border-color: ${colors.yellow400};
      `,
      bottom0: css`
        bottom: 0;
      `,
      colorBlack: css`
        color: ${theme?.palette.black};
      `,
      colorBlue: css`
        color: ${colors.blue400};
      `,
      colorBlue100: css`
        color: ${colors.blue100};
      `,
      colorBlue200: css`
        color: ${colors.blue200};
      `,
      colorBlue400: css`
        color: ${colors.blue400};
      `,
      colorBlue600: css`
        color: ${colors.blue600};
      `,
      colorBlue700: css`
        color: ${colors.blue700};
      `,
      colorDanger: css`
        color: ${theme?.palette.brandDanger};
      `,
      colorFormDescription: css`
        color: ${theme?.colors.formDescription};
      `,
      colorFormInvalid: css`
        color: ${theme?.colors.formInputBorderInvalid};
      `,
      colorGray: css`
        color: ${colors.gray400};
      `,
      colorGray100: css`
        color: ${colors.gray100};
      `,
      colorGray200: css`
        color: ${colors.gray200};
      `,
      colorGray400: css`
        color: ${colors.gray400};
      `,
      colorGray600: css`
        color: ${colors.gray600};
      `,
      colorGray700: css`
        color: ${colors.gray700};
      `,
      colorGray800: css`
        color: ${colors.gray800};
      `,
      colorGreen: css`
        color: ${colors.green400};
      `,
      colorGreen100: css`
        color: ${colors.green100};
      `,
      colorGreen200: css`
        color: ${colors.green200};
      `,
      colorGreen300: css`
        color: ${colors.green300};
      `,
      colorGreen400: css`
        color: ${colors.green400};
      `,
      colorGreen500: css`
        color: ${colors.green500};
      `,
      colorGreen600: css`
        color: ${colors.green600};
      `,
      colorGreen700: css`
        color: ${colors.green700};
      `,
      colorPrimary: css`
        color: ${theme?.palette.brandPrimary};
      `,
      colorRed: css`
        color: ${colors.red400};
      `,
      colorRed100: css`
        color: ${colors.red100};
      `,
      colorRed200: css`
        color: ${colors.red200};
      `,
      colorRed300: css`
        color: ${colors.red300};
      `,
      colorRed400: css`
        color: ${colors.red400};
      `,
      colorRed500: css`
        color: ${colors.red500};
      `,
      colorRed600: css`
        color: ${colors.red600};
      `,
      colorRed700: css`
        color: ${colors.red700};
      `,
      colorSemiWeak: css`
        color: ${theme?.colors.textSemiWeak};
      `,
      colorStrong: css`
        color: ${theme?.colors.textStrong};
      `,
      colorSuccess: css`
        color: ${theme?.palette.brandSuccess};
      `,
      colorVeryWeak: css`
        color: ${isDark ? colors.gray800 : colors.gray100};
      `,
      colorWarning: css`
        color: ${theme?.palette.brandWarning};
      `,
      colorWeak: css`
        color: ${theme?.colors.textWeak};
      `,
      colorWhite: css`
        color: ${theme?.palette.white};
      `,
      colorYellow: css`
        color: ${colors.yellow400};
      `,
      colorYellow100: css`
        color: ${colors.yellow100};
      `,
      colorYellow200: css`
        color: ${colors.yellow200};
      `,
      colorYellow400: css`
        color: ${colors.yellow400};
      `,
      colorYellow600: css`
        color: ${colors.yellow600};
      `,
      colorYellow700: css`
        color: ${colors.yellow700};
      `,
      displayNone: css`
        display: none;
      `,
      fixed: css`
        position: fixed;
      `,
      flex: css`
        display: flex;
      `,
      flex1: css`
        flex: 1;
      `,
      flex2: css`
        flex: 2;
      `,
      flexColumn: css`
        flex-direction: column;
      `,
      flexNoShrink: css`
        flex-shrink: 0;
      `,
      flexRow: css`
        flex-direction: row;
      `,
      gradientBlue: css`
        background: linear-gradient(180deg, ${colors.blue100} 0%, ${colors.blue200} 100%);
      `,
      gradientGreen: css`
        background: linear-gradient(270deg, ${colors.green300} 0%, ${colors.green100} 100%);
      `,
      gradientRed: css`
        background: linear-gradient(180deg, ${colors.red300} 0%, ${colors.red500} 100%);
      `,
      gradientYellow: css`
        background: linear-gradient(180deg, ${colors.yellow200} 0%, ${colors.yellow600} 100%);
      `,
      heightFull: css`
        height: 100%;
      `,
      inline: css`
        display: inline;
      `,
      inlineBlock: css`
        display: inline-block;
      `,
      justifyCenter: css`
        justify-content: center;
      `,
      justifyEnd: css`
        justify-content: flex-end;
      `,
      justifyStart: css`
        justify-content: flex-start;
      `,
      left0: css`
        left: 0;
      `,
      lineHeightLg: css`
        line-height: ${theme?.typography.lineHeight.lg};
      `,
      lineHeightMd: css`
        line-height: ${theme?.typography.lineHeight.md};
      `,
      lineHeightSm: css`
        line-height: ${theme?.typography.lineHeight.sm};
      `,
      lineHeightXs: css`
        line-height: ${theme?.typography.lineHeight.xs};
      `,
      margin: css`
        margin: ${theme?.spacing.md};
      `,
      margin2Xl: css`
        margin: ${spacing2Xl};
      `,
      margin3Xl: css`
        margin: ${spacing3Xl};
      `,
      marginAuto: css`
        margin: auto auto;
      `,
      marginBottom: css`
        margin-bottom: ${theme?.spacing.md};
      `,
      marginBottom2Xl: css`
        margin-bottom: ${spacing2Xl};
      `,
      marginBottom3Xl: css`
        margin-bottom: ${spacing3Xl};
      `,
      marginBottomLg: css`
        margin-bottom: ${theme?.spacing.lg};
      `,
      marginBottomMd: css`
        margin-bottom: ${theme?.spacing.md};
      `,
      marginBottomNone: css`
        margin-bottom: 0 !important;
      `,
      marginBottomSm: css`
        margin-bottom: ${theme?.spacing.sm};
      `,
      marginBottomXl: css`
        margin-bottom: ${theme?.spacing.xl};
      `,
      marginBottomXs: css`
        margin-bottom: ${theme?.spacing.xs};
      `,
      marginLeft: css`
        margin-left: ${theme?.spacing.md};
      `,
      marginLeft2Xl: css`
        margin-left: ${spacing2Xl};
      `,
      marginLeft3Xl: css`
        margin-left: ${spacing3Xl};
      `,
      marginLeftLg: css`
        margin-left: ${theme?.spacing.lg};
      `,
      marginLeftMd: css`
        margin-left: ${theme?.spacing.md};
      `,
      marginLeftNone: css`
        margin-left: 0 !important;
      `,
      marginLeftSm: css`
        margin-left: ${theme?.spacing.sm};
      `,
      marginLeftXl: css`
        margin-left: ${theme?.spacing.xl};
      `,
      marginLeftXs: css`
        margin-left: ${theme?.spacing.xs};
      `,
      marginLg: css`
        margin: ${theme?.spacing.lg};
      `,
      marginMd: css`
        margin: ${theme?.spacing.md};
      `,
      marginNone: css`
        margin: 0 !important;
      `,
      marginRight: css`
        margin-right: ${theme?.spacing.md};
      `,
      marginRight2Xl: css`
        margin-right: ${spacing2Xl};
      `,
      marginRight3Xl: css`
        margin-right: ${spacing3Xl};
      `,
      marginRightLg: css`
        margin-right: ${theme?.spacing.lg};
      `,
      marginRightMd: css`
        margin-right: ${theme?.spacing.sm};
      `,
      marginRightNone: css`
        margin-right: 0 !important;
      `,
      marginRightSm: css`
        margin-right: ${theme?.spacing.sm};
      `,
      marginRightXl: css`
        margin-right: ${theme?.spacing.xl};
      `,
      marginRightXs: css`
        margin-right: ${theme?.spacing.xs};
      `,
      marginSm: css`
        margin: ${theme?.spacing.sm};
      `,
      marginTop: css`
        margin-top: ${theme?.spacing.md};
      `,
      marginTop2Xl: css`
        margin-top: ${spacing2Xl};
      `,
      marginTop3Xl: css`
        margin-top: ${spacing3Xl};
      `,
      marginTopLg: css`
        margin-top: ${theme?.spacing.lg};
      `,
      marginTopMd: css`
        margin-top: ${theme?.spacing.md};
      `,
      marginTopNone: css`
        margin-top: 0 !important;
      `,
      marginTopSm: css`
        margin-top: ${theme?.spacing.sm};
      `,
      marginTopXl: css`
        margin-top: ${theme?.spacing.xl};
      `,
      marginTopXs: css`
        margin-top: ${theme?.spacing.xs};
      `,
      marginXAuto: css`
        margin: 0 auto;
      `,
      marginXl: css`
        margin: ${theme?.spacing.xl};
      `,
      marginXs: css`
        margin: ${theme?.spacing.xs};
      `,
      marginYAuto: css`
        margin: auto 0;
      `,
      maxHeight300: css`
        max-height: 300px;
      `,
      maxWidth100: css`
        max-width: 100px;
      `,
      maxWidth1000: css`
        max-width: 1000px;
      `,
      maxWidth200: css`
        max-width: 200px;
      `,
      maxWidth300: css`
        max-width: 300px;
      `,
      maxWidth400: css`
        max-width: 400px;
      `,
      maxWidth500: css`
        max-width: 500px;
      `,
      maxWidth600: css`
        max-width: 600px;
      `,
      maxWidth700: css`
        max-width: 700px;
      `,
      maxWidth800: css`
        max-width: 800px;
      `,
      maxWidth900: css`
        max-width: 900px;
      `,
      noFocusOutline: css`
        &:focus {
          outline: none;
          outline-offset: 0;
          box-shadow: none;
        }
      `,
      noSelect: css`
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      `,
      noTransition: css`
        transition: none !important;
      `,
      outlineNone: css`
        outline: none;
      `,
      overflowHidden: css`
        overflow: hidden;
      `,
      overflowScroll: css`
        overflow: scroll;
      `,
      paddingBottomLg: css`
        padding-bottom: ${theme?.spacing.lg};
      `,
      paddingBottomMd: css`
        padding-bottom: ${theme?.spacing.md};
      `,
      paddingBottomSm: css`
        padding-bottom: ${theme?.spacing.sm};
      `,
      paddingBottomXl: css`
        padding-bottom: ${theme?.spacing.xl};
      `,
      paddingBottomXs: css`
        padding-bottom: ${theme?.spacing.xs};
      `,
      paddingHorizontalLg: css`
        padding-left: ${theme?.spacing.lg};
        padding-right: ${theme?.spacing.lg};
      `,
      paddingHorizontalMd: css`
        padding-left: ${theme?.spacing.md};
        padding-right: ${theme?.spacing.md};
      `,
      paddingHorizontalSm: css`
        padding-left: ${theme?.spacing.sm};
        padding-right: ${theme?.spacing.sm};
      `,
      paddingHorizontalXl: css`
        padding-left: ${theme?.spacing.xl};
        padding-right: ${theme?.spacing.xl};
      `,
      paddingHorizontalXs: css`
        padding-left: ${theme?.spacing.xs};
        padding-right: ${theme?.spacing.xs};
      `,
      paddingLeft: css`
        padding-left: ${theme?.spacing.md};
      `,
      paddingLeft2Xl: css`
        padding-left: ${spacing2Xl};
      `,
      paddingLeft3Xl: css`
        padding-left: ${spacing3Xl};
      `,
      paddingLeftLg: css`
        padding-left: ${theme?.spacing.lg};
      `,
      paddingLeftMd: css`
        padding-left: ${theme?.spacing.sm};
      `,
      paddingLeftSm: css`
        padding-left: ${theme?.spacing.sm};
      `,
      paddingLeftXl: css`
        padding-left: ${theme?.spacing.xl};
      `,
      paddingLeftXs: css`
        padding-left: ${theme?.spacing.xs};
      `,
      paddingLg: css`
        padding: ${theme?.spacing.lg};
      `,
      paddingMd: css`
        padding: ${theme?.spacing.md};
      `,
      paddingNone: css`
        padding: 0;
      `,
      paddingRight: css`
        padding-right: ${theme?.spacing.md};
      `,
      paddingRight2Xl: css`
        padding-right: ${spacing2Xl};
      `,
      paddingRight3Xl: css`
        padding-right: ${spacing3Xl};
      `,
      paddingRightLg: css`
        padding-right: ${theme?.spacing.lg};
      `,
      paddingRightMd: css`
        padding-right: ${theme?.spacing.sm};
      `,
      paddingRightNone: css`
        padding-right: 0 !important;
      `,
      paddingRightSm: css`
        padding-right: ${theme?.spacing.sm};
      `,
      paddingRightXl: css`
        padding-right: ${theme?.spacing.xl};
      `,
      paddingRightXs: css`
        padding-right: ${theme?.spacing.xs};
      `,
      paddingSm: css`
        padding: ${theme?.spacing.sm};
      `,
      paddingVerticalLg: css`
        padding-top: ${theme?.spacing.lg};
        padding-bottom: ${theme?.spacing.lg};
      `,
      paddingVerticalMd: css`
        padding-top: ${theme?.spacing.md};
        padding-bottom: ${theme?.spacing.md};
      `,
      paddingVerticalSm: css`
        padding-top: ${theme?.spacing.sm};
        padding-bottom: ${theme?.spacing.sm};
      `,
      paddingVerticalXl: css`
        padding-top: ${theme?.spacing.xl};
        padding-bottom: ${theme?.spacing.xl};
      `,
      paddingVerticalXs: css`
        padding-top: ${theme?.spacing.xs};
        padding-bottom: ${theme?.spacing.xs};
      `,
      paddingXl: css`
        padding: ${theme?.spacing.xl};
      `,
      paddingXs: css`
        padding: ${theme?.spacing.xs};
      `,
      pointer: css`
        cursor: pointer;
      `,
      relative: css`
        position: relative;
      `,
      resizeNone: css`
        resize: none;
      `,
      right0: css`
        right: 0;
      `,
      rounded: css`
        border-radius: 3px;
      `,
      roundedFull: css`
        border-radius: 100%;
      `,
      roundedLg: css`
        border-radius: 8px;
      `,
      spaceBetween: css`
        align-content: space-between;
      `,
      static: css`
        position: static;
      `,
      textBold: css`
        font-weight: ${theme?.typography.weight.bold};
      `,
      textCenter: css`
        text-align: center;
      `,
      textItalic: css`
        font-style: italic;
      `,
      textLeft: css`
        text-align: left;
      `,
      textLg: css`
        font-size: ${theme?.typography.size.lg};
      `,
      textMd: css`
        font-size: ${theme?.typography.size.md};
      `,
      textMonospace: css`
        font-family: monospace;
      `,
      textNormal: css`
        font-weight: normal;
      `,
      textOverflowClip: css`
        text-overflow: clip;
        overflow-y: scroll;
      `,
      textOverflowEllipsis: css`
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      `,
      textRight: css`
        text-align: right;
      `,
      textSemiBold: css`
        font-weight: ${theme?.typography.weight.semibold};
      `,
      textSm: css`
        font-size: ${theme?.typography.size.sm};
      `,
      textUnderline: css`
        text-decoration: underline;
      `,
      textXl: css`
        font-size: 24px;
      `,
      textXs: css`
        font-size: ${theme?.typography.size.xs};
      `,
      themeErrorBg: css`
        background-color: ${theme?.colors.formValidationMessageBg};
      `,
      themeErrorBorder: css`
        border-color: ${isDark ? colors.red400 : colors.red600};
      `,
      themeErrorColor: css`
        color: ${theme?.colors.formValidationMessageText};
      `,
      top0: css`
        top: 0;
      `,
      width100: css`
        width: 100px;
      `,
      width150: css`
        width: 150px;
      `,
      width200: css`
        width: 200px;
      `,
      width300: css`
        width: 300px;
      `,
      width400: css`
        width: 400px;
      `,
      width500: css`
        width: 500px;
      `,
      widthFull: css`
        width: 100%;
      `,
      wrap: css`
        overflow-wrap: anywhere;
      `,
    };
  });

  return styles;
};
