import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';

export const getStyles = (theme: GrafanaTheme2) => ({
  noTenant: css`
    font-style: italic;
    text-align: center;
  `,
  createBtn: css`
    text-align: center;
    padding-top: ${theme.spacing(3)};
    padding-bottom: ${theme.spacing(3)};
  `,
  description: css`
    text-align: center;
    color: ${theme.colors.text.secondary};
  `,
});
