import React, { useRef } from 'react';
import { cx } from '@emotion/css';
import debounce from 'lodash/debounce';

import { FieldValidationMessage } from '@grafana/ui';

import { useUtilityStyles } from '@common/utils';

import { TextArea } from '../TextArea';

type Props = {
  /** Optional additional CSS classes. */
  className?: string;
  /** An error message to show */
  error?: string;
  /** Called with the updated selector on every change. */
  onChange: (selector: string) => void;
  selector: string;
};

export const LabelSelectorEditor = ({ className, error = '', onChange, selector = '' }: Props) => {
  const s = useUtilityStyles();
  const ref = useRef<HTMLTextAreaElement>(null);
  const debouncedOnChange = debounce(onChange, 200);

  return (
    <>
      <TextArea
        rows={1}
        ref={ref}
        spellCheck="false"
        placeholder="E.g. foo=bar"
        defaultValue={selector}
        className={cx(s.resizeNone, s.noFocusOutline, s.noTransition, s.textSm, className)}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
          debouncedOnChange(e.target.value);
        }}
        onKeyPress={(e) => {
          // We would like to have an auto-grow textarea, but without extra line breaks.
          if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
      />

      {error && <FieldValidationMessage>{error}</FieldValidationMessage>}

      {/* Show hints if it is possibly a syntax error */}
      {error && selector.trim() !== '' && (
        <div className={cx(s.colorWeak, s.textSm, s.marginTopXs)}>
          Example syntax: foo=bar
          <br />
          Available operators: =, !=, =~, !~
        </div>
      )}
    </>
  );
};
