import React, { FunctionComponent, useState } from 'react';

import { useGetAll } from '@common/state/src/tenants';
import { AccessPolicy, Token } from '@common/types';
import {
  getTenants,
  isNotAReservedTenant,
  isWildcardAccessPolicy,
  removeTrailingSlashes,
  usePluginMeta,
  useUtilityStyles,
} from '@common/utils';

import { CodeSnippet } from '../CodeSnippet';
import { SelectTenant } from '../SelectTenant';

type Props = {
  /** The access policy that we are showing the snippet for. */
  accessPolicy: AccessPolicy;
  /** Additional className for styling. */
  className?: string;
  /** The token that is used for the authentication. */
  token: Token;
};

export const RemoteWriteConfigSnippet: FunctionComponent<Props> = ({ accessPolicy, className, token }) => {
  const s = useUtilityStyles();
  const allTenantNames = useGetAll()
    .activeResources.filter(isNotAReservedTenant)
    .map((tenant) => tenant.name);
  const tenantNames = isWildcardAccessPolicy(accessPolicy) ? allTenantNames : getTenants(accessPolicy);
  const [tenant, setTenant] = useState<string | unknown>(tenantNames[0]);
  const { pluginMeta } = usePluginMeta();
  const codeSnippet = `remote_write:
- url: ${removeTrailingSlashes(pluginMeta?.jsonData?.backendUrl || '')}/api/v1/push
  basic_auth:
    username: ${tenant}
    password: ${token.token}
`;

  return (
    <>
      <SelectTenant
        width={20}
        value={tenant}
        closeMenuOnSelect
        tenantNames={tenantNames}
        className={s.marginBottomSm}
        placeholder="Select tenant"
        onChange={({ value }) => setTenant(value)}
      />
      <CodeSnippet className={className} code={codeSnippet} />
    </>
  );
};
