import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';

import { AdminResourceList, License } from '@common/types';
import { ADMIN_RESOURCES, apiGet, BackendContext } from '@common/utils';

export const useGetAll = () => {
  const { adminApiPrefix } = useContext(BackendContext);

  return useQuery(
    [ADMIN_RESOURCES.licenses, adminApiPrefix],
    async () => {
      if (!adminApiPrefix) {
        return null;
      }
      const url = `${adminApiPrefix}/${ADMIN_RESOURCES.licenses}`;
      const res = await apiGet<AdminResourceList<License>>(url);
      return res?.data;
    },
    {
      retry: false,
    }
  );
};
