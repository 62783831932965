import React from 'react';
import { cx } from '@emotion/css';

import { CortexConfigSection } from '@common/types';
import { useUtilityStyles } from '@common/utils';

import { TextHighlighter } from '../TextHighlighter';

type Props = {
  keyword?: string;
  section: CortexConfigSection;
};

export const ConfigSection = ({ keyword = '', section }: Props) => {
  const s = useUtilityStyles();

  return (
    <div className={cx(s.bgLevel2, s.marginBottomMd)} data-testid="config-section">
      <div className={s.paddingMd}>
        <pre className={s.marginNone}>
          <TextHighlighter text={section.config} keyword={keyword} />
        </pre>
      </div>
    </div>
  );
};
