import React from 'react';
import { cx } from '@emotion/css';

import { useStyles2 } from '@grafana/ui';

import { getStyles } from './TenantStatistic.styles';

export enum TenantStatType {
  Healthy = 'healthy',
  Normal = 'normal',
  Unhealthy = 'unhealthy',
  Warning = 'warning',
}

type Props = {
  className?: string;
  label: string;
  stat: string;
  type?: TenantStatType;
};

export const TenantStatistic: React.FC<Props> = ({ className, label, stat, type = TenantStatType.Normal }) => {
  const styles = useStyles2(getStyles(className));
  const typeSpecificStyle: Record<TenantStatType, string> = {
    [TenantStatType.Healthy]: styles.colorGreen200,
    [TenantStatType.Unhealthy]: styles.colorRed,
    [TenantStatType.Warning]: styles.colorYellow,
    [TenantStatType.Normal]: styles.colorBlue200,
  };

  return (
    <div className={styles.container}>
      <div className={styles.label}>{label}</div>
      <div className={cx(styles.stat, typeSpecificStyle[type])}>{stat}</div>
    </div>
  );
};
