import { useSelector } from 'react-redux';

import {
  selectIsError,
  selectIsLoading,
  selectIsNotFetched,
  selectIsSuccess,
  selectRequestInfo,
} from './requests.selectors';

export const useIsLoading = (actionType: string) => useSelector(selectIsLoading(actionType));

export const useIsSuccess = (actionType: string) => useSelector(selectIsSuccess(actionType));

export const useIsError = (actionType: string) => useSelector(selectIsError(actionType));

export const useIsNotFetched = (actionType: string) => useSelector(selectIsNotFetched(actionType));

export const useGetRequestInfo = (actionType: string) => useSelector(selectRequestInfo(actionType));
