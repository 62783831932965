import React from 'react';
import { css, cx } from '@emotion/css';

import { colors, matchAll, split, useUtilityStyles } from '@common/utils';

type Props = {
  highlightClassname?: string;
  keyword: string;
  text: string;
};

export const TextHighlighter = ({ highlightClassname, keyword, text }: Props) => {
  const s = useUtilityStyles();
  const parts = split(text, keyword);
  const matches = matchAll(text, keyword);

  const className =
    highlightClassname ||
    cx(
      s.colorGray800,
      // We need this unfortunately as the <mark> element has an override using "background: " in the dark theme.
      css`
        background: ${colors.yellow400};
      `
    );

  return (
    <>
      {parts.map((part, index) => (
        <React.Fragment key={index}>
          {/*
            String.prototype.split() will insert an empty string to the beginning of the array if there is a match at the beginning of the text,
            which means that the first match will never be the first item. Also, if there are no matches then the .split() is going return
            an array containing a single element (the whole text).
          */}
          {index > 0 && matches && <mark className={className}>{matches[index - 1]}</mark>}
          {part}
        </React.Fragment>
      ))}
    </>
  );
};
