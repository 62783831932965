import React, { useCallback, useContext } from 'react';
import { css } from '@emotion/css';
import _ from 'lodash';

import { GrafanaTheme2 } from '@grafana/data';
import { Checkbox, useStyles2 } from '@grafana/ui';

import { getScopeByValue, Scope } from '@common/types';
import { BackendContext, getScopeDescription } from '@common/utils';

const getStyles = (theme: GrafanaTheme2) => {
  return {
    table: css`
      display: flex;
      flex-direction: column;
    `,
    row: css`
      background-color: ${theme.colors.background.secondary};
      display: grid;
      grid-template-columns: ${theme.spacing(25)} auto;
      grid-gap: ${theme.spacing(1)};
      overflow-wrap: anywhere;
      padding: ${theme.spacing(1)} ${theme.spacing(2)};

      &:nth-child(even) {
        background-color: ${theme.colors.background.primary};
      }
    `,
    descriptionText: css`
      color: ${theme.colors.text.secondary};
    `,
  };
};
export type ScopeEditorScope = {
  scope: Scope;
  /** (Optional) Determines whether the checkbox should be disabled defaults to falsey */
  required?: boolean;
};

type Props = {
  /** (Optional) A list of scopes that will be marked as checked. */
  checkedScopes: ScopeEditorScope[];
  /** A callback that is going to be called every time the scopes are changed. */
  onChange: (data: ScopeEditorScope[]) => void;
};

export const ScopesEditor = ({ checkedScopes = [], onChange }: Props) => {
  const styles = useStyles2(getStyles);
  const {
    backend: {
      accessPolicyScopeFeatures: { availableScopes },
    },
  } = useContext(BackendContext);

  const innerOnChange = useCallback(
    (e: React.MouseEvent<HTMLInputElement>) => {
      const scope: Scope = getScopeByValue(e.currentTarget.name);
      const isChecked = e.currentTarget.checked;

      const updatedScopes = isChecked
        ? _.uniqBy([...checkedScopes, { scope }], 'scope')
        : _.filter(checkedScopes, (checkedScope) => checkedScope.scope !== scope);

      onChange(updatedScopes);
    },
    [checkedScopes, onChange]
  );

  return (
    <div className={styles.table} data-testid={'scopes-editor'}>
      {availableScopes.map((scope) => {
        const checkedScope = _.find(checkedScopes, _.matches({ scope: getScopeByValue(scope) }));
        return (
          <div key={scope} className={styles.row}>
            <Checkbox
              name={scope}
              label={scope}
              data-testid={`access-policy-form-${scope}`}
              checked={!!checkedScope}
              disabled={checkedScope?.required}
              onChange={innerOnChange}
            />

            <div className={styles.descriptionText}>{getScopeDescription(scope)}</div>
          </div>
        );
      })}
    </div>
  );
};
