import { AccessPolicy } from '@common/types';

import { createAdminResourceReducer } from '../adminResources/adminResources.reducer';

import { accessPolicyActions } from './accessPolicies.actions';

export const { entityAdapter, reducer } = createAdminResourceReducer<AccessPolicy>(
  'accesspolicies',
  accessPolicyActions,
  []
);

export const accessPoliciesAdapter = entityAdapter;
