import React from 'react';

import { HorizontalGroup, Icon, useStyles2 } from '@grafana/ui';

import { Tenant } from '@common/types';
import { isWildcardTenant } from '@common/utils';

import { getStyles } from './TenantSummary.styles';
import t from './text';

export const TenantSummary = ({ tenants }: { tenants: Tenant[] }) => {
  const countByCluster: { [cluster: string]: number } = {};

  for (let tenant of tenants) {
    if (tenant.cluster) {
      if (isWildcardTenant(tenant)) {
        countByCluster[tenant.cluster] = Number.POSITIVE_INFINITY; // "All"
      } else {
        const count = countByCluster[tenant.cluster] || 0;
        countByCluster[tenant.cluster] = count + 1;
      }
    }
  }

  const clusterNames = Object.keys(countByCluster);
  clusterNames.sort();

  return (
    <HorizontalGroup spacing="sm">
      {clusterNames.length === 0 && <TenantSummaryItem />}
      {clusterNames.map((clusterName) => (
        <TenantSummaryItem key={clusterName} clusterName={clusterName} count={countByCluster[clusterName]} />
      ))}
    </HorizontalGroup>
  );
};

export const TenantSummaryItem = ({ clusterName, count }: { clusterName?: string; count?: number }) => {
  const styles = useStyles2(getStyles);
  const prefix = clusterName ? `${clusterName}:  ` : '';

  return (
    <span className={styles.container}>
      {clusterName && <Icon name="cloud" />}
      {prefix}
      <strong>{t.tenantsLabel(count)}</strong>
    </span>
  );
};
