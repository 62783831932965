import React, { useMemo, useState } from 'react';

import { Icon, Input } from '@grafana/ui';

import { CortexConfig } from '@common/types';
import { getSections, matchAll, partition, useUtilityStyles } from '@common/utils';

import { ConfigSection } from '../ConfigSection';
import { ConfigSectionCollapsable } from '../ConfigSectionCollapsable';

type Props = {
  config: CortexConfig;
};

export const Config: React.FC<Props> = ({ config }) => {
  const s = useUtilityStyles();
  const [keyword, setKeyword] = useState('');
  const clearKeyword = () => setKeyword('');
  const [sections, [basicSection]] = partition(getSections(config), (section) => section.name !== 'basic');

  // In place sort, Alphabetise non-basic section
  sections.sort((a, b) => a.name.localeCompare(b.name));

  const filteredSections = useMemo(
    () =>
      keyword
        ? sections.filter((section) => matchAll(section.name, keyword) || matchAll(section.config, keyword))
        : sections,
    [sections, keyword]
  );

  return (
    <>
      <div className={s.marginBottomMd}>
        {/* Search */}
        <Input
          value={keyword}
          placeholder="Search..."
          type="text"
          width={30}
          onChange={(e) => setKeyword(e.currentTarget?.value)}
          suffix={keyword ? <Icon className={s.pointer} name="times" onClick={clearKeyword} /> : null}
        />
      </div>
      <div>
        {/* Basic section (containing the primitive config values) */}
        <ConfigSection section={basicSection} keyword={keyword} />

        {/* More detailed config sections */}
        {filteredSections.map((section) => (
          <ConfigSectionCollapsable key={section.name} section={section} keyword={keyword} />
        ))}
      </div>
    </>
  );
};
