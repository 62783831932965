import { AsyncThunk, createAction, createAsyncThunk } from '@reduxjs/toolkit';

import {
  ADMIN_RESOURCES_PLURAL,
  ADMIN_RESOURCES_SINGULAR,
  AdminApiPrefix,
  AdminResource,
  AdminResourceApiName,
} from '@common/types';
import { createRequestError, RequestOptions } from '@common/utils';

import { RootState } from '../store';

export type AdminObjectApi<T extends AdminResource> = {
  create: (prefix: AdminApiPrefix, resource: T) => Promise<T>;
  deactivate: (prefix: AdminApiPrefix, resource: T) => Promise<T>;
  getAll: (prefix: AdminApiPrefix, options?: RequestOptions) => Promise<T[]>;
  getByName: (prefix: AdminApiPrefix, name: string, options?: RequestOptions) => Promise<T>;
  update: (prefix: AdminApiPrefix, resource: T) => Promise<T>;
};

const getAdminResourceAsyncThunkActionNames = (apiName: AdminResourceApiName) => {
  return {
    create: `${apiName}/create`,
    deactivate: `${apiName}/deactivate`,
    fetchAll: `${apiName}/fetchAll`,
    fetchById: `${apiName}/fetchById`,
    update: `${apiName}/update`,
  };
};

export type AdminResourceActions<T extends AdminResource> = {
  create: AsyncThunk<T, T, {}>;
  deactivate: AsyncThunk<T, T, {}>;
  fetchAll: AsyncThunk<T[], void, {}>;
  fetchById: AsyncThunk<T, string, {}>;
  update: AsyncThunk<T, T, {}>;
};

export const clearSecrets = createAction('tokens/clearSecrets');

export const createAdminResourceAsyncThunkActions = <T extends AdminResource>(
  apiName: AdminResourceApiName,
  api: AdminObjectApi<T>
) => {
  const pluralized = ADMIN_RESOURCES_PLURAL[apiName];
  const singular = ADMIN_RESOURCES_SINGULAR[apiName];

  const actionNames = getAdminResourceAsyncThunkActionNames(apiName);

  const { create, deactivate, getAll, getByName, update } = api;

  const actions: AdminResourceActions<T> = {
    fetchAll: createAsyncThunk<T[], void, { state: RootState }>(actionNames.fetchAll, async (_, thunkApi) => {
      try {
        const { adminApiPrefix } = thunkApi.getState().apiPrefixes;
        return await getAll(adminApiPrefix);
      } catch (e: any) {
        return thunkApi.rejectWithValue(createRequestError(`Unable to fetch ${pluralized}`, e));
      }
    }),
    fetchById: createAsyncThunk<T, string, { state: RootState }>(
      actionNames.fetchById,
      async (id: string, thunkApi) => {
        try {
          const { adminApiPrefix } = thunkApi.getState().apiPrefixes;
          return await getByName(adminApiPrefix, id);
        } catch (e: any) {
          return thunkApi.rejectWithValue(createRequestError(`Unable to find ${singular} with name "${id}"`, e));
        }
      }
    ),
    create: createAsyncThunk<T, T, { state: RootState }>(actionNames.create, async (resource: T, thunkApi) => {
      try {
        const { adminApiPrefix } = thunkApi.getState().apiPrefixes;
        return await create(adminApiPrefix, resource);
      } catch (e: any) {
        return thunkApi.rejectWithValue(createRequestError(`Unable to create new ${singular}`, e));
      }
    }),
    deactivate: createAsyncThunk<T, T, { state: RootState }>(actionNames.deactivate, async (resource: T, thunkApi) => {
      try {
        const { adminApiPrefix } = thunkApi.getState().apiPrefixes;
        return await deactivate(adminApiPrefix, resource);
      } catch (e: any) {
        return thunkApi.rejectWithValue(
          createRequestError(`Unable to remove ${singular} with name "${resource.name}"`, e)
        );
      }
    }),
    update: createAsyncThunk<T, T, { state: RootState }>(actionNames.update, async (resource: T, thunkApi) => {
      try {
        const { adminApiPrefix } = thunkApi.getState().apiPrefixes;
        return await update(adminApiPrefix, resource);
      } catch (e: any) {
        return thunkApi.rejectWithValue(createRequestError(`Unable to update ${singular} "${resource.name}"`, e));
      }
    }),
  };

  return actions;
};
