import { createAsyncThunk } from '@reduxjs/toolkit';

import { getClusters } from '@common/api';
import { createRequestError } from '@common/utils';

export const fetchAll = createAsyncThunk('clusters/fetchAll', async (_, thunkApi) => {
  try {
    const { adminApiPrefix } = (thunkApi.getState() as any).apiPrefixes;
    return (await getClusters(adminApiPrefix))?.items;
  } catch (e: any) {
    return thunkApi.rejectWithValue(createRequestError('Unable to fetch clusters', e));
  }
});
