import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useQueries, useQuery, useQueryClient } from '@tanstack/react-query';

import { rangeUtil } from '@grafana/data';

import { forgetRingMember, getRingStatus } from '@common/api';
import { BackendType, PluginApiPrefix, RingMetadata, RingStatus, RingType } from '@common/types';
import { BackendContext, getMetadataFromRingMembers, usePluginMeta } from '@common/utils';

function getRingMemberQuery(prefix: PluginApiPrefix, backendType: BackendType, ringType: RingType) {
  return {
    queryKey: [prefix, 'ring-members', ringType, backendType],
    queryFn: () => getRingStatus(prefix, backendType, ringType, false),
    retry: true,
  };
}

function useCommonContext() {
  const { pluginResourceUrlPrefix: prefix } = usePluginMeta();

  const {
    backend: { name: backendType },
  } = useContext(BackendContext);

  return { prefix, backendType };
}

export function useRingMembers(ringType: RingType) {
  const { prefix, backendType } = useCommonContext();
  const query = getRingMemberQuery(prefix, backendType, ringType);

  const queryResult = useQuery(query);
  return queryResult;
}

export function useRingRefreshInterval() {
  const { prefix, backendType } = useCommonContext();
  const ringTypes = useRingTypes();
  const [refreshInterval, setRefreshInterval] = useState(rangeUtil.intervalToMs('1m'));
  const client = useQueryClient();

  const queries = useMemo(() => {
    const queries = ringTypes.map((ringType) => getRingMemberQuery(prefix, backendType, ringType));
    return queries;
  }, [ringTypes, prefix, backendType]);

  useEffect(() => {
    for (const query of queries) {
      client.setQueryDefaults(query.queryKey, { refetchInterval: refreshInterval });
    }
  }, [client, queries, refreshInterval]);

  const refresh = useCallback(() => {
    queries.forEach((query) => client.refetchQueries(query.queryKey));
  }, [queries, client]);

  return { refreshInterval, setRefreshInterval, refresh };
}

export function useLoadingRingTypes() {
  const { prefix, backendType } = useCommonContext();
  const ringTypes = useRingTypes();
  const queries = ringTypes.map((ringType) => getRingMemberQuery(prefix, backendType, ringType));
  const result = useQueries({ queries });
  const loadingRingTypes: Set<RingType> = new Set();

  for (let i = 0; i < ringTypes.length; ++i) {
    const ringType = ringTypes[i];
    const { isLoading, isFetching } = result[i];
    if (isLoading || isFetching) {
      loadingRingTypes.add(ringType);
    }
  }

  return loadingRingTypes;
}

export function useRingMetadata() {
  const { prefix, backendType } = useCommonContext();
  const ringTypes = useRingTypes();
  const queries = ringTypes.map((ringType) => getRingMemberQuery(prefix, backendType, ringType));

  const result = useQueries({ queries });

  const metadata: Map<RingType, RingMetadata> = new Map();

  for (let i = 0; i < ringTypes.length; ++i) {
    const ringType = ringTypes[i];
    const { data, isError } = result[i];
    metadata.set(ringType, calculateRingMetaData(data, isError));
  }

  return metadata;
}

function calculateRingMetaData(data: Awaited<ReturnType<typeof getRingStatus>> | undefined, isError: boolean) {
  if (isError || !data) {
    return {
      activeMemberIds: [],
      unhealthyMemberIds: [],
      serviceAvailable: false,
    };
  }

  return {
    serviceAvailable: true,
    shardingDisabled: data?.shardingDisabled ?? true,
    ...getMetadataFromRingMembers(data?.ringMembers || []),
  };
}

export function useForgetMember(ringType: RingType) {
  const { backendType, prefix } = useCommonContext();

  const query = getRingMemberQuery(prefix, backendType, ringType);

  const queryClient = useQueryClient();

  return async function (id: string) {
    await queryClient.cancelQueries(query);

    // Indicate that we are forgetting this id.
    queryClient.setQueryData<RingStatus>(query.queryKey, (oldData) => {
      if (!oldData) {
        return undefined;
      }
      const forgottenMember = oldData.ringMembers.find((member) => member.id === id);
      if (forgottenMember) {
        forgottenMember.isForgetting = true;
      }
      return oldData;
    });

    await forgetRingMember(prefix, backendType, ringType, id);

    // Ignore the response from `forgetRingMember` and refetch
    await queryClient.refetchQueries(query);
  };
}

export function useRingTypes() {
  const {
    backend: {
      implicitFeatures: { ringTypes },
    },
  } = useContext(BackendContext);

  return ringTypes;
}
