import { map } from 'ramda';

import { config } from '@grafana/runtime';

import { GeneratedDatasource } from '@common/types';

export const generateDatasourceName = (accessPolicyName: string, tenantName: string, tokenName: string) =>
  `GE / ${accessPolicyName} / ${tenantName} / ${tokenName}`;

export const getExistingDatasourcesAsGenerated = (): Record<string, GeneratedDatasource> => {
  return map(
    (datasource) =>
      ({
        id: datasource.id,
        name: datasource.name,
        type: datasource.type,
        url: datasource.url,
      } as GeneratedDatasource),
    // @ts-ignore
    config.datasources
  );
};
