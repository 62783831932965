import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';

export const getStyles = (theme: GrafanaTheme2) => ({
  container: css`
    margin-top: ${theme.spacing(2)};
  `,
  title: css`
    font-weight: ${theme.typography.fontWeightBold};
    color: ${theme.colors.text.secondary};
  `,
  description: css`
    font-size: ${theme.typography.bodySmall.fontSize};
    color: ${theme.colors.text.secondary};
  `,
  topGapSm: css`
    margin-top: ${theme.spacing(1)};
  `,
  availableLimitsContainer: css`
    margin-top: ${theme.spacing(3)};
  `,
  pointer: css`
    cursor: pointer;
  `,
});
