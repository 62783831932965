import React, { useMemo, useState } from 'react';
import { cx } from '@emotion/css';

import { Alert, Icon } from '@grafana/ui';

import { CortexConfigSection } from '@common/types';
import { countAllMatches, useUtilityStyles } from '@common/utils';

import { TextHighlighter } from '../TextHighlighter';

type Props = {
  keyword?: string;
  section: CortexConfigSection;
};

export const ConfigSectionCollapsable = ({ keyword = '', section }: Props) => {
  const s = useUtilityStyles();
  const [isOpen, setIsOpen] = useState(false);
  const toggleIsOpen = () => setIsOpen(!isOpen);
  const iconName = isOpen ? 'angle-down' : 'angle-right';
  const numberOfResults = useMemo(() => countAllMatches(section.config, keyword), [section, keyword]);

  return (
    <div className={cx(s.bgLevel2, s.marginBottomMd)} data-testid="config-section-collapsable">
      <div
        onClick={toggleIsOpen}
        onKeyDown={toggleIsOpen}
        role="button"
        tabIndex={0}
        className={cx(s.paddingVerticalSm, s.paddingHorizontalMd, s.pointer)}
      >
        <Icon name={iconName} className={s.marginRightSm} />
        {/* Name */}
        <TextHighlighter text={section.name} keyword={keyword} />

        {/* Number of results */}
        {keyword && numberOfResults > 0 && (
          <span className={cx(s.marginLeftSm, s.colorWeak)}>({numberOfResults} results)</span>
        )}
      </div>

      {/* Code */}
      {isOpen && (
        <div className={cx(s.borderTop, s.borderSolid, s.borderCurrentColor, s.colorVeryWeak, s.paddingMd)}>
          {section?.name === 'limits' && <SpecialLimitsAlert />}
          <pre className={s.marginNone}>
            <TextHighlighter text={section.config} keyword={keyword} />
          </pre>
        </div>
      )}
    </div>
  );
};

const SpecialLimitsAlert = () => {
  const s = useUtilityStyles();
  return (
    <Alert severity="warning" title="About limits">
      These limits are applied to each tenant within cluster. You can set custom limit values for specific tenants by
      using the runtime configuration. For more information, please see{' '}
      <a
        rel="noreferrer"
        target="_blank"
        href={`https://grafana.com/docs/mimir/latest/operators-guide/configuring/about-runtime-configuration/`}
        className={cx(s.colorSemiWeak, s.textUnderline)}
      >
        Runtime Configuration.
      </a>
    </Alert>
  );
};
