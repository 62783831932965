import React, { useContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { css } from '@emotion/css';

import { AppRootProps } from '@grafana/data';
import { PluginPage } from '@grafana/runtime';

import {
  ActionButtonSet,
  BackendErrorWarning,
  EnterpriseLicenseAlerts,
  GrafanaVersionWarning,
  Header,
  LicenseAlerts,
  RingManager,
} from '@common/components';
import { AppPluginSettings } from '@common/types';
import { BackendContext, usePluginMeta } from '@common/utils';

import { AccessPolicies } from '../AccessPolicies';
import { Config } from '../Config';
import { Licenses } from '../Licenses';
import { Tenants } from '../Tenants';

import { BackendRequired } from './BackendRequired';

export const PageRoutes = (props: AppRootProps<AppPluginSettings>) => {
  const {
    meta,
    meta: { name },
  } = props;

  const {
    backend: { name: backendName, version: backendVersion, isBackend },
  } = useContext(BackendContext);
  const { pluginAppUrl } = usePluginMeta();

  // React Router
  const connectionIndicator = isBackend ? `${backendName} ${backendVersion || '(version unknown)'}` : '';

  const actionButtons = <ActionButtonSet className={css({ marginTop: '-28px' })} />;

  return (
    <PluginPage
      renderTitle={(title) => <Header title={title} name={name} meta={meta} />}
      info={[...(connectionIndicator ? [{ label: 'Connected', value: connectionIndicator }] : [])]}
      actions={actionButtons}
    >
      {/* Only show license alerts if there is a backend available to fetch our licenses from */}
      {isBackend && <LicenseAlerts />}
      <GrafanaVersionWarning />
      <EnterpriseLicenseAlerts />
      <BackendErrorWarning />

      <Routes>
        <Route
          path={`/tenants`}
          element={
            <BackendRequired>
              <Tenants />
            </BackendRequired>
          }
        />
        <Route
          path={`/access-policies`}
          element={
            <BackendRequired>
              <AccessPolicies />
            </BackendRequired>
          }
        />
        <Route
          path={`/ring-health/*`}
          element={
            <BackendRequired>
              <RingManager enableRefresh />
            </BackendRequired>
          }
        />
        <Route
          path={`/licenses`}
          element={
            <BackendRequired>
              <Licenses />
            </BackendRequired>
          }
        />
        <Route
          path={`/cluster-configuration`}
          element={
            <BackendRequired>
              <Config />
            </BackendRequired>
          }
        />
        {/* Default route (only redirect if the path is at root) */}
        <Route path={`/`} element={<Navigate to={`${pluginAppUrl}/tenants`} />} />
      </Routes>
    </PluginPage>
  );
};
