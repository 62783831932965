import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { css, cx } from '@emotion/css';
import _ from 'lodash';

import { GrafanaTheme2 } from '@grafana/data';
import { Button, Field, Icon, Input, Switch, Tooltip, useStyles2 } from '@grafana/ui';

import { useCreateLoading, useUpdateLoading } from '@common/state/src/accessPolicies';
import { AccessPolicy, Scope } from '@common/types';
import { BackendContext, filterWildcardRedundancies, formatSelectorsInRealms, generateName } from '@common/utils';

import { RealmsEditor } from '../RealmsEditor';
import { ScopeEditorScope, ScopesEditor } from '../ScopesEditor';

const getStyles = (theme: GrafanaTheme2) => {
  return {
    formControls: {
      wrapper: css`
        display: flex;
        margin-top: ${theme.spacing(3)};
        gap: ${theme.spacing(1)};
      `,
    },
    dataSourceIntent: {
      wrapper: css`
        display: flex;
        gap: ${theme.spacing(1)};
        align-items: center;
      `,
    },
  };
};

type Props = {
  /** (Optional) Additional styling for the form */
  className?: string;
  /** A tenant object that can be used to pre-fill the form. Can be used for editing tenants. */
  defaultValues?: AccessPolicy;
  nameValidator?: (accessPolicyName: string) => string | undefined;
  /** A callback called when the "Cancel" button is clicked. */
  onCancel: () => void;

  /** A callback called when the form is submitted. */
  onSubmit: (data: AccessPolicy) => void;
};

export const AccessPolicyForm = ({ className, defaultValues, nameValidator, onCancel, onSubmit }: Props) => {
  const {
    backend: { accessPolicyScopeFeatures, features },
  } = useContext(BackendContext);
  const formMethods = useForm<AccessPolicy>({ defaultValues });
  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    setFocus,
    setValue,
    watch,
  } = formMethods;
  const styles = useStyles2(getStyles);
  const [datasourceIntent, setDatasourceIntent] = useState<boolean>(
    !!(defaultValues?.scopes && _.isEqual(defaultValues.scopes, accessPolicyScopeFeatures.defaultScopes))
  );
  const [prevDisplayName, setPrevDisplayName] = useState('');

  const enableLabelSelectors =
    features.lbac &&
    accessPolicyScopeFeatures.readScope &&
    getValues('scopes')?.includes(accessPolicyScopeFeatures.readScope);

  const watchDisplayName = watch('display_name', '');
  const watchName = watch('name', '');
  const watchScopes = watch('scopes');
  const isCreating = useCreateLoading();
  const isUpdating = useUpdateLoading();

  const isEditMode = Boolean(defaultValues?.name);
  const submitButtonText = isEditMode ? (isUpdating ? 'Saving...' : 'Save') : isCreating ? 'Creating...' : 'Create';
  const defaultScopesForCreatingDatasource = accessPolicyScopeFeatures.defaultScopes;
  const includesAlertingScopes = defaultScopesForCreatingDatasource.includes(Scope.AlertsRead);

  useEffect(() => {
    setValue('scopes', defaultValues?.scopes || []);
  }, []); // eslint-disable-line

  useEffect(() => {
    const displayNameHasChanged = watchDisplayName !== prevDisplayName;
    const nameWasAutoGenerated = generateName(prevDisplayName) === watchName;
    const nameCanBeAutoGenerated = (nameWasAutoGenerated || watchName === '') && !isEditMode;

    if (displayNameHasChanged && nameCanBeAutoGenerated) {
      setValue('name', generateName(watchDisplayName));
    }

    setPrevDisplayName(watchDisplayName);
  }, [watchName, watchDisplayName, prevDisplayName, setValue, isEditMode]);

  useEffect(() => {
    setFocus('display_name');
  }, [setFocus]);

  const datasourceIntentOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const isChecked = e.currentTarget.checked;
      if (isChecked) {
        setValue('scopes', defaultScopesForCreatingDatasource);
      }
      setDatasourceIntent(isChecked);
    },
    [defaultScopesForCreatingDatasource, setValue]
  );

  const mapToScopesForEditor = (scopes: Scope[]): ScopeEditorScope[] =>
    scopes.map((scope: Scope): ScopeEditorScope => {
      if (datasourceIntent && _.indexOf(defaultScopesForCreatingDatasource, scope) > -1) {
        return { scope, required: true };
      }
      return { scope, required: false };
    });

  return (
    <FormProvider {...formMethods}>
      <form
        className={cx(className)}
        data-testid={'access-policy-form'}
        onSubmit={handleSubmit((data: AccessPolicy) =>
          onSubmit({
            ...data,
            realms: formatSelectorsInRealms(filterWildcardRedundancies(data.realms)),
          })
        )}
      >
        <>
          {/* Display Name */}
          <Field
            label="Display name"
            description={'The name that you are going to see when looking through access policies.'}
            invalid={!!errors.display_name}
            error={'Display name is required'}
          >
            <>
              <Input
                {...register('display_name', { required: true })}
                name="display_name"
                placeholder=""
                data-testid="access-policy-form-display_name"
              />
            </>
          </Field>

          {/* Name */}
          <Field
            label="Name"
            description={'Used as a unique identifier for the access policy.'}
            invalid={!!errors.name}
            error={errors.name?.message || 'Name is required'}
          >
            <>
              <Input
                {...register('name', {
                  required: true,
                  validate: nameValidator,
                })}
                name="name"
                placeholder=""
                data-testid="access-policy-form-name"
                disabled={isEditMode}
              />
            </>
          </Field>

          {/* Scopes */}
          <Field
            label="Scopes"
            error="Selecting a scope is required"
            invalid={!!errors.scopes && (!watchScopes || watchScopes.length === 0)}
          >
            <>
              <div className={styles.dataSourceIntent.wrapper}>
                Are you planning on creating a data source that uses this access policy?
                <Tooltip
                  content={
                    includesAlertingScopes
                      ? `Choosing this will pre-select the required permission scopes to allow this data source to interact with Grafana Alerting.`
                      : `Choosing this will pre-select the required permission scopes.`
                  }
                >
                  <Icon name={'info-circle'} />
                </Tooltip>
                <Switch value={datasourceIntent} onChange={datasourceIntentOnChange} />
              </div>
              <ScopesEditor
                {
                  /* Omit ref to avoid the `Function components cannot be given refs` warning */
                  ..._.omit(register('scopes', { required: true }), ['ref'])
                }
                checkedScopes={mapToScopesForEditor(getValues('scopes') || [])}
                onChange={(scopeEditorScopes) => {
                  setValue(
                    'scopes',
                    scopeEditorScopes.map((ses) => ses.scope)
                  );
                }}
              />
            </>
          </Field>

          {/* Realms (our backend data structure differs from how we present it to the end user: Realms vs. Tenants) */}
          <Field label="Tenants" error="Selecting a tenant is required" invalid={!!errors.realms}>
            <RealmsEditor
              {
                /* Omit ref to avoid the `Function components cannot be given refs` warning */
                ..._.omit(register('realms', { required: true }), ['ref'])
              }
              control={control}
              enableLabelSelectors={enableLabelSelectors}
            />
          </Field>

          {/* Actions */}
          <div className={styles.formControls.wrapper}>
            <Button
              type="submit"
              disabled={isCreating || isUpdating}
              icon={isCreating || isUpdating ? 'fa fa-spinner' : undefined}
            >
              {submitButtonText}
            </Button>
            <Button onClick={onCancel} variant="secondary">
              Cancel
            </Button>
          </div>
        </>
      </form>
    </FormProvider>
  );
};
