import { createAction } from '@reduxjs/toolkit';

import { createToken, deactivateToken, getToken, getTokens, updateToken } from '@common/api';
import { Token } from '@common/types';

import { createAdminResourceAsyncThunkActions } from '../adminResources/adminResources.actions';

export const tokenActions = {
  ...createAdminResourceAsyncThunkActions<Token>('tokens', {
    getAll: getTokens,
    getByName: getToken,
    update: updateToken,
    create: createToken,
    deactivate: deactivateToken,
  }),
  clearSecrets: createAction('tokens/clearSecrets'),
};

export const { create, deactivate, fetchAll, clearSecrets } = tokenActions;
