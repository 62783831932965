import { satisfies } from 'semver';

import { config } from '@grafana/runtime';

import { SUPPORTED_VERSIONS } from './grafana.versions';

export const grafanaVersionRange = SUPPORTED_VERSIONS;

export function isGrafanaVersionSupported() {
  const [version] = config.buildInfo.version.split('-');

  return satisfies(version, SUPPORTED_VERSIONS);
}
