import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useIsError as useIsRequestError, useIsLoading as useIsRequestLoading, useIsNotFetched } from '../requests';

import { fetchAll } from './clusters.actions';
import { selectAll } from './clusters.selectors';

const REQUEST_ALL = 'clusters/fetchAll';

export const useGetAll = () => {
  // Fetch once if it hasn't been fetched yet
  useFetchAll();

  return useSelector(selectAll);
};

export const useFetchAll = () => {
  const dispatch = useDispatch();
  const isNotFetched = useIsNotFetched(REQUEST_ALL);

  useEffect(() => {
    isNotFetched && dispatch(fetchAll());
  }, []); // eslint-disable-line
};

export const useIsLoading = () => useIsRequestLoading(REQUEST_ALL);

export const useIsError = () => useIsRequestError(REQUEST_ALL);
