import { DataSourceApi } from '@grafana/data';

// This type is used to keep track of the data sources that we have created from the plugin.
// It is also used to pass to some of our intermediate Grafana api accessing functions.
// It is a flattened representation (some jsonData and secureJsonData are at root)
export type GeneratedDatasource = {
  id?: number;
  uid?: string;
  name: string;
  basicAuthPassword?: string;
  basicAuthUser?: string;
  type: DatasourceType;
  url?: string;
};

export enum DatasourceType {
  Loki = 'loki',
  Prometheus = 'prometheus',
  Tempo = 'tempo',
}

export type DataSourceApiWithURL = DataSourceApi & { url: string };

export function datasourceHasUrl(datasource: DataSourceApi): datasource is DataSourceApiWithURL {
  return datasource && typeof (datasource as any).url === 'string';
}
