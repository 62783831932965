import { AnyAction, combineReducers, configureStore } from '@reduxjs/toolkit';
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { reducer as apiPrefixReducer } from './apiPrefixes/apiPrefixes.reducer';
import { reducer as accessPoliciesReducer } from './accessPolicies';
import { reducer as clustersReducer } from './clusters';
import { reducer as generatedDatasourcesReducer } from './generatedDatasources';
import { reducer as requestsReducer } from './requests';
import { reducer as tenantsReducer } from './tenants';
import { reducer as tokensReducer } from './tokens';

const persistConfig = {
  key: 'root',
  storage,
  version: 1,
  whitelist: [],
};

const combinedReducers = combineReducers({
  accesspolicies: accessPoliciesReducer,
  clusters: clustersReducer,

  generatedDatasources: generatedDatasourcesReducer,

  requests: requestsReducer,

  tenants: tenantsReducer,
  tokens: tokensReducer,

  apiPrefixes: apiPrefixReducer,
});

type RootReducer = typeof combinedReducers;

export const changeBackendAction: AnyAction = { key: 'CHANGE_BACKEND', type: 'persist/REHYDRATE' };

const rootReducer: RootReducer = (state, action) => {
  if (action.key === changeBackendAction.key) {
    // Reset the whole state.
    state = undefined;
  }

  return combinedReducers(state, action);
};

export const persistedReducer = persistReducer(persistConfig, rootReducer);

type GetStoreArgs = {
  initialState?: any;
};

export const getStore = ({ initialState }: GetStoreArgs = {}) =>
  configureStore({
    // Due to using Redux-Toolkit we currently need to ignore the following actions.
    // More info: https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),

    preloadedState: initialState,
    reducer: persistedReducer,
  });

export const store = getStore();

//  Used in the <PersistGate /> component to know whether the store has been rehydrated.
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
