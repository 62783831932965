import React from 'react';
import { cx } from '@emotion/css';

import { Button, Modal } from '@grafana/ui';

import { RingMember } from '@common/types';
import { splitTokensIntoColumns, useUtilityStyles } from '@common/utils';

import { CopyToClipboard } from '../CopyToClipboard';

type Props = {
  isOpen: boolean;
  onDismiss: () => void;
  ringMember?: RingMember;
};

export const RingTokensModal = ({ isOpen, onDismiss, ringMember }: Props) => {
  const s = useUtilityStyles();

  if (!ringMember) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={onDismiss}
      onClickBackdrop={onDismiss}
      title={<div className={cx(s.textLg, s.textNormal, s.paddingMd)}>{ringMember.id} tokens</div>}
    >
      {/* Tokens */}
      <div style={{ maxHeight: '250px' }} className={cx(s.flex, s.textMonospace, s.paddingMd, s.overflowScroll)}>
        {splitTokensIntoColumns(ringMember.tokens).map((column, columnIndex) => (
          // Tokens Column
          <div className={s.flex1} key={columnIndex}>
            {column.map(([index, token]) => (
              <div key={token} className={s.flex}>
                {/* Token Index */}
                <div className={s.colorWeak}>{(index + 1).toString().padStart(3, '0')}</div>
                {/* Token */}
                <div className={cx(s.colorSemiWeak, s.marginLeft)}>{token}</div>
              </div>
            ))}
          </div>
        ))}
      </div>

      {/* Actions */}
      <div className={s.marginTopLg}>
        <CopyToClipboard label="Copy All" btnVariant="primary" textToCopy={ringMember.tokens.join('\n')} />
        <Button onClick={onDismiss} className={s.marginLeft} variant="secondary">
          Cancel
        </Button>
      </div>
    </Modal>
  );
};
