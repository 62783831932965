// The @grafana/ui/Collapse component only has a controlled way of working, and is it is a very dumb component we created our own.
import React, { useState } from 'react';
import { cx } from '@emotion/css';

import { Icon } from '@grafana/ui';

import { isDefined, useUtilityStyles } from '@common/utils';

type Props = {
  title: React.ReactNode;
  /** (Optional) Additional className for styling. */
  children: React.ReactNode;
  /** (Optional) Sets if the content should be visible by default or not. */
  className?: string;
  /** (Optional) Can be used to control the state of the collapsable from outside. */
  defaultIsOpen?: boolean;
  /** (Optional) Is called every time the collapsable is toggled. Can be used for using it as a controlled component. */
  isOpen?: boolean;
  /** (Optional) Some explanation under the title. */
  onToggle?: (isOpen: boolean) => void;
  /** The title of the collapsable block displayed next to the indicator arrow. */
  subTitle?: React.ReactNode;
};

export const Collapse: React.FC<Props> = ({
  title,
  children,
  className,
  defaultIsOpen = true,
  isOpen: controlledIsOpen,
  onToggle,
  subTitle,
}) => {
  const s = useUtilityStyles();
  const [innerIsOpen, setInnerIsOpen] = useState(defaultIsOpen);
  const isOpen = isDefined(controlledIsOpen) ? controlledIsOpen : innerIsOpen;
  const iconName = isOpen ? 'angle-down' : 'angle-right';
  const visibilityClassName = isOpen ? s.block : s.displayNone;
  const toggleIsOpen = (e: React.KeyboardEvent | React.MouseEvent) => {
    e.stopPropagation();

    setInnerIsOpen(!innerIsOpen);

    if (onToggle) {
      onToggle(!innerIsOpen);
    }
  };

  return (
    <div className={className}>
      {/* Label */}
      <div className={cx(s.noSelect)}>
        <div className={s.flex}>
          <Icon name={iconName} className={cx(s.marginRightXs, s.pointer)} onClick={toggleIsOpen} />
          <span className={s.pointer} tabIndex={0} role="button" onKeyDown={toggleIsOpen} onClick={toggleIsOpen}>
            {title}
          </span>
        </div>
        {subTitle && <div className={cx(s.colorWeak, s.textSm)}>{subTitle}</div>}
      </div>

      {/* Content */}
      <div className={cx(visibilityClassName)}>{children}</div>
    </div>
  );
};
