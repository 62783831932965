import React from 'react';
import { cx } from '@emotion/css';

import { Tooltip } from '@grafana/ui';

import { RingMemberState } from '@common/types';
import { capitalize, useUtilityStyles } from '@common/utils';

type Props = {
  state?: RingMemberState;
};

export const RingMemberStateDot = ({ state = 'PENDING' }: Props) => {
  const s = useUtilityStyles();
  const backgrounds = {
    ACTIVE: s.gradientGreen,
    JOINING: s.gradientBlue,
    LEAVING: s.gradientYellow,
    PENDING: s.gradientBlue,
    UNHEALTHY: s.gradientRed,
  };
  // TODO: fix this type error
  // @ts-ignore
  const background = backgrounds[state];

  return (
    <Tooltip content={capitalize(state)} placement="bottom">
      <div style={{ height: '16px', width: '16px' }} className={cx(background, s.roundedFull, s.inlineBlock)}></div>
    </Tooltip>
  );
};
