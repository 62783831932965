import { useEffect, useState } from 'react';

import { AppPluginMeta, GrafanaPlugin, KeyValue } from '@grafana/data';

import { getPlugin, updatePlugin } from '@common/api';
import { changeBackendAction, queryCache, store } from '@common/state';

let cachedPluginJsonData: any = undefined;

// Put this at the root of a plugin page
export const usePluginCache = <T extends KeyValue<any>>(plugin?: GrafanaPlugin<AppPluginMeta<T>>) => {
  const [meta, setMeta] = useState(plugin?.meta);

  useEffect(() => {
    if (meta?.jsonData !== cachedPluginJsonData) {
      cachedPluginJsonData = meta?.jsonData;
    }
  }, [meta?.jsonData]);

  const setPluginJsonData = async (jsonData?: T, secureJsonData?: KeyValue<any>) => {
    const { pinned, enabled } = meta || {};

    const newMeta = {
      pinned,
      enabled,
      jsonData: { ...meta?.jsonData, ...jsonData },
      ...(secureJsonData && Object.keys(secureJsonData).length ? { secureJsonData } : {}),
    };
    if (plugin?.meta.id) {
      await updatePlugin(plugin.meta.id, newMeta);
      queryCache.find(['features'])?.reset();

      const reloadedMeta = await getPlugin<T>(plugin.meta.id);
      // The rest of Grafana outside of our React tree will rely on `plugin.meta` this for the new values
      plugin.meta.jsonData = reloadedMeta.jsonData;
      // Our React tree will make use of this new state.

      setMeta(reloadedMeta);
      store.dispatch(changeBackendAction);
      queryCache.findAll().forEach((query) => query.invalidate());
    }
  };

  return { setPluginJsonData, pluginMeta: meta };
};
