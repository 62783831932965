/**
 * IMPORTANT!
 *
 * We only use this <Table /> component until we have a way to customise renderers for different columns
 * in the general <Table /> component under @grafana/ui.
 */
import React from 'react';
import { useTable } from 'react-table';
import { cx } from '@emotion/css';

import { TooltipPlacement } from '@common/types';
import { useUtilityStyles } from '@common/utils';

import { DetailedTooltip } from '../Utils';

import { TableData } from './Table.types';
import { getColumns, getData } from './Table.utils';

type Props = {
  className?: string;
  data: TableData;
  tdClassName?: string;
  thClassName?: string;
  placement?: TooltipPlacement;
};

export const Table = ({ className, data, tdClassName, thClassName, placement }: Props) => {
  const s = useUtilityStyles();
  const tableData = React.useMemo(() => getData(data), [data]);
  const tableColumns = React.useMemo(() => getColumns(data), [data]);
  const { getTableBodyProps, getTableProps, headerGroups, prepareRow, rows } = useTable({
    columns: tableColumns,
    data: tableData,
  });
  const backgrounds = {
    rowEven: s.bgLevel2,
    rowOdd: s.bgLevel1,
  };

  return (
    <table {...getTableProps()} className={className}>
      {/* Header */}
      <thead>
        {headerGroups.map((headerGroup) => (
          // .getHeaderGroupProps() will return with a key as well, so the <tr> will actually have a key.
          // eslint-disable-next-line react/jsx-key
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              // .getHeaderProps() will return with a key as well, so the <th> will actually have a key.
              // eslint-disable-next-line react/jsx-key
              <th {...column.getHeaderProps()} className={thClassName}>
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>

      {/* Body */}
      <tbody {...getTableBodyProps()}>
        {rows.map((row, rowIndex) => {
          prepareRow(row);
          const isEven = rowIndex % 2 === 0;
          const rowClassName = isEven ? backgrounds.rowEven : backgrounds.rowOdd;
          return (
            // .getRowProps() will return with a key as well, so the <tr> here will actually have a key.
            // eslint-disable-next-line react/jsx-key
            <tr {...row.getRowProps()}>
              {row.cells.map((cell, index) => {
                return (
                  // .getCellProps() will return with a key as well, so the <td> here will actually have a key.
                  // eslint-disable-next-line react/jsx-key
                  <td {...cell.getCellProps()} className={cx(tdClassName, rowClassName)}>
                    {index === 0 ? (
                      <DetailedTooltip label={cell.render('Cell')} placement={placement} />
                    ) : (
                      cell.render('Cell')
                    )}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
