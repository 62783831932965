import { useContext } from 'react';
import { useDispatch } from 'react-redux';

import { BackendType, DatasourceType, GeneratedDatasource } from '@common/types';
import { BackendContext, usePluginMeta } from '@common/utils';

import { create } from './generatedDatasources.actions';

const INTERNAL_DATASOURCE_PATH_BY_BACKEND_TYPE: Record<BackendType, string> = {
  GEL: '',
  GEM: '/prometheus',
  GET: '',
};

const DATASOURCE_TYPE_BY_BACKEND_TYPE: Record<BackendType, DatasourceType> = {
  GEL: DatasourceType.Loki,
  GEM: DatasourceType.Prometheus,
  GET: DatasourceType.Tempo,
};

// Returns with a function that can be used to create a new data source for the current backend context.
// Using this makes it easier on the call-site to create data sources with only thinking about their name and basic-auth information.
export const useCreateDatasource = () => {
  //Ensure all use-hooks are called before the conditional branch
  const dispatch = useDispatch();
  const meta = usePluginMeta();
  const {
    backend: { name: backendType },
    config,
  } = useContext(BackendContext);
  if (!config || !backendType) {
    // Cannot create data source until config is available
    // and the current backendType is defined.
    return null;
  }
  const httpApiPrefix = config?.http_api_prefix || '';
  const baseUrl = meta?.pluginMeta?.jsonData?.backendUrl;
  const url = `${baseUrl}${INTERNAL_DATASOURCE_PATH_BY_BACKEND_TYPE[backendType]}${httpApiPrefix}`;
  const type = DATASOURCE_TYPE_BY_BACKEND_TYPE[backendType];

  return ({ name, basicAuthPassword, basicAuthUser }: GeneratedDatasource) =>
    dispatch(
      create({
        name,
        basicAuthPassword,
        basicAuthUser,
        type,
        url,
      })
    );
};
