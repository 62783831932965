import { RingType } from '@common/types';
import { pluralize } from '@common/utils';

/*
 * This module provides all Ring-related strings.
 */

export default {
  clusters: {
    allAreHealthy: 'No problems were detected.',
    oneOrMoreHasHealthIssues: 'One or more clusters has health issues.',
    oneOrMoreHasNoActiveRings: 'Ring unavailability detected.',
    oneOrMoreHasRingsPending: 'There are rings pending.',
  },

  ringOption: {
    disabled: (ringType: RingType) => `The ${getRingLabel(ringType)} service is unavailable.`,
    healthy: (ringType: RingType) => `The ${getRingLabel(ringType)} ring is healthy.`,
    pending: (ringType: RingType) => `The ${getRingLabel(ringType)} ring is starting up.`,
    shardingDisabled: (ringType: RingType) => `Sharding is disabled for the ${getRingLabel(ringType)} service.`,
    unhealthy: (ringType: RingType, count: number) =>
      `The ${getRingLabel(ringType)} ring has ${count} unhealthy ${pluralize('member', count)}.`,
  },

  // These strings show up on a ring table when there is nothing to show.
  ringSelection: {
    inactive: (ringType: RingType) =>
      `The ${getRingLabel(ringType)} ring status is not accessible. If this is unexpected, check its configuration.`,
    none: 'No ring selected.',
    pending: (ringType: RingType) =>
      `The ${getRingLabel(ringType)} ring is reporting that there are no active ring members. ` +
      `If the period of inactivity is longer than expected, ` +
      `check its configuration. Otherwise, there might be a ` +
      `connection issue between ring members.`,
    shardingDisabled: (ringType: RingType) => `Sharding is disabled for the ${getRingLabel(ringType)} service.`,
  },

  ringTable: {
    empty: 'Ring is empty.',
    forget: 'Forget',
    viewTokens: 'View tokens',
  },
} as const;

const RING_LABELS = {
  [RingType.Alertmanager]: 'Alertmanager',
  [RingType.Ingester]: 'Ingester',
  [RingType.Compactor]: 'Compactor',
  [RingType.StoreGateway]: 'Store-gateway',
  [RingType.Ruler]: 'Ruler',
  [RingType.Distributor]: 'Distributor',
  [RingType.QueryScheduler]: 'Query scheduler',
  [RingType.MetricsGenerator]: 'Metrics-generator',
} as const;

// If not defined in `RING_LABELS`, pass service name back.
export const getRingLabel = (ringType: RingType) => RING_LABELS[ringType] || ringType;
