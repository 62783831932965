import React, { useContext } from 'react';

import { HorizontalGroup, LinkButton } from '@grafana/ui';

import { BackendContext, removeLeadingSlashes, usePluginMeta } from '@common/utils';

type Props = {
  className?: string;
};

export const ActionButtonSet: React.FC<Props> = ({ className = '' }) => {
  const { pluginConfigUrl, pluginResourceUrlPrefix } = usePluginMeta();
  const {
    backend: { features },
  } = useContext(BackendContext);

  const enabledDebug = !!features.debug_export;

  const debugExportUrl = removeLeadingSlashes(`${pluginResourceUrlPrefix}/node/api/v1/debug-export`);

  return (
    <div className={className}>
      <HorizontalGroup>
        {enabledDebug && (
          <LinkButton
            href={debugExportUrl}
            target="_blank"
            variant="secondary"
            size="sm"
            icon="bug"
            tooltip="Download debug information as a zip file"
          />
        )}
        <LinkButton
          variant="secondary"
          size="sm"
          icon="cog"
          href={pluginConfigUrl}
          tooltip="Configure the plugin's connection settings"
        >
          Connection
        </LinkButton>
      </HorizontalGroup>
    </div>
  );
};
