import React, { ChangeEvent, useCallback, useContext, useEffect, useState } from 'react';

import { Button, Field, FieldValidationMessage, HorizontalGroup, Input, Legend } from '@grafana/ui';

import * as api from '@common/api';
import { SecretInput } from '@common/components';
import {
  BackendContext,
  capitalize,
  getLicenseTokenError,
  prepareTokenForAdminApiUpdate,
  removeTrailingSlashes,
  usePluginMeta,
  useUtilityStyles,
} from '@common/utils';

export const ConnectionSettings = () => {
  const { pluginMeta, setPluginJsonData, pluginResourceUrlPrefix } = usePluginMeta();
  const { jsonData, id, name } = pluginMeta || {};
  const { backend } = useContext(BackendContext);
  const s = useUtilityStyles();

  const [backendUrl, setBackendUrl] = useState<string>();
  const [newAccessToken, setNewAccessToken] = useState<string | undefined>();

  const [connectionError, setConnectionError] = useState<string>();
  const [tokenError, setTokenError] = useState<string>();
  const [versionError, setVersionError] = useState<string>();

  const [backendIdentifier, setBackendIdentifier] = useState<string>();
  const [savingApiSettings, setSavingApiSettings] = useState(false);

  const base64EncodedAccessTokenSet = !!pluginMeta?.secureJsonFields?.base64EncodedAccessToken;

  const reset = useCallback(() => {
    setBackendUrl(removeTrailingSlashes(jsonData?.backendUrl || ''));
    // Blank string indicates that we should let the user edit it.
    // Undefined means that it is already set, and we can disable the input.
    setNewAccessToken(base64EncodedAccessTokenSet ? undefined : '');

    setConnectionError(undefined);
    setTokenError(undefined);
  }, [setConnectionError, setTokenError, setBackendUrl, jsonData, base64EncodedAccessTokenSet]);

  useEffect(() => {
    if (backend.backendError) {
      setBackendIdentifier('Cannot connect');
    } else if (!backend.isBackend) {
      setBackendIdentifier('Checking...');
    } else {
      setBackendIdentifier(`${backend.name} ${backend.version || '(version not reported)'}`);
    }
  }, [backend, setBackendIdentifier]);

  useEffect(() => {
    reset();
  }, [reset]);

  const onTokenReset = () => setNewAccessToken('');

  useEffect(() => {
    if (newAccessToken === '' || !newAccessToken) {
      setTokenError(undefined);
    } else {
      setTokenError(getLicenseTokenError(newAccessToken));
    }
  }, [newAccessToken]);

  const onChangeBackendUrl = (event: ChangeEvent<HTMLInputElement>) => {
    setBackendIdentifier(undefined); // Clear if user starts to modify
    setConnectionError(undefined);
    setVersionError(undefined);
    setBackendUrl(event.target.value.trim());
  };

  const onChangeToken = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.trim();
    setTokenError(getLicenseTokenError(value));
    setNewAccessToken(value);
  };

  const onSaveApiSettings = async () => {
    if (!backendUrl) {
      return;
    }
    const fixedUrl = removeTrailingSlashes(backendUrl?.trim());
    setBackendUrl(fixedUrl);
    setSavingApiSettings(true);

    const { tokenError, urlError } = await api.validateApiSettings(pluginResourceUrlPrefix, {
      token: newAccessToken || '',
      url: backendUrl,
    });

    if (tokenError || urlError || versionError) {
      setTokenError(tokenError);
      setConnectionError(urlError);
      setSavingApiSettings(false);
      return;
    }

    if (id && setPluginJsonData) {
      await setPluginJsonData(
        {
          backendUrl: fixedUrl,
        },
        !newAccessToken
          ? {} // Don't change the token
          : { base64EncodedAccessToken: prepareTokenForAdminApiUpdate(newAccessToken) } // Set the new token
      );
    }
    setSavingApiSettings(false);
  };

  return (
    <div className="gf-form-group">
      {/* API SETTINGS */}
      <div>
        <Legend>Connection settings</Legend>

        {/* Access Token */}
        <Field
          label="Access token"
          description={
            <>
              The access token is a string of format &quot;&lt;name&gt;:&lt;password&gt;&quot; that has been base64
              encoded. It is saved on the server.
            </>
          }
        >
          <>
            <SecretInput
              className={s.width500}
              id="base64EncodedAccessToken"
              data-testid="access-token"
              value={newAccessToken}
              placeholder={'Paste your token'}
              onChange={onChangeToken}
              isConfigured={Boolean(base64EncodedAccessTokenSet && newAccessToken === undefined)}
              onReset={onTokenReset}
            />
            {tokenError && (
              <FieldValidationMessage className={s.marginTopSm}>{capitalize(tokenError)}</FieldValidationMessage>
            )}
          </>
        </Field>

        {/* Enterprise database URL */}
        <Field label={`${name} URL`} description="" className={s.marginTopMd}>
          <>
            <Input
              disabled={savingApiSettings}
              className={s.width500}
              id="backendUrl"
              data-testid="backend-url"
              label={`${name} URL`}
              value={backendUrl}
              placeholder={`E.g.: http://${id?.split('-').slice(1, 3).join('-')}`}
              onChange={onChangeBackendUrl}
              suffix={backendIdentifier}
            />
            {connectionError && (
              <FieldValidationMessage className={s.marginTopSm}>{capitalize(connectionError)}</FieldValidationMessage>
            )}
          </>
        </Field>

        <HorizontalGroup className={s.marginTopMd}>
          <Button
            data-testid="test-and-save"
            type="submit"
            onClick={onSaveApiSettings}
            icon={savingApiSettings ? 'fa fa-spinner' : 'save'}
            // We would like to disable the submit button if the token is being edited but is either empty or incorrectly formatted.
            disabled={
              Boolean(
                !backendUrl ||
                  !(base64EncodedAccessTokenSet || newAccessToken !== undefined) ||
                  tokenError ||
                  newAccessToken === ''
              ) || savingApiSettings
            }
          >
            Test and save configuration
          </Button>
          <Button type="reset" onClick={reset} variant="destructive">
            Reset
          </Button>
        </HorizontalGroup>
      </div>
    </div>
  );
};
