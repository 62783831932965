import { AdminApiPrefix, AdminResourceApiName, Token } from '@common/types';
import {
  adminApiDeactivate,
  adminApiGet,
  adminApiGetAllItems,
  adminApiPost,
  excludeReadOnlyFields,
  RequestOptions,
} from '@common/utils';

const TOKENS: AdminResourceApiName = 'tokens';

export const getTokens = async (prefix: AdminApiPrefix) => {
  const response = await adminApiGetAllItems<Token>(prefix, TOKENS);
  return response.data.items;
};

export const getToken = async (prefix: AdminApiPrefix, name: string, options?: RequestOptions) => {
  const response = await adminApiGet<Token>(prefix, TOKENS, name, options);
  return response.data;
};

export const createToken = async (prefix: AdminApiPrefix, token: Token) => {
  // Unlike the other admin API resources (prior to 2022-03), Token is not `PotentiallyBlank`
  const response = await adminApiPost<Token>(prefix, TOKENS, { data: token });
  return response.data;
};

export const deactivateToken = async (prefix: AdminApiPrefix, token: Token) => {
  const data = excludeReadOnlyFields(token);
  const response = await adminApiDeactivate<Token>(prefix, TOKENS, token.name, { data });
  return response.data;
};

export const updateToken = async (_: AdminApiPrefix, __: Token) => {
  throw new Error('Updating tokens is not supported.');
};
