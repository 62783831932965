import React from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { cx } from '@emotion/css';
import get from 'lodash/get';
import { v4 as uuid } from 'uuid';

import { Button, IconButton } from '@grafana/ui';

import { LabelPolicy } from '@common/types';
import { useUtilityStyles, validateLabelSelectorString } from '@common/utils';

import { Collapse } from '../Collapse';
import { LabelSelectorEditor } from '../LabelSelectorEditor';

type Props = {
  // The name of the label-policy section.
  // If it's used inside a field array then the name should be in the following format:  "parent[${parentIndex}].label_policies"
  name: string;
};

// !Important
// This component needs to be used inside a react-hook-form form context.
export const LabelPolicyEditor = ({ name }: Props) => {
  const s = useUtilityStyles();
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { append, fields, remove } = useFieldArray({ name, control });

  return (
    <Collapse title={`Label selectors (${fields.length})`} className={s.colorWeak}>
      {fields.map((labelPolicy: Partial<LabelPolicy>, index) => {
        return (
          <div className={cx(s.flex, s.marginTopSm, s.marginLeftMd)} key={labelPolicy.id}>
            {/* Label policy */}
            <div className={cx(s.resizeNone, s.flex1)}>
              <Controller
                control={control}
                rules={{
                  required: 'A label selector cannot be empty',
                  validate: validateLabelSelectorString,
                }}
                name={`${name}.${index}.selector`}
                defaultValue={labelPolicy.selector}
                render={({ field }) => {
                  return (
                    <LabelSelectorEditor
                      selector={field.value}
                      onChange={field.onChange}
                      error={get(errors, `${name}.${index}.selector.message`)}
                    />
                  );
                }}
              />
            </div>

            {/* Remove Label Policy */}
            <IconButton
              className={cx(s.colorWeak, s.pointer, s.marginLeftSm)}
              name="trash-alt"
              size="md"
              onClick={() => remove(index)}
            />
          </div>
        );
      })}

      {/* Add new Label Policy */}
      <Button
        type="button"
        variant="secondary"
        size="sm"
        className={cx(s.marginLeftMd, s.marginTopSm)}
        onClick={() =>
          append({
            id: uuid(),
            selector: '',
          })
        }
      >
        Add label selector
      </Button>
    </Collapse>
  );
};
