import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EntitySelectors, Selector } from '@reduxjs/toolkit';

import { AdminResource } from '@common/types';

import { useIsError as useIsRequestError, useIsLoading as useIsRequestLoading, useIsSuccess } from '../requests';
import { RootState } from '../store';

import { AdminResourceActions } from './adminResources.actions';

export const createHooks = <T extends AdminResource>(
  actions: AdminResourceActions<T>,
  selectors: EntitySelectors<T, RootState>,
  selectAllActive: Selector<RootState, T[], []>
) => {
  const { create, deactivate, fetchAll, fetchById, update } = actions;
  const { selectAll, selectEntities } = selectors;

  const useGetAll = (initialFetch = true) => {
    const dispatch = useDispatch();

    const reload = useCallback(() => dispatch(fetchAll()), [dispatch]);

    const isLoading = useIsRequestLoading(fetchAll.typePrefix);

    const isLoaded = useIsSuccess(fetchAll.typePrefix);

    const isError = useIsRequestError(fetchAll.typePrefix);

    const activeResources = useSelector(selectAllActive);

    const resources = useSelector(selectAll);

    const entityDictionary = useSelector(selectEntities);

    useEffect(() => {
      if (initialFetch && !isLoaded && !isLoading && !isError) {
        reload();
      }
    }, [isLoaded, isLoading, isError, reload, initialFetch]); // eslint-disable-line

    return { activeResources, entityDictionary, isError, isLoaded, isLoading, reload, resources };
  };

  const UNAVAILABLE = 'ACTION_UNAVAILABLE';

  const useFetchById = () => {
    const dispatch = useDispatch();

    if (!fetchById) {
      return (_: string) => {
        throw new Error('Unsupported action');
      };
    }
    return (name: string) => dispatch(fetchById(name));
  };

  const useDeactivate = () => {
    const dispatch = useDispatch();

    if (!deactivate) {
      return (_: T) => {
        throw new Error('Unsupported action');
      };
    }

    return (item: T) => {
      return dispatch(deactivate(item));
    };
  };

  // Returns with a function that can be used to create an item
  const useCreate = () => {
    const dispatch = useDispatch();

    if (!create) {
      return (_: T) => {
        throw new Error('Unsupported action');
      };
    }

    return (item: T) => dispatch(create(item));
  };

  // TODO MAKE SURE create?.typePrefix === 'tenants/update';
  const useCreateLoading = () => useIsRequestLoading(create?.typePrefix || UNAVAILABLE);

  // Returns with a function that can be used to update an item
  const useUpdate = () => {
    const dispatch = useDispatch();

    if (!update) {
      return (_: T) => {
        throw new Error('Unsupported action');
      };
    }

    return (item: T) => dispatch(update(item));
  };

  const useUpdateLoading = () => useIsRequestLoading(update?.typePrefix || UNAVAILABLE);

  return { useCreate, useCreateLoading, useDeactivate, useFetchById, useGetAll, useUpdate, useUpdateLoading };
};

export type AdminApiHooks<T extends AdminResource> = ReturnType<typeof createHooks<T>>;
