import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';

export const getStyles = (theme: GrafanaTheme2) => ({
  modal: css`
    max-height: calc(80vh - 44px);
  `,
  title: css`
    font-size: ${theme.typography.h4.fontSize};
    font-weight: normal;
    padding-top: ${theme.spacing(1)};
    padding-bottom: ${theme.spacing(1)};
    padding-left: ${theme.spacing(2)};
    padding-right: ${theme.spacing(2)};
  `,
});
