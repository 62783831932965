import React, { useContext } from 'react';

import { LoadingIndicator } from '@common/components';
import { BackendContext, usePluginMeta } from '@common/utils';

export const BackendRequired = ({ children }: { children: JSX.Element }) => {
  const {
    backend: { backendError, isBackend },
  } = useContext(BackendContext);

  const backendUrl = usePluginMeta().pluginMeta?.jsonData?.backendUrl;

  if (backendError || !backendUrl) {
    // Do not even try.
    return null;
  }
  if (!isBackend) {
    return <LoadingIndicator />;
  }

  return children;
};
