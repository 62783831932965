import React from 'react';
import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';
import { Button, useStyles2 } from '@grafana/ui';

import { CurrentTimeUTC } from '@common/components';

type Props = {
  onCreate: () => void;
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    buttonWrapper: css`
      display: flex;
      justify-content: flex-end;
      margin-bottom: ${theme.spacing(2)};
      margin-top: ${theme.spacing(1)};
    `,
    timeWrapper: css`
      align-items: center;
      display: flex;
      justify-content: flex-end;
      margin-bottom: ${theme.spacing(1)};
      margin-top: ${theme.spacing(4)};
    `,
  };
};

export const AccessPoliciesActionsBar: React.FC<Props> = ({ onCreate }) => {
  const styles = useStyles2(getStyles);

  return (
    <>
      {/* Create new */}
      <div className={styles.buttonWrapper}>
        <Button variant="primary" size="md" onClick={onCreate}>
          Create access policy
        </Button>
      </div>

      {/* Current UTC time */}
      <div className={styles.timeWrapper}>
        <CurrentTimeUTC />
      </div>
    </>
  );
};
