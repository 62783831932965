import React from 'react';
import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';
import { Tooltip, useStyles2 } from '@grafana/ui';

import { TooltipPlacement } from '@common/types';

interface Props {
  hidden?: boolean;
  label?: string;
  maxWidth?: string;
  value?: string;
  placement?: TooltipPlacement;
  interactive?: boolean;
}

/**
 * A tooltip that shows on hover and display a label as header
 * and a value as subheader
 */
export const DetailedTooltip = ({
  hidden,
  label,
  maxWidth = css`
    max-width: 500px;
  `,
  placement = 'left',
  interactive = false,
  value,
}: Props) => {
  const styles = useStyles2(getStyles(maxWidth, hidden));

  return (
    <Tooltip
      interactive={interactive}
      placement={placement}
      content={() => {
        return (
          <div>
            {label && <div className={styles.tooltipContentLabel}>{label}</div>}
            {value && value !== '*' && <div className={styles.tooltipContentValue}>{value}</div>}
          </div>
        );
      }}
    >
      <div className={styles.childLabel}>{label}</div>
    </Tooltip>
  );
};

const getStyles = (maxWidth: string, hidden?: boolean) => (theme: GrafanaTheme2) => {
  return {
    tooltipContentLabel: css`
      color: ${theme.colors.text.primary};
      text-overflow: clip;
      overflow-y: scroll;
      max-height: 300px;
      overflow: ${hidden && 'hidden'};
    `,
    tooltipContentValue: css`
      color: ${theme.colors.text.secondary};
    `,
    childLabel: css`
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      vertical-align: middle;
      ${maxWidth};
    `,
  };
};
