import React, { useState } from 'react';
import { css } from '@emotion/css';
import moment from 'moment';

import { GrafanaTheme2, SelectableValue } from '@grafana/data';
import { Button, DatePicker, Field, Form, Input, InputControl, Select, Tooltip, useStyles2 } from '@grafana/ui';

import { useCreateLoading } from '@common/state/src/tokens';
import { daysBetween, getDateForDaysLater } from '@common/utils';

export type TokenFormData = {
  display_name: string;
  expiration: string;
};

const DAYS_LATER_OPTIONS = [30, 60, 90];

const getSelectableValues = () => {
  return [
    { description: 'The token will never expire', label: 'No expiry', value: '' },
    ...DAYS_LATER_OPTIONS.map(selectableValueForDay),
  ] as Array<SelectableValue<string>>;
};

const selectableValueForDay = (daysLater: number) => {
  const date = getDateForDaysLater(daysLater);
  return {
    description: `The token will expire on ${date}`,
    label: `${daysLater} days`,
    value: date,
  };
};

const customSelectableOption = () => {
  return {
    description: 'Choose expiration by selecting a date',
    label: 'Choose an expiration date',
    value: 'custom',
  };
};

type Props = {
  nameValidator: (tokenName: string) => string | undefined;
  onCancel: () => void;
  onSubmit: (data: TokenFormData) => void;
};

export const CreateForm = ({ nameValidator, onCancel, onSubmit }: Props) => {
  const styles = useStyles2(getStyles);
  const isCreating = useCreateLoading();
  const submitButtonText = isCreating ? 'Creating...' : 'Create';
  const tomorrow = moment().add(1, 'days');

  const [customExpirySelections, setCustomExpirySelections] = useState<Array<SelectableValue<string>>>([]);
  const [pickedExpirationDate, setPickedExpirationDate] = useState<Date>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [expireDays, setExpireDays] = useState<number>(0);

  const handleDateChange = (value: Date) => {
    setPickedExpirationDate(value);
  };

  // There is a rare chance that a selection will be hidden if it happens right before a day boundary
  const selectableValues = [...getSelectableValues(), ...customExpirySelections, customSelectableOption()];
  const expirationDescription =
    expireDays > 0
      ? `User's permissions outlined in this access policy will expire in ${expireDays} days`
      : `User's permissions outlined in this access policy will never expire`;

  return (
    <Form validateOn="onChange" onSubmit={onSubmit}>
      {({ control, errors, formState, register }) => (
        <>
          {/* Display Name */}
          <Field
            label="Display name"
            invalid={!!errors.display_name}
            error={errors.display_name?.message || 'Display name is required'}
            description="Assign name to token"
          >
            <>
              <Input
                {...register('display_name', {
                  required: true,
                  validate: nameValidator,
                })}
                name="display_name"
                placeholder=""
                data-testid="token-form-display_name"
              />
            </>
          </Field>

          {/* Expiration */}
          <Field label="Expiration" description="Select/Enter token expiration (in days)">
            <>
              <InputControl
                render={({ field }) => (
                  <>
                    <Select
                      {...field}
                      allowCustomValue={true}
                      isValidNewOption={(inputValue) => {
                        const num = Number(inputValue);
                        return num > 0 && num < 10000 && !DAYS_LATER_OPTIONS.includes(num);
                      }}
                      onCreateOption={(value) => {
                        const newOption = selectableValueForDay(Number(value));
                        field.onChange(newOption.value);

                        setExpireDays(daysBetween(true, newOption.value));

                        setCustomExpirySelections([newOption]);
                      }}
                      formatCreateLabel={(value) => {
                        return `Custom expiry: ${selectableValueForDay(Number(value)).label}`;
                      }}
                      options={selectableValues}
                      onChange={(value) => {
                        if (value.value === 'custom') {
                          setIsOpen(true);
                        } else {
                          if (value.value) {
                            setExpireDays(daysBetween(true, value.value));
                          } else {
                            // when value has no expiry
                            setExpireDays(-1);
                          }
                        }

                        field.onChange(value.value);
                        setCustomExpirySelections([]);
                      }}
                      openMenuOnFocus={true}
                    />
                    <DatePicker
                      {...field}
                      isOpen={isOpen}
                      minDate={tomorrow.toDate()}
                      value={moment(pickedExpirationDate).toDate()}
                      onChange={(value) => {
                        const formattedDate = moment(value).format('YYYY-MM-DD');

                        // calculate the days between today and selected date including the start
                        const days = daysBetween(true, formattedDate);

                        setExpireDays(days);

                        // add to new option of the select
                        const newOption = selectableValueForDay(days);
                        setCustomExpirySelections([newOption]);

                        field.onChange(formattedDate);
                        handleDateChange(value);
                        setIsOpen(false);
                      }}
                      onClose={() => setIsOpen(false)}
                    />
                  </>
                )}
                control={control}
                name="expiration"
                defaultValue={selectableValues[0].value}
                data-testid="token-form-expiration"
              />
            </>
          </Field>

          {/* Actions */}
          <div className={styles.actionWrapper}>
            <Tooltip content={expirationDescription} placement="bottom-start">
              <Button
                className={styles.submitButton}
                type="submit"
                disabled={!formState.isValid || isCreating}
                icon={isCreating ? 'fa fa-spinner' : undefined}
              >
                {submitButtonText}
              </Button>
            </Tooltip>
            <Button onClick={onCancel} variant="secondary">
              Cancel
            </Button>
          </div>
        </>
      )}
    </Form>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    actionWrapper: css`
      margin-top: ${theme.spacing(3)};
    `,
    submitButton: css`
      margin-right: ${theme.spacing(1)};
    `,
  };
};
