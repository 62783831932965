import { Token } from '@common/types';

import { createHooks } from '../adminResources/adminResources.hooks';
import { RootState } from '../store';

import { tokenActions } from './tokens.actions';
import { entityAdapter } from './tokens.reducer';
import { selectAll } from './tokens.selectors';

const selectRoot = (state: RootState) => state.tenants;
const selectors = entityAdapter.getSelectors(selectRoot);

export const { useGetAll, useDeactivate, useCreate, useCreateLoading, useUpdate, useUpdateLoading } =
  createHooks<Token>(tokenActions, selectors, selectAll);
