import React, { useContext } from 'react';

import { locationService } from '@grafana/runtime';
import { HorizontalGroup, RadioButtonGroup } from '@grafana/ui';

import { useRingMetadata } from '@common/state/src/rings';
import { RingType } from '@common/types';
import { BackendContext, usePluginMeta } from '@common/utils';

import { useRingRadioOptions } from './hooks';
import { Ring } from './Ring';
import { RingHealthRefresh } from './RingHealthRefresh';

export type RingManagerProps = {
  enableRefresh?: boolean;
};

export const RingManager = ({ enableRefresh }: RingManagerProps) => {
  const {
    backend: {
      implicitFeatures: { ringTypes },
    },
  } = useContext(BackendContext);

  const [currentRing, setCurrentRing] = useCurrentRingState(ringTypes);

  const ringMetadata = useRingMetadata();

  const radioOptions = useRingRadioOptions(ringMetadata);

  const ringSelectRow = (
    <HorizontalGroup justify="space-between">
      <RadioButtonGroup options={radioOptions} value={currentRing} onChange={setCurrentRing} />
      {enableRefresh && <RingHealthRefresh />}
    </HorizontalGroup>
  );

  const metadata = currentRing && ringMetadata.get(currentRing);

  return (
    <div>
      {ringSelectRow}
      {metadata && <Ring ringType={currentRing!} metadata={metadata}></Ring>}
    </div>
  );
};

function useCurrentRingState(ringTypes: RingType[]) {
  const { pluginAppUrl } = usePluginMeta();
  const baseUrl = `${pluginAppUrl}/ring-health`;

  // Use the location URL as the single source of truth of which ring is selected
  const location = locationService.getLocation();
  const history = locationService.getHistory();
  const urlEnd = location.pathname.split('/').at(-1);

  const setCurrentRing = (ringType: RingType) => {
    history.push(`${baseUrl}/${ringType}`);
  };

  let currentRing = ringTypes.find((r) => r === urlEnd);
  if (!currentRing && ringTypes.length > 0) {
    // `replace` the URL to include the default ring, and ensure the current ring matches.
    // Using `replace` to ensure that we can still use the back button.
    const defaultRing = ringTypes[0];
    history.replace(`${baseUrl}/${defaultRing}`);
    currentRing = defaultRing;
  }

  return [currentRing, setCurrentRing] as [typeof currentRing, typeof setCurrentRing];
}
