import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from '@tanstack/react-query';

import { PluginPage } from '@grafana/runtime';
import { Alert } from '@grafana/ui';

import { BackendError, BackendFeaturesResponse, getBackendFeatures, getConfig } from '@common/api';
import { ApiPrefixes, changeApiPrefixesAction } from '@common/state/src/apiPrefixes/apiPrefixes.actions';
import { AdminApiPrefix, getAdminApiPrefix, getPluginApiPrefix } from '@common/types';
import { BackendContext, getBackendContextObject, usePluginMeta } from '@common/utils';

type Props = {
  children: ReactNode[] | ReactNode;
};

export const BackendContextWrapper = ({ children }: Props) => {
  const { pluginMeta, pluginResourceUrlPrefix } = usePluginMeta();
  const dispatch = useDispatch();

  const [adminApiPrefix, setAdminApiPrefix] = useState<AdminApiPrefix>();

  useEffect(() => {
    if (pluginMeta) {
      // Redux store needs to know the prefixes.
      const prefixes: ApiPrefixes = {
        adminApiPrefix: getAdminApiPrefix(pluginMeta.id),
        pluginPrefix: getPluginApiPrefix(pluginMeta.id),
      };
      dispatch(changeApiPrefixesAction(prefixes));
      setAdminApiPrefix(prefixes.adminApiPrefix);
    }
  }, [pluginMeta, dispatch]);

  // First the features for the current backend must be obtained
  const { data: backend, error: backendErrorResponse } = useQuery<BackendFeaturesResponse, BackendError>(
    [
      adminApiPrefix,
      'features',
      // when we consider multiple backends, we can add the current backend's identifier here
    ],
    () => getBackendFeatures(adminApiPrefix)
  );

  // The backend type selected implicitly determines the `configFetchUrl`
  const configFetchUrl = backend?.implicitFeatures.configFetchUrl;

  const fetchConfig = useCallback(() => {
    if (!configFetchUrl) {
      // Don't fetch anything while we don't have a defined URL.
      return {};
    }
    return getConfig(pluginResourceUrlPrefix, configFetchUrl);
  }, [configFetchUrl, pluginResourceUrlPrefix]);

  const { data: config, error } = useQuery(
    [
      configFetchUrl, // trigger a fetch on defined (or redefined) `configFetchUrl`
      'config',
      // when we consider multiple backends, we can add the current backend's identifier here
    ],
    fetchConfig
  );

  if (error) {
    return (
      <PluginPage>
        <Alert
          severity="error"
          title={
            `Unable to connect to the backend database. ` +
            `Either the database backend URL is not correct, or the database is not currently available. `
          }
        >
          {typeof error === 'string' ? error : JSON.stringify(error)}
        </Alert>
      </PluginPage>
    );
  }

  const backendError = backendErrorResponse
    ? backendErrorResponse?.message || backendErrorResponse?.data?.message
    : undefined;

  const context = getBackendContextObject(backend, adminApiPrefix, backendError, config);

  // TODO ensure we associate the state storage strategy
  // with the currently selected backend for admin objects.
  // Then in the future, when we handle multiple backends,
  // when we switch to a different backend, we will swap to
  // use the selected backend's state store.

  // Wrap the children in the context
  return <BackendContext.Provider value={context}>{children}</BackendContext.Provider>;
};
