import React from 'react';
import { cx } from '@emotion/css';

import { Button, InfoBox } from '@grafana/ui';

import { useUtilityStyles } from '@common/utils';

type Props = {
  onCreate: () => void;
};

export const CreateAccessPolicyInfoBox: React.FC<Props> = ({ onCreate }) => {
  const s = useUtilityStyles();

  return (
    <InfoBox severity="info">
      <div className={cx(s.textItalic, s.textCenter)}>There are no access policies yet.</div>
      <div className={cx(s.textCenter, s.paddingVerticalXl)}>
        <Button variant="primary" size="lg" onClick={onCreate}>
          Create access policy
        </Button>
      </div>
      <div className={cx(s.textCenter, s.colorWeak)}>
        An access policy is a resource that contains a granular set of permissions which specify what actions a request
        to GME is allowed to do.
      </div>
    </InfoBox>
  );
};
