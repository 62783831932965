import React from 'react';
import { useDispatch } from 'react-redux';

import { create } from '@common/state/src/accessPolicies';
import { AccessPolicy } from '@common/types';

import { CreateAccessPolicyModal } from './CreateAccessPolicyModal';

type Props = {
  isOpen: boolean;
  onDismiss: () => void;
};

export const CreateAccessPolicyModalContainer = ({ isOpen, onDismiss }: Props) => {
  const dispatch = useDispatch();
  const onCreate = (accessPolicy: AccessPolicy) => dispatch(create(accessPolicy));

  return <CreateAccessPolicyModal isOpen={isOpen} onDismiss={onDismiss} onCreate={onCreate} />;
};
