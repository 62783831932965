import { any as ramdaAny, complement, isNil } from 'ramda';

export const MAX_NAME_LENGTH = 63;

export const any = ramdaAny;

/**
 * Generates a "name" from any string that only contains characters accepted by the backend.
 * (we generally use the "name" properties as IDs throughout the app)
 */
export const generateName = (displayName: string) => {
  return displayName
    .toLowerCase()
    .trim()
    .replace(/\s\s+/g, ' ')
    .replace(/[^a-z0-9-]/g, '-')
    .slice(0, MAX_NAME_LENGTH);
};

/** See: https://ramdajs.com/docs/#complement */
export const isDefined = complement(isNil);

export const isString = (v: unknown) => typeof v === 'string';

export const isNumber = (v: unknown) => typeof v === 'number';

export const isBoolean = (v: unknown) => typeof v === 'boolean';

export const isObject = (value: any) => typeof value === 'object' && value !== null && !Array.isArray(value);

export const isArray = (value: any) => Array.isArray(value);

export const isEmptyArray = (value: any) => isArray(value) && value.length === 0;

export const isNonEmptyArray = (list: unknown[] | undefined | null) => (list?.length ?? 0) > 0;

export const isEmptyObject = (value: any) =>
  Boolean(value) && value.constructor === Object && Object.keys(value).length === 0;

export const safeBase64 = (str: string) => {
  try {
    return window.atob(str);
  } catch (err) {
    return null;
  }
};
