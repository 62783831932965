import { descend, propOr, sortWith } from 'ramda';

import { AccessPolicy, Realm } from '@common/types';

import { labelSelectorFromString } from './labels';

export const DEFAULT_ACCESS_POLICY_NAME = '__admin__';
export const SYSTEM_MONITORING_ACCESS_POLICY_NAME = '__system__'; // FIXME GEM-specific
const ALTERNATE_DISPLAY_NAMES: Record<string, string> = {
  [DEFAULT_ACCESS_POLICY_NAME]: 'Default Access Policy',
  [SYSTEM_MONITORING_ACCESS_POLICY_NAME]: 'System Monitoring Access Policy',
};

export const getTenants = (accessPolicy: AccessPolicy): string[] =>
  (accessPolicy.realms || []).map((realm) => realm?.tenant || '').filter(Boolean);

export const isWildcardAccessPolicy = (accessPolicy: AccessPolicy) => getTenants(accessPolicy)[0] === '*';

export const countLabelsForAccessPolicy = (accessPolicy: AccessPolicy) => {
  return (accessPolicy.realms || []).map(countLabelsForRealm).reduce((acc, current) => acc + current, 0);
};

export const countLabelsForRealm = (realm: Realm) =>
  (realm.label_policies || [])
    .map(({ selector }) => (selector ? labelSelectorFromString(selector).length : 0))
    .reduce((acc, current) => acc + current, 0);

export const getTenantsWithLabels = (accessPolicy: AccessPolicy) =>
  (accessPolicy.realms || [])
    .map((realm) => {
      if (realm.tenant) {
        const numberOfLabels = countLabelsForRealm(realm);
        const numberOfLabelsText =
          numberOfLabels > 0 ? ` (${numberOfLabels} label${numberOfLabels > 1 ? 's' : ''})` : '';

        return `${realm.tenant}${numberOfLabelsText}`;
      }

      return null;
    })
    .filter(Boolean);

export const isDefaultAccessPolicy = ({ name }: AccessPolicy) => name === DEFAULT_ACCESS_POLICY_NAME;

export const getDisplayName = (accessPolicy: AccessPolicy) =>
  ALTERNATE_DISPLAY_NAMES[accessPolicy.name] || accessPolicy.display_name;

const readOnlyAccessPolicyNames = new Set([DEFAULT_ACCESS_POLICY_NAME, SYSTEM_MONITORING_ACCESS_POLICY_NAME]);

export const isAccessPolicyReadOnly = (accessPolicy: AccessPolicy) => readOnlyAccessPolicyNames.has(accessPolicy.name);

export const sortAccessPolicyByLatestFirst = sortWith<AccessPolicy>([descend(propOr('', 'created_at'))]);
