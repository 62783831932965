import _ from 'lodash';

import { BackendType } from './Features';

export enum Scope {
  Admin = 'admin',
  AlertsRead = 'alerts:read',
  AlertsWrite = 'alerts:write',
  LogsDelete = 'logs:delete',
  LogsRead = 'logs:read',
  LogsWrite = 'logs:write',
  MetricsDelete = 'metrics:delete',
  MetricsImport = 'metrics:import',
  MetricsRead = 'metrics:read',
  MetricsWrite = 'metrics:write',
  RulesRead = 'rules:read',
  RulesWrite = 'rules:write',
  TracesRead = 'traces:read',
  TracesWrite = 'traces:write',
}

export const getScopeByValue = (value: string): Scope => {
  const scopeEntry: [string, Scope] | undefined = _.find(Object.entries(Scope), (entry) => {
    return entry[1] === value;
  });
  if (!scopeEntry) {
    throw new Error(`Value [${value}] does not map to an entry in type Scope`);
  }
  return scopeEntry[1];
};

// prettier-ignore
export const BASE_SCOPES_BY_BACKEND_TYPE: Record<BackendType, Scope[]> = {
  GEL: [
    Scope.Admin,
    Scope.AlertsRead,
    Scope.AlertsWrite,
    Scope.LogsRead,
    Scope.LogsWrite,
    Scope.LogsDelete,
    Scope.RulesRead,
    Scope.RulesWrite,
  ],
  GEM: [
    Scope.Admin,
    Scope.AlertsRead,
    Scope.AlertsWrite,
    Scope.MetricsRead,
    Scope.MetricsWrite,
    Scope.MetricsDelete,
    Scope.RulesRead,
    Scope.RulesWrite,
  ],
  GET: [
    Scope.Admin, 
    Scope.TracesRead, 
    Scope.TracesWrite
  ],
};

export const READ_SCOPE_BY_BACKEND_TYPE: Record<BackendType, Scope> = {
  GEL: Scope.LogsRead,
  GEM: Scope.MetricsRead,
  GET: Scope.TracesRead,
};

// prettier-ignore
export const DEFAULT_SCOPES_BY_BACKEND_TYPE: Record<BackendType, Scope[]> = {
  GEL: [
    Scope.AlertsRead,
    Scope.AlertsWrite,
    Scope.RulesRead,
    Scope.RulesWrite,
    Scope.LogsRead
  ],
  GEM: [
    Scope.AlertsRead,
    Scope.AlertsWrite,
    Scope.RulesRead,
    Scope.RulesWrite,
    Scope.MetricsRead,
  ],
  GET: [
    Scope.TracesRead
  ],
};
