import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { config } from '@grafana/runtime';

import { GeneratedDatasource } from '@common/types';
import { getExistingDatasourcesAsGenerated } from '@common/utils';

import { create } from './generatedDatasources.actions';

export const generatedDatasourcesAdapter = createEntityAdapter<GeneratedDatasource>({
  selectId: ({ name }: GeneratedDatasource) => name,
});

export const { reducer } = createSlice({
  name: 'generatedDatasources',
  extraReducers: (builder) =>
    builder
      // Create
      .addCase(create.fulfilled, (state, action) => {
        generatedDatasourcesAdapter.upsertOne(state, action.payload);
      }),
  initialState: {
    entities: getExistingDatasourcesAsGenerated(),
    ids: Object.keys(config.datasources),
  },
  reducers: {},
});
