import { useContext, useEffect, useState } from 'react';

import { Limits } from '@common/types';

import { BackendContext } from './backend';
import { isEmptyArray, isEmptyObject, isString } from './general';

// These are the limits the GEM supports overriding via Tenants
// The other backends do not support any limits
export const GEM_TENANT_CUSTOMIZABLE_LIMITS = [
  'compactor_blocks_retention_period',
  'ingestion_burst_size',
  'ingestion_rate',
  'max_fetched_chunk_bytes_per_query',
  'max_fetched_chunks_per_query',
  'max_fetched_series_per_query',
  'max_global_series_per_metric',
  'max_global_series_per_user',
  'max_global_exemplars_per_user',
  'max_series_per_query',
  'ruler_max_rules_per_rule_group',
  'ruler_max_rule_groups_per_tenant',
];

export const useSupportedLimitValues = () => {
  const {
    backend: {
      implicitFeatures: { tenantCustomizableLimits },
    },
    config,
  } = useContext(BackendContext);
  const [limitValues, setLimitValues] = useState<Limits>({});

  useEffect(() => {
    // Whenever the config changes
    const limits: Limits = {};

    // Populate values for the tenant customizable limits
    const configLimits: Limits = { ...config?.limits };
    for (let limit of tenantCustomizableLimits) {
      limits[limit] = configLimits[limit];
    }

    setLimitValues(limits);
  }, [config, tenantCustomizableLimits]);

  return limitValues;
};

export const filterLimits = (limits: Limits = [], searchQuery: string) =>
  Object.keys(limits)
    .filter((limitName) => limitName.match(searchQuery))
    .reduce((acc, limitName) => ({ ...acc, [limitName]: limits[limitName] }), {});

export const isEmptyLimit = (limit: unknown): boolean => {
  return isEmptyArray(limit) || isEmptyObject(limit) || limit === undefined || limit === null || limit === '';
};

export const isOverridden = (name: string, overriddenLimits: Limits, defaultLimits: Limits) => {
  const defaultValue = defaultLimits[name];
  const overriddenValue = overriddenLimits[name];
  // Empty slice - Go has a different represantation of an empty slice in Yaml and JSON
  // (While an empty slice is `[]` in Yaml representation, it is going to be `null` in JSON representation)
  // More info: https://github.com/grafana/gex-plugins/issues/182
  const areBothEmptySlices = isEmptyArray(defaultValue) && (isEmptyArray(overriddenValue) || overriddenValue === null);

  if (areBothEmptySlices) {
    return false;
  }

  if (isEmptyLimit(overriddenValue) && isEmptyLimit(defaultValue)) {
    return false;
  }

  return defaultValue !== overriddenValue && overriddenValue !== undefined;
};

// Removes any limits that still have the same value as the default ones.
// This is our way in the frontend to separate custom set limits from the default ones.
// (Currently the backend assigns all the limits to a tenant even if we only specifically set a single limit)
export const getOverriddenLimits = (overriddenLimits: Limits, defaultLimits: Limits) =>
  Object.keys(overriddenLimits)
    .filter((name) => isOverridden(name, overriddenLimits, defaultLimits))
    .sort()
    .reduce((acc, limitName) => ({ ...acc, [limitName]: overriddenLimits[limitName] }), {});

// Removes the limits from the object {} that are customised.
export const getNotOverriddenLimits = (overriddenLimits: Limits, defaultLimits: Limits) =>
  Object.keys(defaultLimits)
    .filter((name) => !isOverridden(name, overriddenLimits, defaultLimits))
    .sort()
    .reduce((acc, limitName) => ({ ...acc, [limitName]: defaultLimits[limitName] }), {});

// We can have numbers, booleans or even arrays as limits.
export const stringifyLimit = (limit: any) => (isString(limit) ? limit : JSON.stringify(limit));
