import { useCallback, useEffect, useState } from 'react';
import copy from 'copy-to-clipboard';

/// Provides a callback that can copy to the clipboard, and has a "copied" indicator
export default function useCopyToClipboard(text: string, duration = 2500): [boolean, () => void] {
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    if (isCopied) {
      const id = setTimeout(() => {
        setIsCopied(false);
      }, duration);
      return () => {
        clearTimeout(id);
      };
    }
    return () => void 0;
  }, [isCopied, duration]);

  return [
    isCopied,
    useCallback(() => {
      const didCopy = copy(text);
      setIsCopied(didCopy);
    }, [setIsCopied, text]),
  ];
}
