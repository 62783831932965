import React from 'react';
import { cx } from '@emotion/css';

import { useUtilityStyles } from '@common/utils';

type LicenseDataRowProps = {
  name: string;
  value: string | number;
};

export const LicenseDataRow = ({ name, value }: LicenseDataRowProps) => {
  const s = useUtilityStyles();
  return (
    <div className={cx(s.flex)}>
      <div className={cx(s.textBold, s.colorStrong, s.marginRightSm)}>{name}:</div>
      <div className={cx(s.colorSemiWeak)}>{value}</div>
    </div>
  );
};
