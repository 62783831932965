import { Token } from '@common/types';
import { removeTokensFromEntities } from '@common/utils';

import { clearSecrets } from '../adminResources/adminResources.actions';
import { createAdminResourceReducer, SpecialCaseReducer } from '../adminResources/adminResources.reducer';

import { tokenActions } from './tokens.actions';

const specialReducerCases: SpecialCaseReducer[] = [
  {
    action: clearSecrets,
    reducer: (state) => {
      return {
        ...state,
        entities: removeTokensFromEntities(state.entities),
      };
    },
  },
];

export const { entityAdapter, reducer } = createAdminResourceReducer<Token>(
  'tokens',
  tokenActions,
  specialReducerCases
);

export const tokensAdapter = entityAdapter;
