import React from 'react';
import { cx } from '@emotion/css';

import { Button } from '@grafana/ui';

import { GeneratedDatasource } from '@common/types';
import { useUtilityStyles } from '@common/utils';

import { DetailedTooltip } from '../../Utils';

type DatasourceSettingsProps = {
  name: string;
  basicAuthPassword: string;
  basicAuthUser: string;
  createDatasource: any;
  datasource: GeneratedDatasource | undefined;
  isCreatedAlready: boolean;
};

export const DatasourceSettings = ({
  name,
  basicAuthPassword,
  basicAuthUser,
  createDatasource,
  datasource,
  isCreatedAlready,
}: DatasourceSettingsProps) => {
  const s = useUtilityStyles();
  function renderAlreadyCreatedUI() {
    return (
      <div className={cx(s.marginLeftXl, s.colorSemiWeak, s.marginBottomSm)}>
        <span className={s.textItalic}>
          <DetailedTooltip label={`"${datasource?.name}"`} maxWidth={s.maxWidth300} />
        </span>
        <a
          rel="noreferrer"
          target="_blank"
          href={`datasources/edit/${datasource?.uid}`}
          className={cx(s.colorSemiWeak, s.marginLeftSm, s.textUnderline)}
        >
          Data source settings
        </a>
      </div>
    );
  }

  function renderCreateButton() {
    return (
      <div className={cx(s.marginLeftXl, s.colorSemiWeak, s.marginBottomSm)}>
        No datasource for this tenant yet.
        <Button
          disabled={createDatasource === null}
          variant="secondary"
          size="sm"
          className={s.marginLeftSm}
          onClick={
            createDatasource
              ? () =>
                  createDatasource({
                    name,
                    basicAuthPassword,
                    basicAuthUser,
                  })
              : undefined
          }
        >
          Create a data source
        </Button>
      </div>
    );
  }

  return <>{isCreatedAlready ? renderAlreadyCreatedUI() : renderCreateButton()}</>;
};
