import React from 'react';
import { Controller } from 'react-hook-form';

import { Select } from '@grafana/ui';

import { useGetAll, useIsLoading } from '@common/state/src/clusters';
import { Cluster } from '@common/types';

export type ClusterOption = {
  name: string;
  label: string;
};

type Props = {
  name?: string;
  control: any;
  required?: boolean;
};

export const SelectCluster = ({ name = 'cluster', control, required = false }: Props) => {
  const isLoading = useIsLoading();
  const clusters = useGetAll();

  if (isLoading) {
    return null;
  }

  return (
    <Controller
      control={control}
      name={name}
      rules={{ required }}
      render={({ field }) => (
        <Select
          onChange={({ name }) => field.onChange(name)}
          value={mapValueToOption(clusters, field.value)}
          options={clusters.map(({ name, display_name }) => ({
            name: name,
            label: display_name,
          }))}
        />
      )}
    />
  );
};

const mapValueToOption = (items: Cluster[], value: string | ClusterOption): ClusterOption => {
  if (typeof value === 'string') {
    const item = items.find(({ name }) => name === value);

    return item
      ? {
          name: item.name,
          label: item.display_name,
        }
      : {
          name: value,
          label: value,
        };
  }

  return value;
};
