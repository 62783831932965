import { RequestStatus } from '@common/types';

type Action = {
  payload: any;
  type: string;
};

const initialState: any = {};

const getTypePrefix = (type: string) => {
  // Extract the trailing '/pending', '/rejected', or '/fulfilled'
  const separator = type.lastIndexOf('/');
  const prefix = type.substring(0, separator);
  return prefix;
};

export const reducer = (state = initialState, action: Action) => {
  const { type } = action;

  // Pending
  if (type.endsWith('/pending')) {
    const typePrefix = getTypePrefix(type);

    return {
      ...state,
      [typePrefix]: {
        counter: state[typePrefix]?.counter || 0,
        status: RequestStatus.Pending,
      },
    };
  }

  // Success
  if (type.endsWith('/fulfilled')) {
    const typePrefix = getTypePrefix(type);

    return {
      ...state,
      [typePrefix]: {
        counter: (state[typePrefix]?.counter || 0) + 1,
        status: RequestStatus.Success,
      },
    };
  }

  // Error
  if (type.endsWith('/rejected')) {
    const typePrefix = getTypePrefix(type);
    const { error, errorMessage, errorTitle } = action.payload;

    return {
      ...state,
      [typePrefix]: {
        counter: (state[typePrefix]?.counter || 0) + 1,
        error,
        errorMessage,
        errorTitle,
        status: RequestStatus.Error,
      },
    };
  }

  return state;
};
