import { AppEvents, BusEvent } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';

export const getQueryParameter = (name: string, defaultValue: any = null) => {
  const urlParams = new URLSearchParams(window.location.search);
  const parameter = urlParams.get(name);

  return parameter || defaultValue;
};

export const getQueryParameterAsList = (name: string) =>
  getQueryParameter(name, '')
    .trim()
    .split(',')
    .map((param: string) => param.trim())
    .filter((param: string) => param);

export const querystring = (params: Record<string, any>) =>
  Object.keys(params)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join('&');

export const removeLeadingSlashes = (url: string) => url?.replace(/^\/+/, '');

export const removeTrailingSlashes = (url: string) => url?.replace(/\/+$/, '');

export const setUrlParameter = (key: string, value?: string) => {
  const urlParams = new URLSearchParams(window.location.search);

  urlParams.set(key, value || '');

  const newUrl = new URL(parent.location.href);
  newUrl.search = urlParams.toString();

  window.history.pushState({}, '', newUrl.toString());
};

export const recommendRefresh = (reason?: string) => {
  const event: BusEvent = { type: AppEvents.alertSuccess.name, payload: ['Browser refresh recommended', reason] };
  getAppEvents().publish(event);
};
