import { AdminApiPrefix, Tenant } from '@common/types';
import {
  adminApiDeactivate,
  adminApiGet,
  adminApiGetAllItems,
  adminApiPost,
  adminApiPut,
  excludeReadOnlyFields,
} from '@common/utils';

const type = 'tenants';

export const getTenants = async (prefix: AdminApiPrefix) => {
  const response = await adminApiGetAllItems<Tenant>(prefix, type);
  return response.data.items;
};

export const getTenant = async (prefix: AdminApiPrefix, name: string) => {
  const response = await adminApiGet<Tenant>(prefix, type, name);
  return response.data;
};

export const createTenant = async (prefix: AdminApiPrefix, tenant: Tenant) => {
  const response = await adminApiPost<Tenant>(prefix, type, {
    data: tenant,
  });
  return response.data;
};

export const deactivateTenant = async (prefix: AdminApiPrefix, tenant: Tenant) => {
  const data = excludeReadOnlyFields(tenant);
  const response = await adminApiDeactivate<Tenant>(prefix, type, tenant.name, { data });
  return response.data;
};

export const updateTenant = async (prefix: AdminApiPrefix, tenant: Tenant) => {
  const data = excludeReadOnlyFields(tenant);
  const response = await adminApiPut<Tenant>(prefix, type, tenant.name, { data });
  return response.data;
};
