import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';

import { colors } from '@common/utils';

export const getStyles = (className?: string) => (theme: GrafanaTheme2) => ({
  statusDot: css`
    height: ${theme.spacing(2)};
    width: ${theme.spacing(2)};
    border-radius: 100%;
    ${className};
  `,
  gradientGreen: css`
    background: linear-gradient(270deg, ${colors.green300} 0%, ${colors.green100} 100%);
  `,
  gradientRed: css`
    background: linear-gradient(180deg, ${colors.red300} 0%, ${colors.red500} 100%);
  `,
  gradientBlue: css`
    background: linear-gradient(180deg, ${colors.blue100} 0%, ${colors.blue200} 100%);
  `,
});
