import { CortexConfigSection } from '@common/types';
import { toYaml } from '@common/utils';

import { isObject } from './general';

export const mergePrimitiveSections = (config: Record<string, unknown>, primitiveSectionName = 'basic') => {
  return Object.entries(config).reduce((acc: Record<string, unknown>, current) => {
    const [sectionName, subSection] = current;
    if (isObject(subSection)) {
      return {
        ...acc,
        [sectionName]: subSection,
      };
    }

    return {
      ...acc,
      [primitiveSectionName]: {
        // @ts-ignore - The accumulated primitive sections is always going to be an object in this case
        ...acc[primitiveSectionName],
        [sectionName]: subSection,
      },
    };
  }, {});
};

export const getSections = (config: Record<string, unknown>): CortexConfigSection[] => {
  return Object.entries(mergePrimitiveSections(config)).map(([sectionName, sectionConfig]) => ({
    name: sectionName,
    config: toYaml(sectionConfig),
  }));
};
