/**
 * Cortex Ring Types are common within all of GEx
 */

import { BackendType } from './Features';

export enum RingType {
  Alertmanager = 'multitenant_alertmanager',
  Compactor = 'compactor',
  Distributor = 'distributor',
  Ingester = 'ingester',
  MetricsGenerator = 'metrics-generator',
  QueryScheduler = 'scheduler',
  Ruler = 'ruler',
  StoreGateway = 'store-gateway',
}

export const RING_TYPES = Object.values(RingType) as RingType[];

// prettier-ignore
export const RING_TYPES_BY_BACKEND_TYPE: Record<BackendType, RingType[]> = {
  GEL: [
    // See https://github.com/grafana/enterprise-logs/blob/main/pkg/enterprise/loki/gateway/routes.go
    RingType.Compactor,
    RingType.Distributor,
    RingType.Ingester, // This is listed as `/ring` instead of `/ingester/ring`
    RingType.Ruler,
    RingType.QueryScheduler,
  ],
  GEM: [
    // See https://github.com/grafana/backend-enterprise/blob/master/pkg/enterprise/gateway/routes.go
    RingType.Alertmanager,
    RingType.Compactor,
    RingType.Distributor,
    RingType.Ingester,
    RingType.Ruler,
    RingType.StoreGateway,
  ],
  GET: [
    // See https://github.com/grafana/enterprise-traces/blob/main/modules/gateway/routes.go
    RingType.Compactor,
    RingType.Distributor,
    RingType.Ingester,
    RingType.MetricsGenerator,
  ],
};
