import { Matcher, MatcherType } from '@common/types';

export const MatcherTypeSymbol: Record<string, string> = {
  [MatcherType.equal]: '=',
  [MatcherType.notEqual]: '!=',
  [MatcherType.match]: '=~',
  [MatcherType.noMatch]: '!~',
};

export const MatcherSymbolToType: Record<string, MatcherType> = {
  '!=': MatcherType.notEqual,
  '!~': MatcherType.noMatch,
  '=': MatcherType.equal,
  '=~': MatcherType.match,
};

function notNull<T>(x: T | null): x is T {
  return x !== null;
}

export const labelSelectorToString = (selector: Matcher[]): string => {
  return `{${selector.map(({ name, type, value }) => `${name}${MatcherTypeSymbol[type]}"${value}"`).join(', ')}}`;
};

export const labelSelectorFromString = (selectorAsString: string): Matcher[] => {
  return (
    selectorAsString
      .replace(/^{|}$/g, '') // remove the leading and trailing "{" & "}" characters if there are any
      .split(',')
      .map((s) => {
        const trimmed = s.trim();
        const match = trimmed.match(/([a-z_][\w-]*)(=~|!=|!~|=)(.*)/i); // allowing "-" characters in the label
        const isParseError = !match && trimmed.length > 0;

        if (isParseError) {
          throw new Error(`Wrong syntax, cannot parse "${trimmed}".`);
        }

        if (!match) {
          return null;
        }

        return {
          name: match[1],
          type: MatcherSymbolToType[match[2]],
          value: match[3].replace(/^"|"$/g, ''), // removing any " quotes
        };
      })
      // remove wrong matchers
      .filter(notNull)
  );
};

export const validateLabelSelectorString = (selectorAsString: string) => {
  try {
    labelSelectorFromString(selectorAsString);

    return true;
  } catch (e) {
    return e.message;
  }
};
