import React, { useEffect, useState } from 'react';

import { Tooltip } from '@grafana/ui';

import { isDateNowOrLater, isValidDate, timeAgo } from '@common/utils';

export const TimeAgo = ({ date }: { date?: Date | string }) => {
  const [indicator, setIndicator] = useState<string>(timeAgo(date));

  useEffect(() => {
    const refresh = () => {
      if (isDateNowOrLater(date)) {
        // Server is posting a time in the future
        setIndicator('now');
      } else {
        setIndicator(timeAgo(date));
      }
    };

    const interval = setInterval(refresh, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [date]);

  const dateTooltip = isValidDate(date) ? new Date(date).toUTCString() : `[${String(date)}]`;

  return (
    <Tooltip content={dateTooltip} placement="top">
      <span>{indicator}</span>
    </Tooltip>
  );
};
