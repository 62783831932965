import { createSelector } from 'reselect';

import { AccessPolicy, isAdminResourceActive, Tenant } from '@common/types';
import { getWildcardTenant, isWildcardRealm, sortByDateField } from '@common/utils';

import { RootState } from '../store';

import { entityAdapter } from './tenants.reducer';

export const selectRoot = (state: RootState) => state.tenants;
const selectors = entityAdapter.getSelectors(selectRoot);

const { selectAll: selectAllIncludingInactive } = selectors;

export const selectAll = createSelector(selectAllIncludingInactive, (tenants) =>
  sortByDateField(tenants.filter(isAdminResourceActive), 'created_at')
);

export const selectTenantsForAccessPolicy = (accessPolicy: AccessPolicy) =>
  createSelector(selectAll, (tenants) =>
    (accessPolicy.realms || [])
      .map((realm) => {
        const tenant = isWildcardRealm(realm)
          ? getWildcardTenant(realm.cluster as string)
          : tenants.find((tenant) => tenant.name === realm.tenant);

        // We only want to keep tenant objects in the list which resolve to an existing tenant, or a wildcard
        if (tenant) {
          return {
            ...tenant,
            label_policies: realm.label_policies,
          };
        }

        return null;
      })
      .filter(Boolean)
  );

export const selectTenantsForAddingToAccessPolicies = createSelector(selectAll, (tenants: Tenant[]) => {
  const usableTenants = tenants.filter((tenant) => tenant.name !== '__system__');
  const clusters = [...new Set<string>(usableTenants.map((tenant: Tenant) => tenant?.cluster as string))];

  return [...clusters.map(getWildcardTenant), ...usableTenants] as Tenant[];
});
