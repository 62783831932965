import {
  createAccessPolicy,
  deactivateAccessPolicy,
  getAccessPolicies,
  getAccessPolicy,
  updateAccessPolicy,
} from '@common/api';
import { AccessPolicy } from '@common/types';

import { createAdminResourceAsyncThunkActions } from '../adminResources/adminResources.actions';

export const accessPolicyActions = createAdminResourceAsyncThunkActions<AccessPolicy>('accesspolicies', {
  create: createAccessPolicy,
  deactivate: deactivateAccessPolicy,
  getAll: getAccessPolicies,
  getByName: getAccessPolicy,
  update: updateAccessPolicy,
});

export const { create } = accessPolicyActions;
