import { PluginApiPrefix } from '@common/types';
import { apiPost } from '@common/utils';

import { ValidateApiSettingsResponse } from './grafana';

export const validateApiSettings = async (prefix: PluginApiPrefix, { adminApiVersion = '', token = '', url = '' }) => {
  const options = {
    data: { adminApiVersion, token, url },
    showErrorAlert: false,
  };

  try {
    const response = await apiPost<ValidateApiSettingsResponse>(`${prefix}/validate-api-settings`, options);
    return response?.data;
  } catch (error: any) {
    return { urlError: error?.data?.message } as ValidateApiSettingsResponse;
  }
};
