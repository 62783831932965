import { map, omit } from 'ramda';

import { dateTimeFormatTimeAgo } from '@grafana/data';

import { Token } from '@common/types';

import { SYSTEM_MONITORING_ACCESS_POLICY_NAME } from './accessPolicies';
import { generateName, MAX_NAME_LENGTH } from './general';

export const SYSTEM_MONITORING_TOKEN_NAME_PREFIX = 'system-monitoring';
export const DEFAULT_DOES_NOT_EXPIRE_TEXT = 'Never';

export const getExpiration = (token: Token, doesNotExpireText = DEFAULT_DOES_NOT_EXPIRE_TEXT) => {
  const { expiration = '' } = token;

  // The "zero date" in go is returned by the server indicating that there is no expiration date set.
  if (expiration === '0001-01-01T00:00:00Z' || !expiration) {
    return doesNotExpireText;
  }

  return dateTimeFormatTimeAgo(new Date(expiration));
};

export const isTokenReadOnly = (token: Token) =>
  token.access_policy === SYSTEM_MONITORING_ACCESS_POLICY_NAME &&
  token.name.startsWith(SYSTEM_MONITORING_TOKEN_NAME_PREFIX);

export const removeTokensFromEntities: any = map(omit(['token']));

/**
 * Generate a name for token with the combination of the access
 * policy name and the token name
 * @param {string} accessPolicyName Original access policy name
 * @param {string} tokenDisplayName Original token display name
 * @returns {string} The combines names
 */
export const tokenNameGenerator = (accessPolicyName: string, tokenDisplayName: string) => {
  const shortenedAPName = accessPolicyName.slice(0, Math.ceil(MAX_NAME_LENGTH / 2));
  return generateName(`${shortenedAPName}-${tokenDisplayName}`);
};
