import React, { useMemo } from 'react';
import { isEmpty } from 'lodash';

import { Alert } from '@grafana/ui';

import { getEnterpriseLicenseExpiryDate, hasValidLicense } from '@common/utils';

export const EnterpriseLicenseAlerts = () => {
  const expiry = useMemo(getEnterpriseLicenseExpiryDate, []);
  const evaluation = hasValidLicense();

  if (expiry && expiry?.getTime() <= Date.now()) {
    const alertMessage = [
      `Your Grafana Enterprise license expired on ${expiry.toLocaleDateString()}.`,
      evaluation.valid
        ? ` This license will remain usable for one week past the expiry date.`
        : `This plugin is not usable without a valid license.`,
      'Please contact Grafana Labs for renewal.',
    ].join(' ');

    return (
      <Alert severity={evaluation.valid ? 'warning' : 'error'} title="Expired license">
        {alertMessage}
      </Alert>
    );
  }
  if (evaluation.valid) {
    return null;
  }
  const { message } = evaluation;

  const alertMessage = [
    'This plugin requires a valid Grafana Enterprise license.',
    message,
    'Please contact Grafana Labs for renewal.',
  ]
    .filter((message) => !isEmpty(message))
    .join(' ');

  return (
    <Alert severity="error" title="Invalid license">
      {alertMessage}
    </Alert>
  );
};
