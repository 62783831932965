import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';

export const getStyles = (theme: GrafanaTheme2) => ({
  createTenantContainer: css`
    display: flex;
    justify-content: flex-end;
    margin-top: ${theme.spacing(1)};
    margin-bottom: ${theme.spacing(2)};
  `,
  timeContainer: css`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: ${theme.spacing(4)};
    margin-bottom: ${theme.spacing(1)};
  `,
});
