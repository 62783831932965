import React, { useState } from 'react';
import { cx } from '@emotion/css';
import { AsyncThunkAction } from '@reduxjs/toolkit';

import { dateTimeFormatTimeAgo } from '@grafana/data';
import { ConfirmModal, IconButton } from '@grafana/ui';

import { Token } from '@common/types';
import { getExpiration, isTokenReadOnly, useUtilityStyles } from '@common/utils';

import { ApiError } from '../ApiError';
import { LoadingIndicator } from '../LoadingIndicator';
import { Table } from '../Table';

type Props = {
  className?: string;
  isError?: boolean;
  isLoading?: boolean;
  items: Token[];
  onDelete: (token: Token) => AsyncThunkAction<Token, Token, {}>;
};

export enum TokenField {
  Actions = 'actions',
  CreatedAt = 'created_at',
  Expiration = 'expiration',
  Name = 'name',
}

// Determines order of table columns
const TokenFieldNames = {
  [TokenField.Name]: 'Name',
  [TokenField.CreatedAt]: 'Created at',
  [TokenField.Expiration]: 'Expiration',
  [TokenField.Actions]: '',
};

export type TableFieldRenderers = {
  [key: string]: (item: Token) => React.ReactNode | null | number | string;
};

export const TokensList = ({ className = '', isError, isLoading, items = [], onDelete }: Props) => {
  const [tokenToBeDeleted, setTokenToBeDeleted] = useState<Token>();
  const s = useUtilityStyles();
  // We don't necessarily want to have the field renderers to be named components below:
  /* eslint-disable react/display-name */
  const fieldRenderers: TableFieldRenderers = {
    [TokenField.Name]: ({ name, display_name }) => (
      <>
        <div className={cx(s.colorStrong, s.textBold, s.textSm)}>{display_name}</div>
        <div className={s.textSm}>{name}</div>
      </>
    ),
    [TokenField.Expiration]: (token) => getExpiration(token),
    [TokenField.CreatedAt]: ({ created_at }) => (created_at ? dateTimeFormatTimeAgo(new Date(created_at)) : '-'),
    [TokenField.Actions]: (token) => (
      <div className={s.textRight}>
        {!isTokenReadOnly(token) && (
          <IconButton
            name="trash-alt"
            size="lg"
            onClick={(e) => {
              e.stopPropagation();
              setTokenToBeDeleted(token);
            }}
          />
        )}
      </div>
    ),
  };
  /* eslint-enable react/display-name */

  if (isError) {
    return <ApiError actionType="tokens/fetchAll" />;
  }

  // Currently we wouldn't like to display the loading spinner if there is any loading
  // in the background as we are trying to build an "optimistic UI".
  if (isLoading && !items.length) {
    return <LoadingIndicator />;
  }

  if (!items.length) {
    return <div className={cx(s.textCenter, s.colorWeak, s.textLg, s.marginTopLg)}>No tokens.</div>;
  }

  // TableData is almost identical to the one that the <Table> component from @grafana/ui expects for the easy transition
  // once that component is starting to support custom renderers for columns.
  const tableData = {
    fields: Object.entries(TokenFieldNames).map(([key, value]) => ({
      id: key,
      name: value,
      render: fieldRenderers[key],
      values: items,
    })),
  };

  return (
    <div className={className}>
      {/* Delete Confirmation Modal */}
      {tokenToBeDeleted && (
        <ConfirmModal
          isOpen
          icon="trash-alt"
          title="Delete tenant"
          body={<div className={s.textMd}>Are you sure you want to delete &quot;{tokenToBeDeleted.name}&quot;?</div>}
          confirmText="Delete"
          onDismiss={() => setTokenToBeDeleted(undefined)}
          onConfirm={() => {
            onDelete(tokenToBeDeleted);
            setTokenToBeDeleted(undefined);
          }}
        />
      )}

      <Table
        className={cx(s.widthFull, s.margin, s.marginYAuto, s.colorSemiWeak, s.textSm)}
        thClassName={cx(s.textSemiBold, s.colorWeak, s.paddingHorizontalLg, s.paddingVerticalXs)}
        tdClassName={cx(s.paddingHorizontalLg, s.paddingVerticalXs, s.maxWidth100, s.textOverflowEllipsis)}
        data={tableData}
        placement="bottom-start"
      />
    </div>
  );
};
