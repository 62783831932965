import { RequestInfo } from '@common/types';

export type StateWithRequestInfo = {
  requestInfo: { [key: string]: RequestInfo };
};

type ErrorResponse = {
  data: {
    message?: string;
  };
  status: number;
  statusText: string;
};

export const createRequestError = (errorTitle: string, error?: ErrorResponse) => {
  // Prints stack traces of the error at development time
  console.error(error);

  const message = error?.statusText || error?.data?.message || 'No error status available';
  const prefix = Boolean(error?.status) ? `HTTP ${error?.status} - ` : '';

  const errorMessage = prefix + message;

  // TODO Here is a place where we could consider mappings to alternative human-readable messages.
  // Perhaps by adding a "Details" field with useful exposition, to the returned object below.
  // see: https://github.com/grafana/gex-plugins/issues/599

  return {
    error,
    errorMessage,
    errorTitle, // Prevents unreadable "unserializable" stack trace
  };
};
