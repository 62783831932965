import React, { useCallback, useMemo, useState } from 'react';

import { Icon, Input, useStyles2 } from '@grafana/ui';

import { useLimitOverrideState } from '@common/state/src/cortex';
import { Limits } from '@common/types';
import { filterLimits } from '@common/utils';

import { getStyles } from './TenantLimits.styles';
import { TenantLimitsRow } from './TenantLimitsRow';

type Props = {
  /** (Optional) The overridden limits that are already set. */
  defaultValues?: Limits;
  /** A callback that is going to be called every time a limit is changed. */
  onChange: (data: Limits) => void;
};

export const TenantLimits = ({ defaultValues, onChange }: Props) => {
  const styles = useStyles2(getStyles);
  const [searchQuery, setSearchQuery] = useState('');

  const { getDefaultLimit, notOverriddenLimits, overriddenLimits, setUpdatedLimits } =
    useLimitOverrideState(defaultValues);

  const filteredNotOverriddenLimits = useMemo(
    () => filterLimits(notOverriddenLimits, searchQuery),
    [notOverriddenLimits, searchQuery]
  );

  const onLimitChanged = useCallback(
    (limitName: string, updatedValue: any) => {
      const updatedOverriddenLimits = { ...overriddenLimits, [limitName]: updatedValue };
      const { overridden } = setUpdatedLimits(updatedOverriddenLimits);
      onChange(overridden);
    },
    [setUpdatedLimits, overriddenLimits, onChange]
  );

  // Only show the first X values by default
  const visibleNotOverriddenLimits = searchQuery ? filteredNotOverriddenLimits : notOverriddenLimits;

  return (
    <>
      {/* Overridden limits */}
      <div className={styles.container}>
        {/* Title */}
        <div className={styles.title}>Custom limits</div>
        <div className={styles.description}>Limits that are custom to this tenant.</div>

        {/* List */}
        <div className={styles.topGapSm}>
          {Object.keys(overriddenLimits).map((limitName) => (
            <TenantLimitsRow
              isHighlighted
              key={limitName}
              name={limitName}
              value={overriddenLimits[limitName]}
              defaultValue={getDefaultLimit(limitName)}
              onChange={(updatedValue) => {
                onLimitChanged(limitName, updatedValue);
              }}
            />
          ))}
        </div>
      </div>

      {/* Available limits */}
      <div className={styles.availableLimitsContainer}>
        {/* Title */}
        <div className={styles.title}>Available limits</div>
        <div className={styles.description}>Default limits applied to the tenant.</div>

        {/* Search */}
        <div className={styles.topGapSm}>
          <Input
            value={searchQuery}
            placeholder="Search..."
            type="text"
            width={30}
            onChange={(e) => setSearchQuery(e.currentTarget?.value)}
            suffix={
              searchQuery ? <Icon className={styles.pointer} name="times" onClick={() => setSearchQuery('')} /> : null
            }
          />
        </div>

        {/* List */}
        <div className={styles.topGapSm}>
          {!Object.keys(visibleNotOverriddenLimits).length && (
            <div className={styles.description}>No limits found.</div>
          )}

          {Object.keys(visibleNotOverriddenLimits).map((limitName) => (
            <TenantLimitsRow
              key={limitName}
              name={limitName}
              searchQuery={searchQuery}
              value={notOverriddenLimits[limitName]}
              defaultValue={getDefaultLimit(limitName)}
              onChange={(updatedValue) => {
                onLimitChanged(limitName, updatedValue);
              }}
            />
          ))}
        </div>
      </div>
    </>
  );
};
