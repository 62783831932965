import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { Cluster } from '@common/types';

import { fetchAll } from './clusters.actions';

export const clustersAdapter = createEntityAdapter<Cluster>({ selectId: ({ name }: Cluster) => name });

export const { reducer } = createSlice({
  name: 'clusters',
  extraReducers: (builder) =>
    builder
      // Fetch All
      .addCase(fetchAll.fulfilled, (state, action) => {
        clustersAdapter.upsertMany(state, action.payload);
      }),
  initialState: clustersAdapter.getInitialState({}),
  reducers: {},
});
