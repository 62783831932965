import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';

export const getStyles = (theme: GrafanaTheme2) => ({
  container: css`
    border-radius: ${theme.shape.borderRadius(8)};
    font-size: ${theme.typography.bodySmall.fontSize};
    padding-left: ${theme.spacing(1)};
    padding-right: ${theme.spacing(1)};
    color: ${theme.colors.text.secondary};
    background-color: ${theme.colors.action.hover};
  `,
});
