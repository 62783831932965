import React from 'react';
import { cx } from '@emotion/css';

import { Icon } from '@grafana/ui';

import { formatDate, useUtilityStyles } from '@common/utils';

type Props = {
  className?: string;
};

export const CurrentTimeUTC: React.FC<Props> = ({ className }) => {
  const s = useUtilityStyles();
  const dateWithTimezone = new Date(Date.now());
  const utcDate = new Date(dateWithTimezone.getTime() + dateWithTimezone.getTimezoneOffset() * 60000);
  const formattedDate = formatDate(utcDate, 'yyyy-MM-dd');
  const formattedTime = formatDate(utcDate, 'HH:mm:ss');

  return (
    <div className={cx(s.colorWeak, className)}>
      <Icon name="clock-nine" className={cx(s.colorSemiWeak, s.marginRightXs)} />
      {formattedDate} <span className={cx(s.textBold, s.colorSemiWeak)}>{formattedTime}</span> UTC
    </div>
  );
};
