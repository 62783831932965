import React, { useEffect, useRef, useState } from 'react';

import { TextArea as BaseTextArea } from '@grafana/ui';

type TextAreaProps = React.ComponentProps<typeof BaseTextArea>;

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>((props, outerRef) => {
  const ref = useRef<HTMLTextAreaElement>(null);
  const [text, setText] = useState('');
  const [textAreaHeight, setTextAreaHeight] = useState('auto');

  useEffect(() => {
    // We need to use the "ref" inside, but we would also like to forward it
    if (typeof outerRef === 'function') {
      outerRef(ref.current);
    } else if (outerRef) {
      outerRef.current = ref.current;
    }

    // Setting the height of the TextArea for the first time
    if (ref.current) {
      setTextAreaHeight(`${ref.current!.scrollHeight}px`);
    }
  }, [outerRef, ref]);

  useEffect(() => {
    setTextAreaHeight(`${ref.current!.scrollHeight}px`);
  }, [text]);

  const onChangeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextAreaHeight('auto');
    setText(event.target.value);

    if (props.onChange) {
      props.onChange(event);
    }
  };

  return (
    <BaseTextArea
      {...props}
      ref={ref}
      onChange={onChangeHandler}
      style={{
        height: textAreaHeight,
        overflow: 'hidden',
      }}
    />
  );
});

TextArea.displayName = 'TextArea';
