import React, { FunctionComponent } from 'react';
import { cx } from '@emotion/css';

import { useUtilityStyles } from '@common/utils';

import { CopyToClipboard } from '../CopyToClipboard';

type Props = {
  /** Additional className for styling. */
  className?: string;
  /** The piece of code that is going to be displayed and can be copied to clipboard. */
  code: string;
  /** Render additional actions next to the copy-to-clipboard button */
  renderActions?: () => React.ReactElement;
};

export const CodeSnippet: FunctionComponent<Props> = ({ className, code, renderActions }) => {
  const s = useUtilityStyles();

  return (
    <div className={cx(s.textSm, s.colorSemiWeak, s.relative, className)}>
      <pre className={cx(s.marginNone)}>{code}</pre>
      <div className={cx(s.absolute, s.top0, s.right0, s.flex, s.paddingSm)}>
        <CopyToClipboard textToCopy={code} />
        {renderActions && renderActions()}
      </div>
    </div>
  );
};
