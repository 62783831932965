import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';

export const getStyles = (isHighlighted?: boolean) => (theme: GrafanaTheme2) => ({
  container: css`
    display: flex;
    padding-top: ${theme.spacing(0.5)};
    padding-bottom: ${theme.spacing(0.5)};
    padding-left: ${theme.spacing(1)};
    padding-right: ${theme.spacing(1)};
    position: relative;
    &:nth-child(odd) {
      background-color: ${theme.colors.background.secondary};
    }
  `,
  limitInput: css`
    width: 200px;
    font-size: ${theme.typography.bodySmall.fontSize};
    font-family: monospace;
    padding-left: ${theme.spacing(1)};
    padding-right: ${theme.spacing(1)};
  `,
  limitName: css`
    flex: 1;
    display: flex;
    align-items: center;
    font-size: ${theme.typography.bodySmall.fontSize};
    ${isHighlighted
      ? `
      font-weight: ${theme.typography.fontWeightBold};
      color: ${theme.colors.text.primary};
    `
      : `
        color: ${theme.colors.text.secondary};
        `}
  `,
  defaultValueContainer: css`
    flex: 1;
    display: flex;
    align-items: center;
    font-size: ${theme.typography.bodySmall.fontSize};
    font-family: monospace;
    cursor: pointer;
    position: relative;
    padding-left: ${theme.spacing(1)};
    padding-right: ${theme.spacing(1)};
    color: ${isHighlighted ? theme.colors.text.primary : theme.colors.text.secondary};
  `,
  defaultValues: css`
    margin-left: ${theme.spacing(1)};
  `,
  tag: css`
    margin-right: ${theme.spacing(0.5)};
    padding-left: ${theme.spacing(1)};
    padding-right: ${theme.spacing(1)};
    background-color: ${theme.colors.action.hover};
    border-radius: 3px;
  `,
  edittedValueContainer: css`
    flex: 1;
    display: flex;
    align-items: center;
  `,
  valueType: css`
    margin-left: ${theme.spacing(0.5)};
  `,
  arrayValueInput: css`
    width: 200px;
    font-size: ${theme.typography.bodySmall.fontSize};
    font-family: monospace;
    padding-left: ${theme.spacing(1)};
    padding-right: ${theme.spacing(1)};
  `,
  actionsContainer: css`
    display: flex;
    justify-content: flex-end;
    width: 115px;
  `,
  editIcon: css`
    color: ${theme.colors.text.primary};
    cursor: pointer;
    & {
      width: 20px;
      opacity: 0.4;
    }
    &:hover {
      opacity: 1;
    }
  `,
  penIcon: css`
    margin-right: ${theme.spacing(1)};
  `,
  clearIcon: css`
    color: ${theme.colors.text.primary};
    cursor: pointer;
    margin-right: ${theme.spacing(1)};
    font-size: ${theme.typography.body.fontSize};
    & {
      opacity: 0.4;
    }
    &:hover {
      opacity: 1;
    }
  `,
  buttonGroup: css`
    margin-left: ${theme.spacing(1)};
    height: 21px !important;
    line-height: 21px !important;
    font-size: 11px !important;
  `,
});
