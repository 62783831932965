import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';

export const getStyles = (className?: string) => (theme: GrafanaTheme2) => ({
  form: css`
    ${className}
  `,
  description: css`
    margin-top: ${theme.spacing(1)};
    font-size: ${theme.typography.bodySmall.fontSize};
    color: ${theme.colors.text.secondary};
  `,
  limitSettingsTitle: css`
    font-size: ${theme.typography.h4.fontSize};
  `,
  collapse: css`
    margin-top: ${theme.spacing(5)};
  `,
  actionsContainer: css`
    margin-top: ${theme.spacing(3)};
  `,
  submitButton: css`
    margin-right: ${theme.spacing(1)};
  `,
});
