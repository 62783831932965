import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';

import { getTenantStats } from '@common/api';
import { AccessPolicy, Tenant } from '@common/types';
import { BackendContext, usePluginMeta } from '@common/utils';

import { createHooks } from '../adminResources/adminResources.hooks';
import { RootState } from '../store';

import { tenantActions } from './tenants.actions';
import { entityAdapter } from './tenants.reducer';
import { selectAll, selectTenantsForAccessPolicy } from './tenants.selectors';

const selectRoot = (state: RootState) => state.tenants;
const selectors = entityAdapter.getSelectors(selectRoot);

export const { useFetchById, useGetAll, useDeactivate, useCreate, useCreateLoading, useUpdate, useUpdateLoading } =
  createHooks<Tenant>(tenantActions, selectors, selectAll);

export const useTenantsForAccessPolicy = (accessPolicy: AccessPolicy) => {
  const tenants: Tenant[] = useSelector(selectTenantsForAccessPolicy(accessPolicy));

  return tenants;
};

export const useTenantsStats = () => {
  const { pluginResourceUrlPrefix: prefix } = usePluginMeta();
  const {
    backend: {
      implicitFeatures: { tenantStatsEnabled },
    },
  } = useContext(BackendContext);

  const refetchTime = 0.5 * 60 * 1000; // Half-minute;

  const { data: tenantStatsDictionary } = useQuery(['tenant-stats', prefix], () => getTenantStats(prefix), {
    enabled: tenantStatsEnabled,
    cacheTime: refetchTime,
    refetchInterval: refetchTime,
  });

  return tenantStatsDictionary;
};
